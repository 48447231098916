import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SharedHttpService} from '../services/shared-http.service';
import {Router} from '@angular/router';
import {FormService} from '../services/formservice.service';
import {Tables} from '../../Models/Tables';
import {BaseComponent} from '../BaseComponent/BaseComponent';

@Component({
  selector: 'app-two-fa-step',
  templateUrl: './two-fa-step.component.html',
  styleUrls: ['./two-fa-step.component.css']
})
export class TwoFaStepComponent extends BaseComponent implements OnInit {

  public form: FormGroup;
  successAlert = false;
  errorAlert = false;
  alertMsg: any;
  successMsg: any;

  constructor(private fb: FormBuilder, private httpService: SharedHttpService, private router: Router, private formService: FormService) {
    super(router);
  }

  async sendToken() {
    console.log('sending..');
    const dataToSend = {
      'numberToUseIn2FA' : this.user.numberToUseIn2FA
    };

    const res = await this.httpService.PostWithoutModel('2FA/send-verify-token', dataToSend);
    console.log(res);

    if (res.success) {
      console.log('success', res);
      this.successAlert = true;
      this.successMsg = 'Code sent successfully.';
      setTimeout(() => {
        this.successAlert = false;
      }, 5000);
    } else {
      this.errorAlert = true;
      setTimeout(() => {
        this.errorAlert = false;
      }, 5000);
      this.alertMsg = 'Couldn\'t be able to send the code to the targeted number.';
    }
  }

  async ngOnInit() {
    this.form = this.fb.group({
      code: ['', Validators.required],
    });
    this.sendToken();
  }

  resendCode() {
    console.log('resend code');
    this.sendToken();
  }


  async submit() {
    console.log('val', this.form.value);
    if (this.form.valid) {
      const dataToSend = {
        'to' : this.user.numberToUseIn2FA,
        'code': this.form.value.code
      };
      const res = await this.httpService.PostWithoutModel('2FA/verify-token', dataToSend);
      console.log(res);
      if (res.success) {
        console.log('success', res);
        const result = await this.httpService.Patch(Tables.Staff, {verified2FA: true}, this.user.id);

        if (result.success) {
          this.user.verified2FA = true;
          localStorage.setItem('user', JSON.stringify(this.user));
          this.successMsg = 'Verified successfully.';
          this.successAlert = true;

          setTimeout(() => {
            this.successAlert = false;
            if (this.user.verified2FA) {
              this.router.navigate(['/home/dashboard']);
            }
          }, 3000);
        }
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = 'Something went wrong. Please try with correct code.';
      }
    } else {
      console.log('form invalid');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }
  }

}
