import { Component, OnInit } from '@angular/core';
import { SharedHttpService } from '../../services/shared-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tables } from '../../../Models/Tables';
import { BaseComponent } from '../../BaseComponent/BaseComponent';
import { Approval } from '../../../Models/Approval';
import { TransporterService } from '../../services/transporter.service';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { BluePrint } from '../../../Models/BluePrint';

@Component({
  selector: 'app-waiting-for-approval',
  templateUrl: './waiting-for-approval.component.html',
  styles: []
})
export class WaitingForApprovalComponent extends BaseComponent implements OnInit {

  webBaseUrl = environment.webBaseUrl;
  htmlToSave = '';
  items: any;
  itemsOnServer: any;
  rowId: any;
  verificationModal = false;

  constructor(private httpService: SharedHttpService, private transporterService: TransporterService, private router: Router, private route: ActivatedRoute) {
    super(router);
  }

  async getWaitingForMyApproval() {
    const res = await this.httpService.GetCertificatesWaitingForMyApproval();
    if (res.success) {
      this.items = this.itemsOnServer = res.data;
      // console.log('waiting for my approval', res);
    } else {
      // console.log('waiting for my approval err', res);
    }
  }

  async ngOnInit() {
    await this.getWaitingForMyApproval();
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(x =>
        x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
        x.certification.title.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.items = this.itemsOnServer;
    }
  }

  async approveCertificate(rowId) {
    const r = confirm('Are You Sure?');
    if (r == true) {
      const res = await this.httpService.Post<Approval>(Tables.Approvals, {
        staffId: this.user.id,
        certificateId: rowId, instituteId: this.user.institute.id
      });

      if (res.success) {
        await this.getWaitingForMyApproval();
        this.checkRemainingCertificateApproval(rowId);
      }
    }
  }

  async checkRemainingCertificateApproval(rowId) {
    const payload = {
      certificateId: rowId
    }
    await this.httpService.checkRemainingCertificateApproval(payload);
  }

  async preGeneratePreview(e, item: any) {
    const selectedCert = this.items.find(x => {
      return x.id === item;
    });
    const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
      table: Tables.BluePrints,
      key: selectedCert['bluePrintId']
    });
    if (getRequest.success) {
      this.prepingUnprintedPreview(selectedCert, getRequest['data']);
    }
  }

  async prepingUnprintedPreview(cert, design) {
    const this_ = this;
    const dynamicVariables = ['identifier', 'pin', 'candidate_firstName', 'candidate_rollNumber', 'candidate_lastName', 'candidate_registrationNumber', 'candidate_rollNumber', 'certification_endDate', 'certification_startDate', 'certification_endDate', 'certification_title'];
    /*todo: push dynamic variables from cadndidate.fields into dynamicVariables */
    design.template.forEach(function (page, index) {
      const variable = 'data=dummy_authentific&';
      const newVal = 'data=' + this_.webBaseUrl + '/verify-certificate/' + cert['identifier'] + '&';
      const regx = new RegExp(variable, 'g');
      design.template[index] = page.replace(regx, encodeURI(newVal));
    });
    dynamicVariables.forEach(function (value) {
      const valueArray = value.split('_');
      if (valueArray.length === 1) {
        design.template.forEach(function (page, index) {
          const variable = '#' + value;
          const regx = new RegExp(variable, 'g');
          design.template[index] = page.replace(regx, cert[value]);
        });
      }
      if (valueArray.length === 2) {
        design.template.forEach(function (page, index) {
          const variable = '#' + value;
          const regx = new RegExp(variable, 'g');

          if (value == "certification_startDate" || value == "certification_endDate") {
            const date = cert[valueArray[0]][valueArray[1]];
            design.template[index] = page.replace(regx, date.split('T')[0]);
          } else {
            design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
          }

          // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
        });
      }
      if (valueArray.length === 3) {
        design.template.forEach(function (page, index) {
          const variable = '#' + value;
          const regx = new RegExp(variable, 'g');
          design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]][valueArray[2]]);
        });
      }
    });
    const extraStyles = '<style id="custom-print-style">@media print { @page {size: ' + design.size + ' ' + design.orientation + ';margin: 0; }}</style>';
    $('head').append(extraStyles);

    this.htmlToSave = '';
    design.template.forEach((page, index) => {
      if (index !== 0) {
        this.htmlToSave += '<p style="page-break-before: always"><hr style="margin: 30px 0"></p>';
      }
      this.htmlToSave += design.template[index];
    });

    $('.dynamic-google-fonts').remove();
    const fontsCollection = design.googleFonts.split('|');
    fontsCollection.forEach(f => {
      if (f.length > 1) {
        $('head').append('<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' + f.replace(/\s+/g, '+') + '" type="text/css" />');
      }
    });

    $('.cert-holder').html(this.htmlToSave).promise().done(function () {
      this_.updateZoomLevel(0.5);
      $('body').addClass('cert-modal-active');
    });
  }

  closePreview() {
    $('body').removeClass('cert-modal-active');
    $('.cert-holder').html('No Certificate Record found');
  }

  updateZoomLevel(val) {
    $('.the-certificate').css('transform', 'scale(' + val + ')');
    setTimeout(() => {
      const currentHeight = document.getElementById('the-certificate').getBoundingClientRect().height;
      const currentWidth = document.getElementById('the-certificate').getBoundingClientRect().width;
      $('.cert-holder').css('width', currentWidth + 'px');
      $('.cert-holder').css('height', currentHeight + 'px');
    }, 500);
  }

}
