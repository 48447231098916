import { StaffCategory } from "./StaffCategory";
import { Institute } from "./Institute";
import { Certification } from "./Certification";
import { StaffCertification } from "./StaffCertification";
import { nonZeroValidator } from "../Validators/nonZeroValidator";
import { Validators } from "@angular/forms";

export class Person {
  validators = {
    staffCategoryId: Validators.required,
    email: Validators.email,
  };
  img: string;
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  token: string;
  staffCategory: StaffCategory;
  institute: Institute;
  staffCategoryId: string;
  instituteId: string;
  email: string;
  password: string;
  position: string;
  type: string;
  yubikeyId: string;
  requireYubikey: boolean;
  certifications: Array<Certification>;
  require2FA: boolean;
  numberToUseIn2FA: string;
  verified2FA: boolean;
  blockchainAddress: string;

  constructor(instituteId: string, inityubikey = false) {
    this.firstName =
      this.lastName =
      this.email =
      this.password =
      this.position =
        "";
    this.staffCategoryId = "";
    this.type = "user";
    this.instituteId = instituteId;
    this.requireYubikey = false;
    this.require2FA = false;
  }
}
