
import { Component, OnInit } from '@angular/core';
import {Paper} from '../../../Models/Paper';
import {Tables} from '../../../Models/Tables';
import {ExcelService} from '../../services/excel.service';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-certificate-stock',
  templateUrl: './new-certificate-stock.component.html',
  styles: []
})
export class NewCertificateStockComponent extends BaseComponent implements OnInit {

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router) {
    super(router);
  }

  ngOnInit() {
  }


  async importExcelData(evt) {
    const page = this;
    this.excelService.importExcelData(evt, async excelData => {
      if (!excelData) {
        return;
      }
      const res = await page.httpService.PostRelation<Paper>
      (Tables.Paper, excelData, Tables.Institutes, page.user.institute.id);
      // console.log('res', res);
      if (res.success) {
        // console.log('excel import', res);
        this.router.navigate(['/home/certificate-stock/view']);
      }
    });
  }

}
