import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as uuid from 'uuid';
import { environment } from '../../environments/environment';
import { SharedHttpService } from '../services/shared-http.service';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-print-preview',
  templateUrl: './print-preview.component.html',
  styles: []
})
export class PrintPreviewComponent implements OnInit {
  htmlToSave = '';
  googleFonts = '';
  emailModal = false;
  pdfEmail = false;
  showContent = false;
  hideContent = false;
  webBaseUrl = environment.webBaseUrl;
  apiBaseUrl = environment.apiBaseUrl;
  form: FormGroup;
  content1;
  afterPrint = async (e) => {
    // console.log('e')
    // console.log(e)
    const data = {
      isPrinted: true,
      print: this.htmlToSave,
      googleFonts: this.googleFonts
    };

    $('.printable').remove();
  };
  constructor(private httpService: SharedHttpService, private router: Router, private fb: FormBuilder) {

  }

  ngOnInit() {

    this.form = this.fb.group({
      identifier: [''],
      pin: [''],
      print: [''],
      email: [''],
      pdf: [''],
      name: [''],
    });
    $('body').addClass('cert-printing-page');
  }

  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }


  sendToEmail() {
    this.emailModal = true;
    document.getElementById("printDiv").style.opacity = "0.1";
    document.getElementById("submitBtn").style.opacity = "0.1";

  }
  cancelEmailModal() {
    this.emailModal = false;
    document.getElementById("printDiv").style.opacity = "1";
    document.getElementById("submitBtn").style.opacity = "1";
  }

  ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }


  }

  sendCertificate() {
    let customEmail = this.ValidateEmail($("#customEmail").val());
    var inputName = $("#custom-input-name").html();
    // console.log(inputName)
    if (customEmail) {
      this.generatePdf($("#customEmail").val(), inputName);
      this.cancelEmailModal();
      this.pdfEmail = true;

    } else {
      alert("Please type a valid email address!")
    }
  }

  generatePdf(customEmail, inputName) {
    let page = this;
    page.content1 = document.getElementById("company-info")
    let customUid = uuid.v4();
    let customPin = Math.random().toString(36).substr(2, 5);
    var verificationUrl = this.webBaseUrl + 'demo-certificate-verification/' + customUid;
    // var qrUrl = "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" + verificationUrl + "&chld=M|0";
    var qrUrl =
      "https://api.qrserver.com/v1/create-qr-code/?data=" +
      encodeURIComponent(verificationUrl) +
      "&size=500x500";

    this.toDataURL(qrUrl, function (dataUrl) {

      $("#qr-code-identification img").attr('src', dataUrl)
      $("#custom-input-pin").html(customPin)

      // let data = document.querySelector("#printDiv");
      $("#the-certificate").css({ "transform": "scale(.8)" });
      $("#printDiv").css({ "padding": "0% !important" });
      var content = document.getElementById("printDiv").innerHTML;
      page.hideContent = true;


      html2canvas(document.querySelector("#printDiv")).then(canvas => {

        // Few necessary setting options  
        var imgWidth = 208;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png')

        let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight())


        pdf.addPage();
        pdf.fromHTML($('#company-info').get(0), 10, 10, { 'width': 275 });

        // pdf.addImage(page.content1, 'PNG', 0,0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight())
        // console.log(pdf.output('mypdf.pdf'))
        // pdf.save('MYPdf.pdf'); // Generated PDF 


        var string = pdf.output('datauristring'); // Generated PDF  
        var base64Content = string.split(",")[1]

        page.form.patchValue({
          identifier: customUid,
          pin: customPin,
          print: content,
          email: customEmail,
          pdf: base64Content,
          name: inputName,
        })

        // console.log(page.form.value)
        // pdf.save('MYPdf.pdf');
        const res = page.httpService.PostWithoutModel('/DemoCertificates/sendmail', page.form.value)

        console.log(res)

      })
    })
  }

  printDiv() {

    var design = document.getElementById("printDiv").innerHTML;
    let designDiv = "#printDiv";
    let extraStyles = '<style id="custom-print-style">@media print { #company-info{page-break-before: always !important;display:block !important;font-size: xx-large;position: absolute;top: 2600px;}  .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>';

    $('head').append(extraStyles);

    this.htmlToSave = design;

    // $('.dynamic-google-fonts').remove();

    $('.printable').remove();
    $(designDiv + " #report-cert-img").html(this.htmlToSave)
    var printableDiv = $(designDiv).html()
    // console.log(printableDiv)
    $('body').append('<div class="printable">' + printableDiv + '</div>').promise().done(function () {
      const ttl_images = $('.printable img').length;
      $('.printable img').each(function (index) {
        const $img = $(this);
        const downloadingImage = new Image();
        downloadingImage.onload = function () {
          if (index === ttl_images - 1) {
            setTimeout(function () {
              window.print();
              $('.printing-loading').fadeOut();
            }, 1000);
          }
        };
        downloadingImage.src = $img.attr('src');
      });
    });
  }
}
