import { Component, OnInit } from '@angular/core';
import { FormService } from '../../services/formservice.service';
import { Certification } from '../../../Models/Certification';
import { BaseComponent } from '../../BaseComponent/BaseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { Attributes, Tables } from '../../../Models/Tables';
import { SharedHttpService } from '../../services/shared-http.service';
import {StaffCertification} from '../../../Models/StaffCertification';
import { FormGroup } from '@angular/forms';
import { Person } from '../../../Models/Person';
import { BluePrint } from '../../../Models/BluePrint';
import { async } from '@angular/core/testing';

@Component({
    selector: 'app-new-certification',
    templateUrl: './new-certification.component.html',
    styles: []
})
export class NewCertificationComponent extends BaseComponent implements OnInit {

    form: FormGroup;
    id: string;
    levels = [];
    editMode = false;
    items: Array<Person>;
    bluePrints: Array<BluePrint>;
    itemsOnServer: Array<Person>;
    selectedLevel: any;
    startDate = new Date().toDateString();
    endDate = new Date().toDateString();
    printingDeadline = new Date().toDateString();
    successAlert = false;

    constructor(private formService: FormService, private router: Router,
        private httpService: SharedHttpService, private route: ActivatedRoute) {
        super(router);
    }

    async ngOnInit() {
        // await this.getBluePrints();
        for (let i = 1; i <= this.user.institute.levels; i++) {
            this.levels.push({
                name: 'level ' + i,
                level: i
            });
        }


        this.id = this.route.snapshot.params['id'];

        // console.log(this.route.snapshot.params['showsuccess']);
        if (this.route.snapshot.params['showsuccess'] && this.route.snapshot.params['showsuccess'].includes('true')) {
            this.successAlert = true;
            setTimeout(() => {
                this.successAlert = false;
            }, 3000);
        }

        if (this.id) {
            this.editMode = true;
            const getRequest = await
                this.httpService.Get<Certification>(Certification, { table: Tables.Certifications, key: this.id });
            if (getRequest.success) {
                // console.log('editing');
                // console.log('date', getRequest.data);
                this.selectedLevel = getRequest.data.level;
                await this.getUsers();
                this.form = this.formService.Get(new Certification(this.user.institute.id), getRequest.data);

                const startDate = new Date(this.form.value.startDate);
                const endDate = new Date(this.form.value.endDate);
                this.startDate = startDate.getMonth() + '/' + startDate.getDate() + '/' + startDate.getFullYear();
                this.endDate = endDate.getMonth() + '/' + endDate.getDate() + '/' + endDate.getFullYear();
                this.form.controls['startDate'].patchValue(startDate.getMonth() + '/' + startDate.getDate() + '/' + startDate.getFullYear());
                this.form.controls['endDate'].patchValue(endDate.getMonth() + '/' + endDate.getDate() + '/' + endDate.getFullYear());

                if (this.form.value.printingDeadline) {
                    const printingDeadline = new Date(this.form.value.printingDeadline);
                    this.printingDeadline = printingDeadline.getMonth() + '/' + printingDeadline.getDate() + '/' + printingDeadline.getFullYear();
                    this.form.controls['printingDeadline'].patchValue(printingDeadline.getMonth() + '/' + printingDeadline.getDate() + '/' + printingDeadline.getFullYear());
                }

                console.log('form val', this.form.value);
                return;
            }
            return;
        } else {
            this.form = this.formService.Get(new Certification(this.user.institute.id));
        }
    }

    async changeLevel(e) {
        console.log('e', e);
        this.selectedLevel = e;
        console.log('TYPE OF E', typeof parseInt(e));

        await this.getUsers();
        this.items = this.itemsOnServer.filter(x => x.staffCategory.level === parseInt(e));
        console.log('item', this.items);
    }

    async getUsers() {
        const res = await this.httpService.GetRelated<Person>(Tables.Institutes, this.user.institute.id,
            Tables.Staff, '', [Attributes.certifications, Attributes.staffCategory]);
        if (res.success) {
            this.itemsOnServer = res.data;
            this.items = this.itemsOnServer.filter(x => x.staffCategory.level === this.selectedLevel);
        } else {
            console.log('err', res);
        }
    }

    // async getBluePrints() {
    //     const res = await this.httpService.GetRelated<BluePrint>(Tables.Institutes, this.user.institute.id,
    //         Tables.BluePrints, '', [Attributes.None], {isArchived: false});
    //     console.log('Blp', res);
    //     if (res.success) {
    //         this.bluePrints = res.data;
    //     } else {
    //         console.log('err', res);
    //     }
    // }


    search(event) {
        console.log(event.target.value);
        this.items = this.itemsOnServer.filter(x => x.firstName.includes(event.target.value) || x.email.includes(event.target.value));
    }

    clearForm() {
        this.ngOnInit();
    }


    async submit() {

        // console.log('FORM VAL', this.form);
        // console.log('Synthesize', this.formService.Synthesize(this.form.value, this.form));

        if (this.form.valid) {
            let res;
            if (this.id) {
                res = await this.httpService.Patch
                    (Tables.Certifications, this.form.value, this.id);
            } else {
                res = await this.httpService.PostRelation<Certification>
                    (Tables.Certifications, this.form.value, Tables.Institutes, this.user.institute.id);
            }
            if (res.success) {
                if(!this.id){
                    this.userAllowAccess(res.data.id)
                }
                this.id = res.data.id;
                this.successAlert = true;
                setTimeout(() => {
                    this.successAlert = false;
                    this.router.navigate(['/home/courses/view']);
                }, 600);
            }
        } else {
            // console.log('form value', this.form.value);
            // console.log('form valid', this.form.valid);
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control.markAsDirty({ onlySelf: true });
            });
        }
    }


    async userAllowAccess(courseId) {
        const res = await this.httpService.PostRelation<Certification>
        (Tables.StaffCertifications, new StaffCertification(this.user.institute.id, this.user.id, courseId), Tables.Institutes,
            this.user.institute.id);
        console.log('res',res);
        if (res.success) {
            return true;
        //    await this.ngOnInit();
        }
    }

    public checkCertificateAccess(staff: string): boolean {
        const findStaff = this.items.find(x => x.id === staff);
        if (findStaff.certifications.find(y => y.id === this.id)) {
            return true;
        }
        return false;

    }

}
