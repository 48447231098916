import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as $ from "jquery";
import { SharedHttpService } from "../../services/shared-http.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../../environments/environment";
@Component({
  selector: "app-certificate-mobile-view",
  templateUrl: "./certificate-mobile-view.component.html",
  styleUrls: ["./certificate-mobile-view.component.css"],
})
export class CertificateMobileViewComponent implements OnInit {
  identifier: any;
  notVerified = false;
  verified = false;
  certificate: any;
  certhtml = "";
  apiBaseUrl: any;
  currentHeight = 0;
  currentWidth = 0;
  showImageBtn = false;
  imageUrl;
  url: any;
  isBlockchain = false;
  fileName: string;
  blockchainFile: any;
  pdfLink: any;
  isShowPdfModal: boolean = false;
  isLoading = false;
  isRendered = false;
  thumbnail: any;
  blockChainFileData: any;
  isVerifyHashModal: boolean;
  HashFile: any;
  serverBlockchainFileHash: string;
  uploadedFileHash: string;
  hashMatch: boolean;
  hashCheckAgain: boolean;
  pin: number;
  backgroundCover: any;
  item:any;
  @ViewChild('preview') private previewButton : ElementRef
  constructor(
    private httpService: SharedHttpService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    // this.verified = true;
    this.route.queryParams.subscribe((params) => {
      console.log({ params });
      this.identifier = params["identifier"];
      this.pin = params["pin"];
    });
    this.submit();
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      let event = new Event('click')
      console.log(this.previewButton)
      this.previewButton.nativeElement.dispatchEvent(event)
      console.log('here')
  }, 1500);

    }


  async submit() {
    const res = await this.httpService.verifyCertificate(
      this.identifier,
      String(this.pin)
    );

    // console.log(res);
    this.certificate = res.data;
    this.item = this.certificate;
    if (res) {

      // this.certificate.print = this.certificate.print.replace(
      //   'style="transform: scale(0.5);"',
      //   ""
      // );
      // this.certificate.print = this.certificate.print.replace(
      //   "height:100%",
      //   "background-size: 100% 100%; background-repeat: no-repeat; height:100%;"
      // );
      console.log(this.certificate.print);
    }
    this.backgroundCover = this.sanitizer.bypassSecurityTrustStyle(
      `url(${this.certificate.imageUrl}) no-repeat`
    );
    // if (res["data"] == null || res.data.length < 1) {
    //   this.notVerified = true;
    //   this.verified = false;
    // } else {
    //   this.notVerified = false;
    //   this.verified = true;
    //   this.certhtml = this.certificate.print;
    //   if (this.certificate.candidate.dp) {
    //     this.showImageBtn = true;
    //     this.imageUrl =
    //       this.apiBaseUrl +
    //       "/Attachments/institute-" +
    //       this.certificate.candidate.instituteId +
    //       "-candidates/download/" +
    //       this.certificate.candidate.dp;
    //   }
    //   if (this.certificate.googleFonts) {
    //     $(".dynamic-google-fonts").remove();
    //     const fontsCollection = this.certificate.googleFonts.split("|");
    //     fontsCollection.forEach((f) => {
    //       if (f.length > 1) {
    //         $("head").append(
    //           '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
    //             f.replace(/\s+/g, "+") +
    //             '" type="text/css" />'
    //         );
    //       }
    //     });
    //   }
    // }
    // this.showPreview();
  }

  closePreview() {
    $("body").removeClass("cert-modal-active");
    $(".cert-holder").html("No Certificate Record found");
  }

  async showPreview() {
    const id = this.certificate.id;
    try {
      const this_ = this;
      $(".cert-holder")
        .html($(".source-html").html())
        .promise()
        .done(function () {
          this_.updateZoomLevel(0.4);
          $("body").addClass("cert-modal-active");
        });
    } catch (error) {
      console.log(error);
    }

    // -----------------------------------------downloading blockchain complete-----------------
  }

  async verifyHash() {
    this.isVerifyHashModal = true;
    const id = this.certificate.id;
    try {
      const file: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, {
          id,
        })
        .toPromise();
      this.serverBlockchainFileHash = file[0].hash;
      this.blockChainFileData = file[0];
    } catch (error) {
      window.alert(`Can't get data! Something went wrong!`);
    }
  }

  async onVerifyHashFileUploadChange(e) {
    this.HashFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", this.HashFile);
    try {
      const hash: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-sha-256Hash`, formData)
        .toPromise();
      this.uploadedFileHash = hash.hash;
      if (
        this.uploadedFileHash.toString() ===
        this.serverBlockchainFileHash.toString()
      )
        this.hashMatch = true;
      else this.hashMatch = false;
    } catch (error) {
      window.alert(`Something went wrong!`);
    }
  }

  verifyHashModalClose() {
    this.isVerifyHashModal = false;
    this.uploadedFileHash = null;
  }
  onCheckVerifyHashFileUploadCheckAgain() {
    this.HashFile = undefined;
    this.uploadedFileHash = null;
    this.hashMatch = false;
  }

  showImage() {
    let imageHtml =
      '<div id="the-certificate" class="the-certificate size-a4-landscape ppi_150" style="transform: scale(0.2);"><img src="' +
      this.imageUrl +
      '"></div>';
    const this_ = this;
    $(".cert-holder")
      .html(imageHtml)
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.2);
        $("body").addClass("cert-modal-active");
      });
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(() => {
      const currentHeight = document
        .getElementById("the-certificate")
        .getBoundingClientRect().height;
      const currentWidth = document
        .getElementById("the-certificate")
        .getBoundingClientRect().width;
      $(".cert-holder").css("width", currentWidth + "px");
      $(".cert-holder").css("height", currentHeight + "px");
    }, 500);
  }

  // showPreview(e) {
  //   const this_ = this;
  //   $(".cert-holder")
  //     .html($(e.target).parents("tr").find(".source-html").html())
  //     .promise()
  //     .done(function () {
  //       this_.updateZoomLevel(0.5);
  //       $("body").addClass("cert-modal-active");
  //     });
  // }
  // async onBlockchainCertificate() {
  //   const id = this.certificate.id;
  //   try {
  //     const file: any = await this.http
  //       .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, {
  //         id,
  //       })
  //       .toPromise();
  //     this.blockChainFileData = file[0];
  //     this.child.pdf();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
}
