import { Component, OnInit, Input } from "@angular/core";
import html2canvas from "html2canvas";
import jspdf from "jspdf";
import * as html2pdf from "html2pdf.js";
import { BlockchainFile } from "../../verify-certificate/blockchainTypes";
import { environment } from "src/environments/environment";
import { SharedHttpService } from "src/app/services/shared-http.service";
@Component({
  selector: "app-blockchain-certificate-pdf",
  templateUrl: "./blockchain-certificate-pdf.component.html",
  styleUrls: ["./blockchain-certificate-pdf.component.css"],
})
export class BlockchainCertificatePdfComponent implements OnInit {
  @Input() blockchainFile: BlockchainFile[];

  constructor(private httpService: SharedHttpService) { }
  ngOnInit() {
  }

  pdf() {
    let pdf = document.getElementById("first-pdf");
    var opt = {
      margin: 0,
      filename: "Blockchain_Certificate.pdf",
      pagebreak: { mode: ["avoid-all", "css"], after: ".page-break" },
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    html2pdf().from(pdf).set(opt).toPdf().outputPdf('datauristring')
      .then((pdfBase64) => {
        var arr = pdfBase64.split(',');
        const pdfAsString = arr[1];
        // const file = new File(
        //     [pdfAsString],
        //     "Blockchain_Certificate.pdf",
        //     {type: 'application/pdf'}
        // );

        const formData = new FormData();
        formData.append("file", pdfAsString);
        this.httpService.sendTransactionHistoryToMail({ pdf: pdfAsString })

      });
  }
}
