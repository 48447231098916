// import { Component, OnInit } from "@angular/core";
// import { SharedHttpService } from "../../services/shared-http.service";
// import { Router } from "@angular/router";
// import { Attributes, Tables } from "../../../Models/Tables";
// import { BaseComponent } from "../../BaseComponent/BaseComponent";
// import { environment } from "../../../environments/environment";
// import * as $ from "jquery";
// import { BluePrint } from "../../../Models/BluePrint";

// @Component({
//   selector: "app-printable-certificates",
//   templateUrl: "./printable-certificates.component.html",
//   styles: [],
// })
// export class PrintableCertificatesComponent
//   extends BaseComponent
//   implements OnInit
// {
//   htmlToSave = "";
//   googleFonts = "";
//   webBaseUrl = environment.webBaseUrl;
//   items: any;
//   itemsOnServer: any;
//   rowId: any;
//   certificateInHand: any;
//   private apiBaseUrl;

//   afterPrint = async (e) => {
//     // console.log('e')
//     // console.log(e)
//     const r = confirm("Certificate Printed Successfully?");
//     if (r == true) {
//       const data = {
//         isPrinted: true,
//         print: this.htmlToSave,
//         googleFonts: this.googleFonts,
//       };

//       if (data.print && data.print.trim() != "") {
//         const res = await this.httpService.Patch(
//           Tables.Certificates,
//           data,
//           this.certificateInHand.id
//         );
//         if (res.success) {
//           this.router.navigate(["/home/certificates/printed"]);
//           // await this.getItems({ isApproved: true });
//         } else {
//           console.log("error print patch", res);
//         }
//         $(".printable").remove();
//       } else {
//         alert("Something went wrong. Please refresh and try again");
//       }
//     } else {
//       const data = {
//         print: this.htmlToSave,
//         googleFonts: this.googleFonts,
//       };

//       if (data.print && data.print.trim() != "") {
//         const res = await this.httpService.Patch(
//           Tables.Certificates,
//           data,
//           this.certificateInHand.id
//         );
//         if (res.success) {
//           this.router.navigate(["/home/certificates/printed"]);
//           // await this.getItems({ isApproved: true });
//         } else {
//           console.log("error print patch", res);
//         }
//         $(".printable").remove();
//       } else {
//         alert("Something went wrong. Please refresh and try again");
//       }
//     }
//   };

//   constructor(private httpService: SharedHttpService, private router: Router) {
//     super(router);
//     this.apiBaseUrl = environment.apiBaseUrl;
//   }

//   async ngOnInit() {
//     $("body").addClass("cert-printing-page");
//     const input = document.querySelectorAll('input[type="text"]');
//     for (let i = 0; i < input.length; i++) {
//       input[i].setAttribute(
//         "size",
//         input[i].getAttribute("placeholder").length + "px"
//       );
//     }

//     await this.getItems({ isApproved: true, isPrinted: false });
//     window.onafterprint = this.afterPrint;
//   }

//   async confirmPrintedSuccessfully(e, certId) {
//     const res = await this.httpService.Patch(
//       Tables.Certificates,
//       { isPrinted: true },
//       certId
//     );
//     if (res.success) {
//       this.router.navigate(["/home/certificates/printed"]);
//       // await this.getItems({ isApproved: true });
//     } else {
//       console.log("error print patch", res);
//     }
//   }

//   async printCertificate(item) {
//     const r = confirm("Are you sure?");
//     if (r == true) {
//       this.certificateInHand = this.items.find((x) => {
//         return x.id === item;
//       });
//       // console.log('certificate in hand', this.certificateInHand);
//       const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
//         table: Tables.BluePrints,
//         key: this.certificateInHand["bluePrintId"],
//       });
//       if (getRequest.success) {
//         console.log("get request data:", getRequest["data"]);
//         this.prepingPrinting(this.certificateInHand, getRequest["data"]);
//       } else {
//         alert("The Design Attached to this certificate has been removed");
//       }
//     }
//   }

//   async prepingUnprintedPreview(cert, design) {
//     const this_ = this;
//     const dynamicVariables = [
//       "identifier",
//       "pin",
//       "candidate_firstName",
//       "candidate_rollNumber",
//       "candidate_lastName",
//       "candidate_registrationNumber",
//       "candidate_rollNumber",
//       "certification_endDate",
//       "certification_startDate",
//       "certification_endDate",
//       "certification_title",
//     ];
//     /*todo: push dynamic variables from cadndidate.fields into dynamicVariables */
//     design.template.forEach(function (page, index) {
//       const variable = "data=dummy_authentific&";
//       const newVal =
//         "data=" +
//         this_.webBaseUrl +
//         "/verify-certificate/" +
//         cert["identifier"] +
//         "&";
//       const regx = new RegExp(variable, "g");
//       design.template[index] = page.replace(regx, encodeURI(newVal));
//     });
//     dynamicVariables.forEach(function (value) {
//       const valueArray = value.split("_");
//       if (valueArray.length === 1) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");
//           design.template[index] = page.replace(regx, cert[value]);
//         });
//       }
//       if (valueArray.length === 2) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");

//           if (
//             value == "certification_startDate" ||
//             value == "certification_endDate"
//           ) {
//             const date = cert[valueArray[0]][valueArray[1]];
//             design.template[index] = page.replace(regx, date.split("T")[0]);
//           } else {
//             design.template[index] = page.replace(
//               regx,
//               cert[valueArray[0]][valueArray[1]]
//             );
//           }

//           // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
//         });
//       }
//       if (valueArray.length === 3) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");
//           design.template[index] = page.replace(
//             regx,
//             cert[valueArray[0]][valueArray[1]][valueArray[2]]
//           );
//         });
//       }
//     });
//     const extraStyles =
//       '<style id="custom-print-style">@media print { @page {size: ' +
//       design.size +
//       " " +
//       design.orientation +
//       ";margin: 1cm; }}</style>";
//     $("head").append(extraStyles);

//     this.htmlToSave = "";
//     design.template.forEach((page, index) => {
//       if (index !== 0) {
//         this.htmlToSave +=
//           '<p style="page-break-before: always"><hr style="margin: 30px 0"></p>';
//       }
//       this.htmlToSave += design.template[index];
//     });

//     $(".dynamic-google-fonts").remove();

//     const fontsCollection = design.googleFonts.split("|");
//     fontsCollection.forEach((f) => {
//       if (f.length > 1) {
//         $("head").append(
//           '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
//             f.replace(/\s+/g, "+") +
//             '" type="text/css" />'
//         );
//       }
//     });

//     $(".cert-holder")
//       .html(this.htmlToSave)
//       .promise()
//       .done(function () {
//         this_.updateZoomLevel(0.5);
//         $("body").addClass("cert-modal-active");
//       });
//   }

//   async prepingPrinting(cert, design) {
//     $(".dynamic-fonts,#custom-print-style").remove();
//     $(".printing-loading").fadeIn();
//     $(".ps-msg").html("Please wait, certificate is being rendered...");
//     const this_ = this;
//     const dynamicVariables = [
//       "identifier",
//       "pin",
//       "candidate_firstName",
//       "candidate_rollNumber",
//       "candidate_lastName",
//       "candidate_registrationNumber",
//       "candidate_rollNumber",
//       "certification_endDate",
//       "certification_startDate",
//       "certification_endDate",
//       "certification_title",
//     ];
//     /*todo: push dynamic variables from cadndidate.fields into dynamicVariables */
//     design.template.forEach(function (page, index) {
//       const variable = "data=dummy_authentific&";
//       const newVal =
//         "data=" +
//         this_.webBaseUrl +
//         "/verify-certificate/" +
//         cert["identifier"] +
//         "&";
//       const regx = new RegExp(variable, "g");

//       design.template[index] = page.replace(regx, encodeURI(newVal));
//     });
//     dynamicVariables.forEach(function (value) {
//       const valueArray = value.split("_");
//       if (valueArray.length === 1) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");
//           design.template[index] = page.replace(regx, cert[value]);
//         });
//       }
//       if (valueArray.length === 2) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");
//           if (
//             value == "certification_startDate" ||
//             value == "certification_endDate"
//           ) {
//             const date = cert[valueArray[0]][valueArray[1]];
//             design.template[index] = page.replace(regx, date.split("T")[0]);
//           } else {
//             design.template[index] = page.replace(
//               regx,
//               cert[valueArray[0]][valueArray[1]]
//             );
//           }
//           // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
//         });
//       }
//       if (valueArray.length === 3) {
//         design.template.forEach(function (page, index) {
//           const variable = "#" + value;
//           const regx = new RegExp(variable, "g");
//           design.template[index] = page.replace(
//             regx,
//             cert[valueArray[0]][valueArray[1]][valueArray[2]]
//           );
//         });
//       }
//     });

//     const extraStyles =
//       '<style id="custom-print-style">@media print { @page {size: ' +
//       design.size +
//       " " +
//       design.orientation +
//       ";margin: 0;} .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>";

//     $("head").append(extraStyles);
//     this.htmlToSave = "";
//     design.template.forEach((page, index) => {
//       /*if (index !== 0) {
//               this.htmlToSave += '<p style="page-break-before: always">';
//             }*/
//       this.htmlToSave += design.template[index];
//     });

//     $(".dynamic-google-fonts").remove();
//     const fontsCollection = design.googleFonts.split("|");
//     fontsCollection.forEach((f) => {
//       if (f.length > 1) {
//         $("head").append(
//           '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
//             f.replace(/\s+/g, "+") +
//             '" type="text/css" />'
//         );
//       }
//     });

//     this.googleFonts = design.googleFonts;
//     // console.log(this.googleFonts)
//     $(".printable").remove();

//     $("body")
//       .append(
//         '<div class="printable" style="page-break-inside: avoid;">' +
//           this.htmlToSave +
//           "</div>"
//       )
//       .promise()
//       .done(async function () {
//         const ttl_images = $(".printable img").length;
//         if (ttl_images == 0) {
//           setTimeout(function () {
//             window.print();
//             $(".printing-loading").fadeOut();
//           }, 2000);
//         } else {
//           $(".printable img").each(function (index) {
//             const $img = $(this);
//             const downloadingImage = new Image();

//             downloadingImage.onload = function () {
//               if (index === ttl_images - 1) {
//                 setTimeout(function () {
//                   window.print();
//                   $(".printing-loading").fadeOut();
//                 }, 2000);
//               }
//             };
//             downloadingImage.src = $img.attr("src");
//           });
//         }
//       });
//   }

//   async getItems(where: any | null) {
//     const res = await this.httpService.GetRelated(
//       Tables.Institutes,
//       this.user.institute.id,
//       Tables.Certificates,
//       "",
//       [Attributes.certification, Attributes.candidate, Attributes.approvals],
//       where
//     );

//     if (res.success) {
//       this.items = this.itemsOnServer = res.data;
//       // console.log('certifications: ', this.items);
//     } else {
//       console.log("err", res);
//     }
//   }

//   async verificationModalTrigger(rowId: any) {
//     this.rowId = rowId;
//     // this.verificationModal = true;
//     await this.printCertificate(rowId);
//   }

//   showPreview(e) {
//     const this_ = this;
//     $(".cert-holder")
//       .html($(e.target).parents("tr").find(".source-html").html())
//       .promise()
//       .done(function () {
//         this_.updateZoomLevel(0.4);
//         $("body").addClass("cert-modal-active");
//       });
//   }

//   async preGeneratePreview(e, item: any) {
//     const selectedCert = this.items.find((x) => {
//       return x.id === item;
//     });
//     const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
//       table: Tables.BluePrints,
//       key: selectedCert["bluePrintId"],
//     });
//     if (getRequest.success) {
//       this.prepingUnprintedPreview(selectedCert, getRequest["data"]);
//     } else {
//       alert("The Design Attached to this certificate has been removed");
//     }
//   }

//   closePreview() {
//     $("body").removeClass("cert-modal-active");
//     $(".cert-holder").html("No Certificate Record found");
//   }

//   updateZoomLevel(val) {
//     $(".the-certificate").css("transform", "scale(" + val + ")");
//     setTimeout(() => {
//       const currentHeight = document
//         .getElementById("the-certificate")
//         .getBoundingClientRect().height;
//       const currentWidth = document
//         .getElementById("the-certificate")
//         .getBoundingClientRect().width;
//       $(".cert-holder").css("width", currentWidth + "px");
//       $(".cert-holder").css("height", currentHeight + "px");
//     }, 500);
//   }

//   updateFilter(e: any) {
//     if (e) {
//       this.items = this.itemsOnServer.filter(
//         (x) =>
//           x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
//           x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
//           x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
//           x.certification.title.toLowerCase().includes(e.toLowerCase())
//       );
//     } else {
//       this.items = this.itemsOnServer;
//     }
//   }
// }

import { Component, OnInit } from "@angular/core";
import { SharedHttpService } from "../../services/shared-http.service";
import { Router } from "@angular/router";
import { Attributes, Tables } from "../../../Models/Tables";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { environment } from "../../../environments/environment";
import * as $ from "jquery";
import { BluePrint } from "../../../Models/BluePrint";

@Component({
  selector: "app-printable-certificates",
  templateUrl: "./printable-certificates.component.html",
  styles: [],
})
export class PrintableCertificatesComponent
  extends BaseComponent
  implements OnInit {
  htmlToSave = "";
  googleFonts = "";
  webBaseUrl = environment.webBaseUrl;
  items: any;
  itemsOnServer: any;
  rowId: any;
  certificateInHand: any;
  private apiBaseUrl;

  afterPrint = async (e) => {
    // console.log('e')
    // console.log(e)
    const r = confirm("Certificate Printed Successfully?");
    if (r == true) {
      const data = {
        isPrinted: true,
        print: this.htmlToSave,
        googleFonts: this.googleFonts,
      };

      if (data.print && data.print.trim() != "") {
        const res = await this.httpService.Patch(
          Tables.Certificates,
          data,
          this.certificateInHand.id
        );
        if (res.success) {
          this.router.navigate(["/home/certificates/printed"]);
          // await this.getItems({ isApproved: true });
        } else {
          console.log("error print patch", res);
        }
        $(".printable").remove();
      } else {
        alert("Something went wrong. Please refresh and try again");
      }
    } else {
      const data = {
        print: this.htmlToSave,
        googleFonts: this.googleFonts,
      };

      if (data.print && data.print.trim() != "") {
        const res = await this.httpService.Patch(
          Tables.Certificates,
          data,
          this.certificateInHand.id
        );
        if (res.success) {
          this.router.navigate(["/home/certificates/printed"]);
          // await this.getItems({ isApproved: true });
        } else {
          console.log("error print patch", res);
        }
        $(".printable").remove();
      } else {
        alert("Something went wrong. Please refresh and try again");
      }
    }
  };

  constructor(private httpService: SharedHttpService, private router: Router) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  async ngOnInit() {
    $("body").addClass("cert-printing-page");
    const input = document.querySelectorAll('input[type="text"]');
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute(
        "size",
        input[i].getAttribute("placeholder").length + "px"
      );
    }

    await this.getItems({ isApproved: true, isPrinted: false, $or: [{ isExternalCreation: false }, { isExternalCreation: null }] });
    window.onafterprint = this.afterPrint;
  }

  async confirmPrintedSuccessfully(e, certId) {
    const res = await this.httpService.Patch(
      Tables.Certificates,
      { isPrinted: true },
      certId
    );
    if (res.success) {
      this.router.navigate(["/home/certificates/printed"]);
      // await this.getItems({ isApproved: true });
    } else {
      console.log("error print patch", res);
    }
  }

  async printCertificate(item) {
    const r = confirm("Are you sure?");
    if (r == true) {
      this.certificateInHand = this.items.find((x) => {
        return x.id === item;
      });
      // console.log('certificate in hand', this.certificateInHand);
      const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
        table: Tables.BluePrints,
        key: this.certificateInHand["bluePrintId"],
      });
      if (getRequest.success) {
        console.log("get request data:", getRequest["data"]);
        this.prepingPrinting(this.certificateInHand, getRequest["data"]);
      } else {
        alert("The Design Attached to this certificate has been removed");
      }
    }
  }

  async prepingUnprintedPreview(cert, design) {
    const this_ = this;

    //This is a temp fix to handle image stretch issue
    design.template = design.template.map((page) =>
      setHeightAutoForImages(page)
    );

    const dynamicVariables = [
      "identifier",
      "pin",
      "candidate_firstName",
      "candidate_rollNumber",
      "candidate_lastName",
      "candidate_registrationNumber",
      "candidate_rollNumber",
      "certification_endDate",
      "certification_startDate",
      "certification_endDate",
      "certification_title",
    ];
    /*todo: push dynamic variables from cadndidate.fields into dynamicVariables */
    design.template.forEach(function (page, index) {
      const variable = "data=dummy_authentific&";
      const newVal =
        "data=" +
        this_.webBaseUrl +
        "/verify-certificate/" +
        cert["identifier"] +
        "&";
      const regx = new RegExp(variable, "g");
      design.template[index] = page.replace(regx, encodeURI(newVal));
    });
    dynamicVariables.forEach(function (value) {
      const valueArray = value.split("_");
      if (valueArray.length === 1) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(regx, cert[value]);
        });
      }
      if (valueArray.length === 2) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");

          if (
            value == "certification_startDate" ||
            value == "certification_endDate"
          ) {
            const date = cert[valueArray[0]][valueArray[1]];
            design.template[index] = page.replace(regx, date.split("T")[0]);
          } else {
            design.template[index] = page.replace(
              regx,
              cert[valueArray[0]][valueArray[1]]
            );
          }

          // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
        });
      }
      if (valueArray.length === 3) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(
            regx,
            cert[valueArray[0]][valueArray[1]][valueArray[2]]
          );
        });
      }
    });
    const extraStyles =
      '<style id="custom-print-style">@media print { @page {size: ' +
      design.size +
      " " +
      design.orientation +
      ";margin: 1cm; }}</style>";
    $("head").append(extraStyles);

    this.htmlToSave = "";
    design.template.forEach((page, index) => {
      if (index !== 0) {
        this.htmlToSave +=
          '<p style="page-break-before: always"><hr style="margin: 30px 0"></p>';
      }
      this.htmlToSave += design.template[index];
    });

    $(".dynamic-google-fonts").remove();

    const fontsCollection = design.googleFonts.split("|");
    fontsCollection.forEach((f) => {
      if (f.length > 1) {
        $("head").append(
          '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
          f.replace(/\s+/g, "+") +
          '" type="text/css" />'
        );
      }
    });

    $(".cert-holder")
      .html(this.htmlToSave)
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.5);
        $("body").addClass("cert-modal-active");
      });
  }

  async prepingPrinting(cert, design) {
    //This is a temp fix to handle image stretch issue
    design.template = design.template.map((page) =>
      setHeightAutoForImages(page)
    );

    $(".dynamic-fonts,#custom-print-style").remove();
    $(".printing-loading").fadeIn();
    $(".ps-msg").html("Please wait, certificate is being rendered...");
    const this_ = this;
    const dynamicVariables = [
      "identifier",
      "pin",
      "candidate_firstName",
      "candidate_rollNumber",
      "candidate_lastName",
      "candidate_registrationNumber",
      "candidate_rollNumber",
      "certification_endDate",
      "certification_startDate",
      "certification_endDate",
      "certification_title",
    ];
    /*todo: push dynamic variables from cadndidate.fields into dynamicVariables */
    design.template.forEach(function (page, index) {
      const variable = "data=dummy_authentific&";
      const newVal =
        "data=" +
        this_.webBaseUrl +
        "/verify-certificate/" +
        cert["identifier"] +
        "&";
      const regx = new RegExp(variable, "g");

      design.template[index] = page.replace(regx, encodeURI(newVal));
    });
    dynamicVariables.forEach(function (value) {
      const valueArray = value.split("_");
      if (valueArray.length === 1) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(regx, cert[value]);
        });
      }
      if (valueArray.length === 2) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          if (
            value == "certification_startDate" ||
            value == "certification_endDate"
          ) {
            const date = cert[valueArray[0]][valueArray[1]];
            design.template[index] = page.replace(regx, date.split("T")[0]);
          } else {
            design.template[index] = page.replace(
              regx,
              cert[valueArray[0]][valueArray[1]]
            );
          }
          // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
        });
      }
      if (valueArray.length === 3) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(
            regx,
            cert[valueArray[0]][valueArray[1]][valueArray[2]]
          );
        });
      }
    });

    const extraStyles =
      '<style id="custom-print-style">@media print { @page {size: ' +
      design.size +
      " " +
      design.orientation +
      ";margin: 0;} .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>";

    $("head").append(extraStyles);
    this.htmlToSave = "";
    design.template.forEach((page, index) => {
      /*if (index !== 0) {
              this.htmlToSave += '<p style="page-break-before: always">';
            }*/
      this.htmlToSave += design.template[index];
    });

    $(".dynamic-google-fonts").remove();
    const fontsCollection = design.googleFonts.split("|");
    fontsCollection.forEach((f) => {
      if (f.length > 1) {
        $("head").append(
          '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
          f.replace(/\s+/g, "+") +
          '" type="text/css" />'
        );
      }
    });

    this.googleFonts = design.googleFonts;
    // console.log(this.googleFonts)
    $(".printable").remove();

    $("body")
      .append(
        '<div class="printable" style="page-break-inside: avoid;">' +
        this.htmlToSave +
        "</div>"
      )
      .promise()
      .done(async function () {
        const ttl_images = $(".printable img").length;
        if (ttl_images == 0) {
          setTimeout(function () {
            window.print();
            $(".printing-loading").fadeOut();
          }, 2000);
        } else {
          $(".printable img").each(function (index) {
            const $img = $(this);
            const downloadingImage = new Image();

            downloadingImage.onload = function () {
              if (index === ttl_images - 1) {
                setTimeout(function () {
                  window.print();
                  $(".printing-loading").fadeOut();
                }, 2000);
              }
            };
            downloadingImage.src = $img.attr("src");
          });
        }
      });
  }

  async getItems(where: any | null) {
    const res = await this.httpService.GetRelated(
      Tables.Institutes,
      this.user.institute.id,
      Tables.Certificates,
      "",
      [Attributes.certification, Attributes.candidate, Attributes.approvals],
      where
    );

    if (res.success) {
      this.items = this.itemsOnServer = res.data;
      // console.log('certifications: ', this.items);
    } else {
      console.log("err", res);
    }
  }

  async verificationModalTrigger(rowId: any) {
    this.rowId = rowId;
    // this.verificationModal = true;
    await this.printCertificate(rowId);
  }

  showPreview(e) {
    const this_ = this;
    $(".cert-holder")
      .html($(e.target).parents("tr").find(".source-html").html())
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.4);
        $("body").addClass("cert-modal-active");
      });
  }

  async preGeneratePreview(e, item: any) {
    const selectedCert = this.items.find((x) => {
      return x.id === item;
    });
    const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
      table: Tables.BluePrints,
      key: selectedCert["bluePrintId"],
    });
    if (getRequest.success) {
      this.prepingUnprintedPreview(selectedCert, getRequest["data"]);
    } else {
      alert("The Design Attached to this certificate has been removed");
    }
  }

  closePreview() {
    $("body").removeClass("cert-modal-active");
    $(".cert-holder").html("No Certificate Record found");
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(() => {
      const currentHeight = document
        .getElementById("the-certificate")
        .getBoundingClientRect().height;
      const currentWidth = document
        .getElementById("the-certificate")
        .getBoundingClientRect().width;
      $(".cert-holder").css("width", currentWidth + "px");
      $(".cert-holder").css("height", currentHeight + "px");
    }, 500);
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(
        (x) =>
          x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
          x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
          x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
          x.certification.title.toLowerCase().includes(e.toLowerCase())
      );
    } else {
      this.items = this.itemsOnServer;
    }
  }
}

function setHeightAutoForImages(htmlString) {
  // Parse the HTML string into a DOM object
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Find all elements with the 'resizeable' class
  const images = Array.from(doc.querySelectorAll("img.resizeable"));

  // Set the height of each image to '100%'
  images.forEach((image) => {
    (image as HTMLImageElement).style.height = "100%";
  });

  // Serialize the document back to HTML string
  var serializedString = new XMLSerializer().serializeToString(doc);

  return serializedString;
}
