import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedHttpService } from "../../services/shared-http.service";
import { Router } from "@angular/router";
import { Attributes, Tables } from "../../../Models/Tables";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { TransporterService } from "../../services/transporter.service";
import { Subscription } from "rxjs";
import * as $ from "jquery";
import { BluePrint } from "../../../Models/BluePrint";
import { environment } from "../../../environments/environment";
import { FormControl, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import verify from "./binance/verify.js";
import web3 from "./binance/web3";
import Web3DefaultAccount from "./binance/returnDefaultAccount.js";
import { BlockchainCertificatePdfComponent } from "./blockchain-certificate-pdf/blockchain-certificate-pdf.component";
declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}
@Component({
  selector: "app-printed-certificates",
  templateUrl: "./printed-certificates.component.html",
  styles: [],
})
export class PrintedCertificatesComponent
  extends BaseComponent
  implements OnInit {
  items: any;
  itemsOnServer: any;
  htmlToSave = "";
  googleFonts = "";
  webBaseUrl = environment.webBaseUrl;
  rowId: any;
  certificateInHand: any;
  private apiBaseUrl;
  reportStudentName;
  reportdegreeTitle;
  reportPassingYear;
  reportRollNumber;
  reportRegistration;
  reportPin;
  reportSeal;
  reportSignature;
  userInstituteInfo;
  isUploadToBlockchain: any = {};
  blockchainFiles: any = [];
  isUploading = false;
  isDownloading = false;
  myFiles: string[] = [];
  blockChainFileData: any;
  @ViewChild(BlockchainCertificatePdfComponent)
  child: BlockchainCertificatePdfComponent;
  myForm = new FormGroup({
    files: new FormControl(""),
  });

  afterPrint = async (e) => {
    console.log("e");
    // console.log(e)
    const data = {
      isReportGenerated: true,
      // isPrinted: true,
      // print: this.htmlToSave,
      // googleFonts: this.googleFonts
    };
    const res = await this.httpService.Patch(
      Tables.Certificates,
      data,
      this.certificateInHand.id
    );
    if (res.success) {
      await this.getItems({ isApproved: true });
    } else {
      console.log("error print patch", res);
    }
    $(".printable").remove();
  };
  constructor(
    private httpService: SharedHttpService,
    private router: Router,
    private http: HttpClient
  ) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  async ngOnInit() {
    this.userInstituteInfo = this.user.institute;

    this.reportSignature =
      this.apiBaseUrl +
      "/Attachments/institute-" +
      this.userInstituteInfo.id +
      "-images/download/" +
      this.userInstituteInfo.signature;
    this.reportSeal =
      this.apiBaseUrl +
      "/Attachments/institute-" +
      this.userInstituteInfo.id +
      "-images/download/" +
      this.userInstituteInfo.seal;

    $("body").addClass("cert-printing-page");
    await this.getItems({ isPrinted: true, isVoid: false, $or: [{ isExternalCreation: false }, { isExternalCreation: null }] });
    this.showBlockchainFileList();
  }

  // -----blockchain function-----------------
  get f() {
    return this.myForm.controls;
  }
  deleteSelectedFile(id) {
    (<HTMLInputElement>document.getElementById("selectedFile")).value = "";
    this.myFiles = [];
    this.isUploadToBlockchain[id] = false;
  }

  inputFieldClick() {
    (<HTMLInputElement>document.getElementById("selectedFile")).value = "";
  }
  onFileChange(event, id) {
    this.myFiles = [];
    if (event.target.files.length > 0) {
      this.isUploadToBlockchain[id] = true;
    }
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
  }
  async downloadFromBlockchain(id, fileName) {
    let accounts = await web3.eth.getAccounts();
    let defaultAccount = accounts[0];
    if (!defaultAccount) {
      const ethEnabled = async () => {
        if (window.ethereum) {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          try {
            window.web3(window.ethereum);
          } catch (error) {
            return true;
          }
          return true;
        }
        return false;
      };
      await ethEnabled();
      accounts = await web3.eth.getAccounts();
      defaultAccount = accounts[0];
    }
    this.isDownloading = true;
    const file: any = await this.http
      .post(`${environment.blockchainBaseUrl}/get-file-database`, { id })
      .toPromise();
    let ipfsFileHash = await verify.methods.getPdfLink(file.mainFileId).call();
    const url: any = await this.http
      .post(`${environment.blockchainBaseUrl}/download-file-blockchain`, {
        ipfsFileHash,
      })
      .toPromise();
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = `${environment.blockchainUrl}${url.pdf}`;
    link.setAttribute("download", `${file.fileName}`);
    document.body.appendChild(link);
    link.click();
    this.isDownloading = false;
  }
  async onUploadBlockchain(id) {
    const defaultAccount = await Web3DefaultAccount();
    if (!defaultAccount) return;
    let uploadMultipleresult = false;
    this.blockchainFiles.map((files) => {
      if (files.length > 0 && files[0].mainFileId === id) {
        uploadMultipleresult = true;
      }
    });
    if (uploadMultipleresult) {
      window.alert("Error! You can't upload multiple file!");
      this.isUploading = false;
      (<HTMLInputElement>document.getElementById("selectedFile")).value = "";
      this.myFiles = [];
      this.isUploadToBlockchain = [];
      return;
    }
    this.isUploading = true;
    const formData = new FormData();
    formData.append("file", this.myFiles[0]);
    formData.append("id", id);
    let uploadIpfsRes: any;
    try {
      uploadIpfsRes = await this.http
        .post(`${environment.blockchainBaseUrl}/upload-ipfs`, formData)
        .toPromise();
    } catch (error) {
      console.log(error);
      window.alert("Something went wrong!");
    }

    if (!this.items[0].institute.blockchainAddress) {
      window.alert("Something went wrong");
      return;
    }

    const amount_to_send_wei =
      this.items[0].institute.blockchainFees * 1000000000000000000;
    const blockchainAddress = this.items[0].institute.blockchainAddress;
    //   const blockchainAddress = '0x5670bA03FB73D5942e775342142fD9fa04BbcC0F';
    //  const amount_to_send_wei =
    //     0.0005 * 1000000000000000000;

    let result = await verify.methods
      .addPdfLink(
        uploadIpfsRes.mainFileId,
        uploadIpfsRes.ipfsLink,
        blockchainAddress
      )
      .send({ from: defaultAccount, value: amount_to_send_wei })
      .then(
        async (res) => {
          function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
          }
          uploadIpfsRes.transactionHistory = res;
          uploadIpfsRes.userId = JSON.parse(localStorage.getItem("user")).id;
          const saveToDB: any = await this.http
            .post(`${environment.blockchainBaseUrl}/save-database`, {
              uploadIpfsRes,
            })
            .toPromise();

          if (saveToDB) {
            this.blockChainFileData = [saveToDB];
            await delay(1000);
            console.log(this.blockChainFileData);
            this.child.pdf();
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = "reload";
            this.router.navigate([currentUrl]);
            this.isUploading = false;
          }
        },
        (err) => {
          console.log(err);
          if (err.code == 4001) {
            window.alert("User has declined payment!! Error!!");
            this.isUploading = false;
            (<HTMLInputElement>document.getElementById("selectedFile")).value =
              "";
            this.myFiles = [];
            this.isUploadToBlockchain = [];
            return;
          }
        }
      );

    this.isUploading = false;
  }
  async showBlockchainFileList() {
    if (this.items.length > 0) {
      this.items.forEach(async (item) => {
        const result = await this.getBlockchainFile(item.id);
        this.blockchainFiles.push(result);
      });
    }
  }
  async getBlockchainFile(id: string) {
    const files: any = await this.http
      .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, { id })
      .toPromise();
    return files;
  }

  // -------------end blockchain function--------------

  async getItems(where: any | null) {
    const res = await this.httpService.GetRelated(
      Tables.Institutes,
      this.user.institute.id,
      Tables.Certificates,
      "",
      [
        Attributes.certification,
        Attributes.candidate,
        Attributes.approvals,
        Attributes.NFCTag,
        Attributes.institute,
      ],
      where
    );
    if (res.success) {
      // console.log('cert data', res);
      this.items = this.itemsOnServer = res.data;
    } else {
      console.log("err", res);
    }
  }

  async voidPrint(rowId: any) {
    let data = { isVoid: true };
    const res = await this.httpService.Patch(Tables.Certificates, data, rowId);
    if (res.success) {
      this.router.navigate(["/home/certificates/voided"]);
      // await this.getItems({ isPrinted: true });
    }
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(
        (x) =>
          x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
          x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
          x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
          x.certification.title.toLowerCase().includes(e.toLowerCase())
      );
    } else {
      this.items = this.itemsOnServer;
    }
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(() => {
      const currentHeight = document
        .getElementById("the-certificate")
        .getBoundingClientRect().height;
      const currentWidth = document
        .getElementById("the-certificate")
        .getBoundingClientRect().width;
      $(".cert-holder").css("width", currentWidth + "px");
      $(".cert-holder").css("height", currentHeight + "px");
    }, 500);
  }

  showPreview(e) {
    const this_ = this;

    $(".cert-holder")
      .html($(e.target).parents("tr").find(".source-html").html())
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.5);
        $("body").addClass("cert-modal-active");
      });
  }

  closePreview() {
    $("body").removeClass("cert-modal-active");
    $(".cert-holder").html("No Certificate Record found");
  }

  async prepingPrinting(cert, design) {
    $(".dynamic-fonts,#custom-print-style").remove();
    $(".printing-loading").fadeIn();
    $(".ps-msg").html("Please wait, certificate is being rendered...");
    const this_ = this;
    const dynamicVariables = [
      "identifier",
      "pin",
      "candidate_firstName",
      "candidate_rollNumber",
      "candidate_lastName",
      "candidate_registrationNumber",
      "candidate_rollNumber",
      "certification_endDate",
      "certification_startDate",
      "certification_endDate",
      "certification_title",
    ];
    design.template.forEach(function (page, index) {
      const variable = "data=dummy_authentific&";
      const newVal =
        "data=" +
        this_.webBaseUrl +
        "/verify-certificate/" +
        cert["identifier"] +
        "&";
      const regx = new RegExp(variable, "g");
      design.template[index] = page.replace(regx, encodeURI(newVal));
    });
    dynamicVariables.forEach(function (value) {
      const valueArray = value.split("_");
      if (valueArray.length === 1) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(regx, cert[value]);
        });
      }
      if (valueArray.length === 2) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          if (value == "createdAt") {
            const date = cert[valueArray[0]][valueArray[1]];
            design.template[index] = page.replace(regx, date.split("T")[0]);
          } else {
            design.template[index] = page.replace(
              regx,
              cert[valueArray[0]][valueArray[1]]
            );
          }
          // design.template[index] = page.replace(regx, cert[valueArray[0]][valueArray[1]]);
        });
      }
      if (valueArray.length === 3) {
        design.template.forEach(function (page, index) {
          const variable = "#" + value;
          const regx = new RegExp(variable, "g");
          design.template[index] = page.replace(
            regx,
            cert[valueArray[0]][valueArray[1]][valueArray[2]]
          );
        });
      }
      if (value == "certificateImage") {
        if (cert["certificateImage"]) {
          design.template.forEach(function (page, index) {
            console.log(page);
            const variable = "367e4a0c-d562-4c52-85c9-c2e4580a24be.jpg";
            // const regx = new RegExp(variable, 'g');
            design.template[index] = page.replace(
              "367e4a0c-d562-4c52-85c9-c2e4580a24be.jpg",
              cert["certificateImage"]
            );
          });
        }
      }
    });

    console.log("design size", design.size);
    console.log("design orienteation", design.orientation);

    const extraStyles =
      '<style id="custom-print-style">@media print { @page {size: ' +
      design.size +
      " " +
      design.orientation +
      ";margin: 0; } .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>";

    $("head").append(extraStyles);

    this.htmlToSave = "";
    design.template.forEach((page, index) => {
      /*if (index !== 0) {
          this.htmlToSave += '<p style="page-break-before: always">';
        }*/
      this.htmlToSave += design.template[index];
    });

    $(".dynamic-google-fonts").remove();
    const fontsCollection = design.googleFonts.split("|");
    fontsCollection.forEach((f) => {
      if (f.length > 1) {
        $("head").append(
          '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
          f.replace(/\s+/g, "+") +
          '" type="text/css" />'
        );
      }
    });

    this.googleFonts = design.googleFonts;
    $(".printable").remove();
    $("body")
      .append('<div class="printable">' + this.htmlToSave + "</div>")
      .promise()
      .done(function () {
        const ttl_images = $(".printable img").length;
        $(".printable img").each(function (index) {
          const $img = $(this);
          const downloadingImage = new Image();
          downloadingImage.onload = function () {
            if (index === ttl_images - 1) {
              setTimeout(function () {
                window.print();
                $(".printing-loading").fadeOut();
              }, 2000);
            }
          };
          downloadingImage.src = $img.attr("src");
        });
      });
  }

  async generateReport(item, e) {
    this.reportStudentName =
      item.candidate.firstName + " " + item.candidate.lastName;
    this.reportRegistration = item.candidate.registrationNumber;
    this.reportRollNumber = item.candidate.rollNumber;
    this.reportPassingYear = item.certification.printingDeadline.split("T")[0];
    this.reportdegreeTitle = item.certification.title;
    this.reportPin = item.pin;

    var verificationUrl =
      this.webBaseUrl + "/verify-certificate/" + item.identifier;
    // var qrUrl =
    //   "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
    //   verificationUrl +
    //   "&chld=M|0";

    var qrUrl =
      "https://api.qrserver.com/v1/create-qr-code/?data=" +
      encodeURIComponent(verificationUrl) +
      "&size=500x500";

    $("#report-qr-img").attr("src", qrUrl);

    const r = confirm("Are you sure?");
    if (r == true) {
      this.certificateInHand = item;
      let design = item.print;

      // this.prepingPrinting(this.certificateInHand, item.print);
      setTimeout(() => {
        let designDiv = "#potrait-report-div";
        let extraStyles =
          '<style id="custom-print-style">@media print {#the-certificate{ transform: scale(0.6) !important; } .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>';
        if (design.search("size-a4-landscape") > 0) {
          designDiv = "#landscape-report-div";
          extraStyles =
            '<style id="custom-print-style">@media print {#the-certificate{ transform: scale(0.8) !important; } .the-real-liner{ border: 0.1px solid black; background-color:black !important; }}</style>';
        }

        $("head").append(extraStyles);

        this.htmlToSave = design;

        $(".dynamic-google-fonts").remove();

        $(".printable").remove();
        $(designDiv + " #report-cert-img").html(this.htmlToSave);
        var printableDiv = $(designDiv).html();
        // console.log(printableDiv)
        $("body")
          .append('<div class="printable">' + printableDiv + "</div>")
          .promise()
          .done(function () {
            const ttl_images = $(".printable img").length;
            $(".printable img").each(function (index) {
              const $img = $(this);
              const downloadingImage = new Image();
              downloadingImage.onload = function () {
                if (index === ttl_images - 1) {
                  setTimeout(function () {
                    window.print();
                    $(".printing-loading").fadeOut();
                  }, 2000);
                }
              };
              downloadingImage.src = $img.attr("src");
            });
          });
      }, 1000);
    }
  }

  async printCertificate(item) {
    const r = confirm("Are you sure?");
    if (r == true) {
      this.certificateInHand = this.items.find((x) => {
        return x.id === item;
      });
      const getRequest = await this.httpService.Get<BluePrint>(BluePrint, {
        table: Tables.BluePrints,
        key: this.certificateInHand["bluePrintId"],
      });
      if (getRequest.success) {
        this.prepingPrinting(this.certificateInHand, getRequest["data"]);
      } else {
        alert("The Design Attached to this certificate has been removed");
      }
    }
  }
}
