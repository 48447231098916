import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styles: []
})
export class ActivityLogComponent extends BaseComponent implements OnInit {

    rows = [];

    constructor(private sharedHttpService: SharedHttpService, private router: Router) {
        super(router);
    }

    async ngOnInit() {

        const res = await this.sharedHttpService.GetRelated(Tables.Institutes, this.user.instituteId,
            Tables.ActivityLogs, '', Attributes.staff);
        if (res.success) {
            this.rows = res.data;
            // console.log(this.rows)
        }

    }

}
