import {Component} from '@angular/core';
import {Person} from '../../Models/Person';
import {Router} from '@angular/router';

@Component({
    selector: 'app-base',
    template: '<div></div>',
    styles: []
})

export class BaseComponent {

    user: Person;
    permissions;

    constructor(router: Router) {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.permissions = this.user.staffCategory.permissions;
        if (!this.user) {
            router.navigate(['/signin']);
        } else {
          if (this.user.require2FA && !this.user.verified2FA) {
            router.navigate(['/home/2FA-step']);
          }
        }
    }

}


