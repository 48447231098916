export class YubikeyModel {
  yubikeyId: string;
  requireYubikey: boolean;

  constructor() {
    this.yubikeyId = '';
    this.requireYubikey = false;
  }


}


