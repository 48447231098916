import {Component, Input, OnInit} from '@angular/core';
import {SharedHttpService} from '../../../services/shared-http.service';
import {Router} from '@angular/router';
import {Attributes, Tables} from '../../../../Models/Tables';
import {BaseComponent} from '../../../BaseComponent/BaseComponent';

@Component({
    selector: 'app-certificates-access-rights',
    templateUrl: './certificates-access-rights.component.html',
    styles: []
})
export class CertificatesAccessRightsComponent extends BaseComponent implements OnInit {

    items: any;
    @Input() id: any;

    constructor(private httpService: SharedHttpService, private router: Router) {
        super(router);
    }

    ngOnInit() {
        this.getItems();
    }

    async getItems() {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.StaffCertifications, '',
            [Attributes.certification], {staffId: this.id});
        if (res.success) {
            // console.log('data', res);
            this.items = res.data;
        } else {
            console.log('err', res);
        }
    }

    submit() {
    }
}
