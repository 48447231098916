import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Attributes,Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Paper} from '../../../Models/Paper';
import {ExcelService} from '../../services/excel.service';

@Component({
  selector: 'app-scan-report',
  templateUrl: './scan-report.component.html',
  styleUrls: ['./scan-report.component.css']
})
export class ScanReportComponent extends BaseComponent implements OnInit {

  stock;
  items: any;
  itemList: any;
  itemsOnServer: any;
  noData = false;
  certificateId;

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router, private route: ActivatedRoute) {
    super(router);
  }

  async ngOnInit() {
    this.certificateId = this.route.snapshot.params['id']
    await this.getNFCTagScanReport();
  }

  async getNFCTagScanReport() {
    const where = { certificateId: this.certificateId};
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.NFCTagScans,'',[Attributes.NFCTag],where);
    if (res.success) {
      
      // console.log('data stock', res);
      this.items = res.data;
      this.prepareData();
      // console.log('items', this.items);
    } else {
      console.log('err', res);
    }
  }

  prepareData(){
    const items = this.items;
   
    
    let dateList = [];
    let itemList = [];
    items.forEach(element => {

      if(element.createdAt){
        let date = element.createdAt.split('T')[0];
        if(!dateList.includes(date)){
          dateList.push(date);
          let data  = element;
          data.count = 1;
          itemList.push(data);
        }else{
          console.log(itemList)
          const item = itemList.find(x => x.createdAt.split('T')[0] === element.createdAt.split('T')[0]);
          console.log(item)
          item.count+=1;
          
        }
      }
      
    });
    console.log('itemList')
    console.log(itemList)
    this.itemList = itemList;

  }
}
