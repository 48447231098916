import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    constructor() {
    }


    importExcelData(evt, callback) {
        const target: DataTransfer = <DataTransfer>(evt.target);
        let okayToImport = true;
        // console.log('target', target);
        if (target.files.length > 1) {
            console.log('Cannot use multiple files');
            return false;
        }
        // console.log('target.files', target.files);
        // console.log(target.files[0]);
        // console.log(target.files[0].name);
        if (target.files[0].name.includes('xlsx')||target.files[0].name.includes('csv')) {
            
        const reader: FileReader = new FileReader();
        if (okayToImport) {
            reader.onload = async (e: any) => {
                /* read workbook */

                const bstr: string = e.target.result;
                const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

                const wsname: string = wb.SheetNames[0];
                const ws: XLSX.WorkSheet = wb.Sheets[wsname];

                callback(XLSX.utils.sheet_to_json(ws, {header: 2}));

            };

            reader.readAsBinaryString(target.files[0]);
        }
    }else{
        alert('Only .xlsx file is acceptable.');
            okayToImport = false;
        }
    }

    }

