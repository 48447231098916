import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { GetUserDataService } from "./get-user-data.service";

@Injectable({
  providedIn: "root",
})
export class BlockchainGuard implements CanActivate {
  constructor(
    private router: Router,
    private getUserDataService: GetUserDataService
  ) {}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = await this.getUserDataService.returnUserData();
    if (user && user.institute) {
      if (user.institute.authentificUser) return true;
      else {
        this.router.navigate(["home/blockchain"]);
        return false;
      }
    } else {
      localStorage.clear();
      this.router.navigate(["signin"]);
      return false;
    }
  }
}
