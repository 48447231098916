import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Tables } from "../../../Models/Tables";
import { SharedHttpService } from "../../services/shared-http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormService } from "../../services/formservice.service";
import { AdminProfileComponent } from "../admin-profile.component";
import { GetUserDataService } from "../../services/get-user-data.service";

@Component({
  selector: "app-admin-settings",
  templateUrl: "./admin-settings.component.html",
  styles: [],
})
export class AdminSettingsComponent
  extends AdminProfileComponent
  implements OnInit
{
  public form: FormGroup;
  successAlert = false;
  errorAlert = false;
  alertMsg: any;

  constructor(
    httpService: SharedHttpService,
    router: Router,
    private fb: FormBuilder,
    private formService: FormService,
    route: ActivatedRoute
  ) {
    super(route, httpService, router);
  }

  async ngOnInit() {
    this.form = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });

    console.log(this.user);

    this.form.setValue({
      firstName: this.user.firstName,
      lastName: this.user.lastName,
    });
  }

  clearForm() {
    this.form.reset();
  }

  async submit() {
    if (this.form.valid) {
      const res = await this.httpService.Patch(
        Tables.Staff,
        this.form.value,
        this.user.id
      );
      if (res.success) {
        // console.log('success', res);
        this.user.firstName = res.data.firstName;
        this.user.lastName = res.data.lastName;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
        }, 3000);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    } else {
      // console.log('form invalid');
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }
}
