import { Component, OnInit } from '@angular/core';
import {Paper} from '../../../Models/Paper';
import {Tables} from '../../../Models/Tables';
import {ExcelService} from '../../services/excel.service';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';

@Component({
  selector: 'app-new-nfc-tags',
  templateUrl: './new-nfc-tags.component.html',
  styles: []
})
export class NewNfcTagsComponent extends BaseComponent implements OnInit {

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router) {
    super(router);
  }

  ngOnInit() {
  }


  async importExcelData(evt) {
    const page = this;
    const excelData = this.excelService.importExcelData(evt, async excelData => {
      // console.log('res2', excelData);
      if (!excelData) {
        console.log('return');
        return;
      }
      var dataToPost= {nfcIds:excelData,institute:this.user.institute.id}
      const res = await this.httpService.PostWithoutModel('/NFCTags/assignToUser',dataToPost)
      if (res.success) {
          // console.log('excel import', res);
          this.router.navigate(['/home/nfc-tags/view']);
        }
    });

  }

}
