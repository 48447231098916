import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  StripeService,
  StripeCardComponent,
  ElementOptions,
  ElementsOptions,
} from "ngx-stripe";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SharedHttpService } from "../services/shared-http.service";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: ElementOptions = {
    style: {
      base: {
        iconColor: "#666EE8",
        color: "#31325F",
        lineHeight: "40px",
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: "18px",
        "::placeholder": {
          color: "#CFD7E0",
        },
      },
    },
  };

  elementsOptions: ElementsOptions = {
    locale: "en",
  };

  stripeTest: FormGroup;
  registerForm: FormGroup;
  constructor(
    private router: Router,
    private httpService: SharedHttpService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {}
  selectedPlan: string = "authentificProCloud";
  isPaymentForm = false;

  ngOnInit() {
    this.registerForm = new FormGroup({
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [Validators.required]),
      companyName: new FormControl(null, [Validators.required]),
      designation: new FormControl(null, [Validators.required]),
      officeLandlineNumber: new FormControl(null, [Validators.required]),
      companyWebsite: new FormControl(null, [Validators.required]),
      officeAddress: new FormControl(null, [Validators.required]),
      governmentIssuedId: new FormControl(null, [Validators.required]),
      businessRegistrationCertificate: new FormControl(null, [
        Validators.required,
      ]),
    });
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
    });
  }

  signInTrigger() {
    this.router.navigate(["/signin"]);
  }

  onSubmit() {
    if (!this.registerForm.valid) return;
    // const payload = {
    //   name: this.registerForm.value.instituteName,
    //   website: this.registerForm.value.instituteWebsite,
    //   levels: 4,
    //   city: this.registerForm.value.city,
    //   country: this.registerForm.value.country,
    //   address: this.registerForm.value.address,
    //   principalContact: this.registerForm.value.phoneNumber,
    //   certificateLimit: 10,
    //   blockchainAddress: "0x5670bA03FB73D5942e775342142fD9fa04BbcC0F",
    //   blockchainFees: "0.005",
    //   authentificUser: true,
    //   blockchainUser: true,
    // };
    // const res = this.httpService.createInstitute(payload);
    // console.log({ res });
    console.log(this.registerForm.value);
    this.isPaymentForm = true;
  }

  setSelectedPlan(plan: string) {
    this.selectedPlan = plan;
  }

  onPreviousClick() {
    this.isPaymentForm = false;
  }

  onGovernmentIssuedIdChanged(event) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      if (file.size > 500000) {
        window.alert("File size should be less than 500kb");
        (
          document.getElementById("governmentIssuedId") as HTMLInputElement
        ).value = "";
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.registerForm.patchValue({
          governmentIssuedId: {
            name: file.name,
            value: reader.result,
            type: file.type,
            size: file.size,
          },
        });
      };
      reader.onerror = function () {
        console.log("there are some problems");
      };
    }
  }

  onBusinessRegisterCertificateChanged(event) {
    if (event.target.files.length > 0) {
      var file = event.target.files[0];
      if (file.size > 500000) {
        window.alert("File size should be less than 500kb");
        (
          document.getElementById(
            "businessRegistrationCertificate"
          ) as HTMLInputElement
        ).value = "";
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.registerForm.patchValue({
          businessRegistrationCertificate: {
            name: file.name,
            value: reader.result,
            type: file.type,
            size: file.size,
          },
        });
      };
      reader.onerror = function () {
        console.log("there are some problems");
      };
    }
  }

  buy() {
    console.log(this.registerForm.value, "ddd");
    const name = this.stripeTest.get("name").value;
    this.stripeService
      .createToken(this.card.getCard(), { name })
      .subscribe((result) => {
        if (result.token) {
          const selectedPlan = this.selectedPlan;
          let amount;
          if (selectedPlan === "authentificBlockchain") amount = 29900;
          else if (selectedPlan === "authentificProCloud") amount = 54900;
          else if (selectedPlan === "authentificProHybrid") amount = 199900;
          const payload = {
            payment_info: {
              source: result.token.id,
              amount: amount,
            },
            user_info: this.registerForm.value,
          };
          this.httpService
            .stripePayment(payload)
            .then((res) => {
              if (!res.success) {
                window.alert("Your payment is failed. Please try again.");
                return;
              }
              window.alert(
                "Your payment is successful. We will get back to you."
              );
              this.router.navigate(["/signin"]);
            })
            .catch((err) => {
              window.alert("Your payment is failed. Please try again.");
            });
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }
}
