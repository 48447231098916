import {Certification} from './Certification';
import {InputField} from './InputField';

export class BluePrint {
    id: string;
    title: string;
    instituteId: string;
    fields: Array<InputField> ;
    template: string;
    quality: string;
    size: string;
    orientation: string;
    googleFonts: string;
    certifications: Array<Certification>;

    constructor(instituteId: string = '') {
        this.instituteId = instituteId;
        this.fields = [new InputField()];
        this.title = '';
        this.quality = 'ppi_150';
        this.orientation = 'landscape';
        this.size = 'a4';
        this.googleFonts = "Abel";
    }

}
