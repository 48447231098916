import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Person} from '../../../Models/Person';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';

@Component({
    selector: 'app-security-settings',
    templateUrl: './security-settings.component.html',
    styles: []
})
export class SecuritySettingsComponent extends BaseComponent implements OnInit {

    public form: FormGroup;

    constructor(private fb: FormBuilder, private httpService: SharedHttpService, private router: Router) {
        super(router);
    }

    ngOnInit() {
    }


    async submit() {
        // console.log('val', this.form.value);
/*        const res = await this.httpService.PostRelation<Person>
        (Tables.Staff, this.form.value, Tables.Institutes, this.user.institute.id);
        console.log('res', res);
        if (res.success) {
        }*/
    }
}
