import { Component, OnInit } from "@angular/core";
// import { environment } from '../../../environments/environment.prod';
import { environment } from "src/environments/environment";

@Component({
  selector: "app-blockchain",
  templateUrl: "./blockchain.component.html",
  styleUrls: ["./blockchain.component.css"],
})
export class BlockchainComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  onVerifyDocumentClick() {
    window.open(environment.verifyCertificateUrl, "_blank");
  }
}
