import { Component, OnInit } from "@angular/core";
import { SharedHttpService } from "../services/shared-http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Attributes, Tables } from "../../Models/Tables";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as $ from "jquery";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-verify-certificate-by-nfc",
  templateUrl: "./verify-certificate-by-nfc.component.html",
  styleUrls: ["./verify-certificate-by-nfc.component.css"],
})
export class VerifyCertificateByNFCComponent implements OnInit {
  identifier: any;
  token: any;
  public form: FormGroup;
  notVerified = false;
  verified = false;
  certificate: any;
  certhtml = "";
  apiBaseUrl: any;
  currentHeight = 0;
  currentWidth = 0;
  nfcData: any;

  constructor(
    private httpService: SharedHttpService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.token = this.route.snapshot.params["token"];
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  ngOnInit() {
    this.getTokenData();
  }
  async getTokenData() {
    const tokenData = await this.httpService.verifyCertificateByNFC(this.token);
    console.log(tokenData);
    this.nfcData = tokenData.data;
    if (tokenData.data) {
      const res = await this.httpService.GetFromTable(
        Tables.Certificates,
        [Attributes.candidate, Attributes.certification],
        { _id: tokenData.data.certificateId }
      );

      this.certificate = res.data[0];
      if (res["data"] == null || res.data.length < 1) {
        this.notVerified = true;
        this.verified = false;
      } else {
        this.notVerified = false;
        this.verified = true;
        this.certhtml = this.certificate.print;
        if (this.certificate.googleFonts) {
          $(".dynamic-google-fonts").remove();
          const fontsCollection = this.certificate.googleFonts.split("|");
          fontsCollection.forEach((f) => {
            if (f.length > 1) {
              $("head").append(
                '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
                  f.replace(/\s+/g, "+") +
                  '" type="text/css" />'
              );
            }
          });
        }
      }
    } else {
      this.notVerified = true;
      this.verified = false;
    }

    // if (this.form.valid) {
    //     /*     const res = await this.httpService.GetFromTable(Tables.Certificates, [Attributes.candidate, Attributes.certification],
    //            {identifier: this.identifier, pin: this.form.value.pin, isPrinted: true, isVoid: {or:[null,false]}});*/

    // } else {
    //     // console.log('form valid', this.form.valid);
    //     Object.keys(this.form.controls).forEach(field => {
    //         const control = this.form.get(field);
    //         control.markAsDirty({onlySelf: true});
    //     });
    // }
  }

  closePreview() {
    $("body").removeClass("cert-modal-active");
    $(".cert-holder").html("No Certificate Record found");
  }

  showPreview() {
    const this_ = this;
    $(".cert-holder")
      .html($(".source-html").html())
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.2);
        $("body").addClass("cert-modal-active");
      });
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(() => {
      console.log(document.getElementsByClassName("cert-in-preview"));
      this.currentHeight = document
        .getElementById("the-certificate")
        .getBoundingClientRect().height;
      this.currentWidth = document
        .getElementById("the-certificate")
        .getBoundingClientRect().width;
      $(".cert-holder").css("width", this.currentWidth + "px");
      $(".cert-holder").css("height", this.currentHeight + "px");
    }, 500);
  }
}
