import {Candidate} from './Candidate';
import {Certification} from './Certification';
import {Paper} from './Paper';
import {Person} from './Person';
import {nonZeroValidator} from '../Validators/nonZeroValidator';
import {Validators} from '@angular/forms';

export class Certificate {

    validators = {certificationId: Validators.required, candidateId: Validators.required,bluePrintId:Validators.required};
    verificationUrl: string;
    status: string;
    id: string;
    staffId: string;
    candidateId: string;
    certificationId: string;
    paperId: string;
    candidate: Candidate;
    certfication: Certification;
    paper: Paper;
    approvals: Array<Person>;
    bluePrintId: string;
    instituteId: string;

    constructor(candidateId: any=0, instituteId: string,certificationId:any=0,bluePrintId:any=0) {

        this.candidateId = candidateId;
        this.instituteId = instituteId;
        this.certificationId = certificationId;
        this.bluePrintId = bluePrintId;

    }

}


