import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nfc-tags',
  templateUrl: './nfc-tags.component.html',
  styles: []
})
export class NfcTagsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
