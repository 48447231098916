import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {BaseComponent} from '../BaseComponent/BaseComponent';
import {Router} from '@angular/router';
import {SharedHttpService} from '../services/shared-http.service';
import { formatDate } from '@angular/common';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styles: []
})

export class DashboardComponent extends BaseComponent implements OnInit {

    metrics: any;
    items: any;
    noData = false;

    constructor(private router: Router, private httpService: SharedHttpService) {
        super(router);
    }

    async ngOnInit() {
        $('.preloader').fadeOut();
        await this.getDashboardMetrics();
        await this.getItems();
    }


    async getDashboardMetrics() {
        let queryString = '/Institutes/get-metrics?';
        let paramsString = 'institute=' + this.user.institute.id + '&userId=' + this.user.id;
        const res = await this.httpService.GetUrl(queryString, paramsString);
        if (res.success) {
            // console.log('metrics', res);
            this.metrics = res.data;
            // console.log('metrics', this.metrics);
        } else {
            console.log('metrics err', res);
        }
    }

    getRowColor(item){
        if(item.total>0){
            if(item.total==item.printed){
                return '#49c749';
            }else if(formatDate(new Date(), 'yyyy-MM-dd', 'en-US')>formatDate(item.printingDeadline, 'yyyy-MM-dd', 'en-US')){
                return '#ee5757';
            }
        }else{
            return '';
        }
       
       
        
    }

    async getItems() {
        let queryString = '/certifications/certification-metrics?';
        let paramsString = 'institute=' + this.user.institute.id;
        const res = await this.httpService.GetUrl(queryString, paramsString);
        if (res.success) {
            // console.log('data course', res);
            this.items = res.data;
            if (this.items === null || this.items.length === 0) {
                this.noData = true;
            } else {
                this.noData = false;
            }
        } else {
            console.log('err', res);
        }
    }

    updateFilter(e) {
    }

}
