import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { NgSelectModule } from "@ng-select/ng-select";
import { ColorChromeModule } from "ngx-color/chrome";
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
} from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha/forms";

import { DatePipe } from "@angular/common";
import { AppComponent } from "./app.component";
import { RouterModule, Routes } from "@angular/router";
import { HeaderComponent } from "./shared/header/header.component";
import { SideBarComponent } from "./shared/side-bar/side-bar.component";
import { SignInComponent } from "./signin/sign-in.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SharedHttpService } from "./services/shared-http.service";
import { TransporterService } from "./services/transporter.service";
import { BaseComponent } from "./BaseComponent/BaseComponent";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { HomeComponent } from "./home/home.component";
import { SignInStaffComponent } from "./sign-in-staff/sign-in-staff.component";
import { SettingsComponent } from "./settings/settings.component";
import { ChangePasswordComponent } from "./admin-profile/change-password/change-password.component";
import { ActivityLogComponent } from "./admin-profile/activity-log/activity-log.component";
import { YubikeySetingsComponent } from "./admin-profile/yubikey-setings/yubikey-setings.component";
import { CertificatesComponent } from "./certificates/certificates.component";
import { AddCertificatesFieldsComponent } from "./add-certificates-fields/add-certificates-fields.component";
import { CertificatesAccessRightsComponent } from "./users/user-profile/certificates-access-rights/certificates-access-rights.component";
import { UserProfileComponent } from "./users/user-profile/user-profile.component";
import { UserSettingsComponent } from "./users/user-profile/user-settings/user-settings.component";
import { UserTimelineComponent } from "./users/user-profile/user-timeline/user-timeline.component";
import { AdminProfileComponent } from "./admin-profile/admin-profile.component";
import { PersonalSettingsComponent } from "./personal-settings/personal-settings.component";
import { InstituteSettingsComponent } from "./settings/institute-settings/institute-settings.component";
import { SecuritySettingsComponent } from "./settings/security-settings/security-settings.component";
import { AdminSettingsComponent } from "./admin-profile/admin-settings/admin-settings.component";
import { CertificationsComponent } from "./certifications/certifications.component";
import { ViewCertificationsComponent } from "./certifications/view-certifications/view-certifications.component";
import { NewCertificationComponent } from "./certifications/new-certification/new-certification.component";
import { NewCertificateComponent } from "./certificates/new-certificate/new-certificate.component";
import { CandidatesComponent } from "./candidates/candidates.component";
import { ViewCandidatesComponent } from "./candidates/view-candidates/view-candidates.component";
import { NewCandidateComponent } from "./candidates/new-candidate/new-candidate.component";
import { GalleryComponent } from "./candidates/gallery/gallery.component";
import { UsersComponent } from "./users/users.component";
import { NewUserComponent } from "./users/new-user/new-user.component";
import { ViewUsersComponent } from "./users/view-users/view-users.component";
import { UserCategoriesComponent } from "./user-categories/user-categories.component";
import { ViewUserCategoriesComponent } from "./user-categories/view-user-categories/view-user-categories.component";
import { NewUserCategoryComponent } from "./user-categories/new-user-category/new-user-category.component";
import { CertificatesStockComponent } from "./certificates-stock/certificates-stock.component";
import { DemagedCertificateStockComponent } from "./certificates-stock/demaged-certificate-stock/demaged-certificate-stock.component";
import { CertificateStockHistoryComponent } from "./certificates-stock/certificate-stock-history/certificate-stock-history.component";
import { NfcTagsComponent } from "./nfc-tags/nfc-tags.component";
import { DemagedNfcTagsComponent } from "./nfc-tags/demaged-nfc-tags/demaged-nfc-tags.component";
import { NfcTagsHistoryComponent } from "./nfc-tags/nfc-tags-history/nfc-tags-history.component";
import { DesignerComponent } from "./layouts/designer/designer.component";
import { ViewStockComponent } from "./certificates-stock/view-stock/view-stock.component";
import { ViewNfcTagsComponent } from "./nfc-tags/view-nfc-tags/view-nfc-tags.component";
import { PrintableCertificatesComponent } from "./certificates/printable-certificates/printable-certificates.component";
import { MediaGalleryComponent } from "./media-gallery/media-gallery.component";
import { ImageSelectorComponent } from "./image-selector/image-selector.component";
import { ViewDesignsComponent } from "./layouts/view-designs/view-designs.component";
import { NewDesignComponent } from "./layouts/new-design/new-design.component";
import { LayoutsComponent } from "./layouts/layouts.component";
import { PrintedCertificatesComponent } from "./certificates/printed-certificates/printed-certificates.component";
import { VoidedCertificatesComponent } from "./certificates/voided-certificates/voided-certificates.component";
import { WaitingForApprovalComponent } from "./certificates/waiting-for-approval/waiting-for-approval.component";
import { PendingApprovalsByOtherComponent } from "./certificates/pending-approvals-by-other/pending-approvals-by-other.component";
import { CertificationCandidatesComponent } from "./certifications/certification-candidates/certification-candidates.component";
import { VerificationModalComponent } from "./shared/verification-modal/verification-modal.component";
import { NewNfcTagsComponent } from "./nfc-tags/new-nfc-tags/new-nfc-tags.component";
import { NewCertificateStockComponent } from "./certificates-stock/new-certificate-stock/new-certificate-stock.component";
import { VerifyCertificateComponent } from "./certificates/verify-certificate/verify-certificate.component";
import { InputModalComponent } from "./shared/input-modal/input-modal.component";
import { PrintPreviewComponent } from "./print-preview/print-preview.component";
import { CertificationStaffComponent } from "./certifications/certification-staff/certification-staff.component";
import { SafeHtmlPipe } from "./services/safe-html.pipe";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { DemoComponent } from "./demo/demo.component";
import { DemoCertificateVerifyComponent } from "./demo-certificate-verify/demo-certificate-verify.component";
import { AssignNfcComponent } from "./certificates/assign-nfc/assign-nfc.component";
import { VerifyCertificateByNFCComponent } from "./verify-certificate-by-nfc/verify-certificate-by-nfc.component";
import { ScanReportComponent } from "./certificates/scan-report/scan-report.component";
import { TWOFASettingsComponent } from "./settings/twofa-settings/twofa-settings.component";
import { TwoFaStepComponent } from "./two-fa-step/two-fa-step.component";
import { DemoUserWithPrintPreviewComponent } from "./demo-user-with-print-preview/demo-user-with-print-preview.component";
import { BlockchainCertificatePdfComponent } from "./certificates/printed-certificates/blockchain-certificate-pdf/blockchain-certificate-pdf.component";
import { BlockchainGuard } from "./services/blockchain-guard.service";
import { AddNewDocumentComponent } from "./blockchain/add-new-document/add-new-document.component";
import { ManageDocumentsComponent } from "./blockchain/manage-documents/manage-documents.component";
import { BlockchainDashboardComponent } from "./blockchain/blockchain-dashboard/blockchain-dashboard.component";
import { ChartsModule } from "ng2-charts";
import { BlockchainComponent } from "./blockchain/blockchain/blockchain.component";
import { NgxLoadingModule } from "ngx-loading";
import { NgxPaginationModule } from "ngx-pagination";
// import { BlockchainVerifyCertificateComponent } from "./blockchain/blockchain-verify-certificate/blockchain-verify-certificate.component";
import { AddMultipleDocumentComponent } from "./blockchain/add-multiple-document/add-multiple-document.component";
import { BlockchainAddressComponent } from "./settings/blockchain-address/blockchain-address.component";
import { CertificateMobileViewComponent } from "./certificates/certificate-mobile-view/certificate-mobile-view.component";
import { MobileVerifyCertificateComponent } from "./blockchain/mobile-verify-certificate/mobile-verify-certificate.component";
import { StytchComponent } from "./signin/stytch/stytch.component";
import { SignupComponent } from "./signup/signup.component";
import { NgxStripeModule } from "ngx-stripe";
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from "ngx-ui-loader";

const settingsRoutes: Routes = [
  { path: "", component: InstituteSettingsComponent },
  {
    path: "institute",
    component: InstituteSettingsComponent,
    // canActivate: [BlockchainGuard],
  },
  { path: "security", component: SecuritySettingsComponent },
  { path: "2FA", component: TWOFASettingsComponent },
  { path: "blockchain-address", component: BlockchainAddressComponent },
];
const adminProfileRoutes: Routes = [
  { path: "", component: ActivityLogComponent },
  {
    path: "activity-log",
    component: ActivityLogComponent,
    canActivate: [BlockchainGuard],
  },
  { path: "profile", component: AdminSettingsComponent },
  { path: "change-password", component: ChangePasswordComponent },
  {
    path: "yubikey",
    component: YubikeySetingsComponent,
    canActivate: [BlockchainGuard],
  },
];
const certificationsRoutes: Routes = [
  { path: "", component: ViewCertificationsComponent },
  { path: "view", component: ViewCertificationsComponent },
  { path: "new", component: NewCertificationComponent },
  { path: ":id/update/:showsuccess", component: NewCertificationComponent },
  { path: ":id/update", component: NewCertificationComponent },
  { path: ":id/candidates", component: CertificationCandidatesComponent },
  { path: ":id/staff", component: CertificationStaffComponent },
];
const certificatesRoutes: Routes = [
  { path: "", component: WaitingForApprovalComponent },
  { path: "new", component: NewCertificateComponent },
  { path: "waiting-for-approval", component: WaitingForApprovalComponent },
  {
    path: "pending-approval-by-other",
    component: PendingApprovalsByOtherComponent,
  },
  { path: "printable", component: PrintableCertificatesComponent },
  { path: "printed", component: PrintedCertificatesComponent },
  { path: "voided", component: VoidedCertificatesComponent },
  { path: ":id/assign-nfc", component: AssignNfcComponent },
  { path: ":id/scan-report", component: ScanReportComponent },
];
const certificatesStockRoutes: Routes = [
  { path: "", component: ViewStockComponent },
  { path: "new", component: NewCertificateStockComponent },
  { path: "view", component: ViewStockComponent },
  { path: "history", component: CertificateStockHistoryComponent },
  { path: "damaged", component: DemagedCertificateStockComponent },
];
const nfcTagsRoutes: Routes = [
  { path: "", component: ViewNfcTagsComponent },
  { path: "new", component: NewNfcTagsComponent },
  { path: "view", component: ViewNfcTagsComponent },
  { path: "history", component: NfcTagsHistoryComponent },
  { path: "damaged", component: DemagedNfcTagsComponent },
];
const candidatesRoutes: Routes = [
  { path: "", component: ViewCandidatesComponent },
  { path: "view", component: ViewCandidatesComponent },
  { path: "new", component: NewCandidateComponent },
  { path: ":courseId/new", component: NewCandidateComponent },
  { path: ":courseId/edit/:id", component: NewCandidateComponent },
  { path: ":id/certificate/:candidateId", component: NewCertificateComponent },
  { path: "gallery", component: GalleryComponent },
];

const userCategoriesRoutes: Routes = [
  { path: "", component: ViewUserCategoriesComponent },
  { path: "view", component: ViewUserCategoriesComponent },
  { path: "new", component: NewUserCategoryComponent },
  { path: ":id/update", component: NewUserCategoryComponent },
];

const blockchainRoutes: Routes = [
  { path: "", component: BlockchainDashboardComponent },
  { path: "dashboard", component: BlockchainDashboardComponent },
  { path: "add", component: AddNewDocumentComponent },
  { path: "add-multiple", component: AddMultipleDocumentComponent },
  { path: "manage", component: ManageDocumentsComponent },
];

const usersRoutes: Routes = [
  { path: "", component: ViewUsersComponent },
  { path: "view", component: ViewUsersComponent },
  { path: "new", component: NewUserComponent },
  { path: "profile/:id/:tab", component: UserProfileComponent },
];

const designerRoutes: Routes = [
  { path: "", component: ViewDesignsComponent },
  { path: "view", component: ViewDesignsComponent },
  { path: "new", component: NewDesignComponent },
  { path: ":id/update", component: NewDesignComponent },
  { path: "designer/:id", component: DesignerComponent },
];

const homeRoutes: Routes = [
  { path: "", redirectTo: "dashboard", pathMatch: "full" },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [BlockchainGuard],
  },
  {
    path: "layouts",
    component: LayoutsComponent,
    children: designerRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "blockchain",
    component: BlockchainComponent,
    children: blockchainRoutes,
  },
  {
    path: "users",
    component: UsersComponent,
    children: usersRoutes,
    // canActivate: [BlockchainGuard],
  },
  {
    path: "user-categories",
    component: UserCategoriesComponent,
    children: userCategoriesRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "certificates",
    component: CertificatesComponent,
    children: certificatesRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "candidates",
    component: CandidatesComponent,
    children: candidatesRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "courses",
    component: CertificationsComponent,
    children: certificationsRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "admin",
    component: AdminProfileComponent,
    children: adminProfileRoutes,
    // canActivate: [BlockchainGuard],
  },
  {
    path: "settings",
    component: SettingsComponent,
    children: settingsRoutes,
    // canActivate: [BlockchainGuard],
  },
  {
    path: "certificate-stock",
    component: NfcTagsComponent,
    children: certificatesStockRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "nfc-tags",
    component: NfcTagsComponent,
    children: nfcTagsRoutes,
    canActivate: [BlockchainGuard],
  },
  {
    path: "media-gallery",
    component: MediaGalleryComponent,
    canActivate: [BlockchainGuard],
  },
  {
    path: "2FA-step",
    component: TwoFaStepComponent,
    canActivate: [BlockchainGuard],
  },
];

const routes: Routes = [
  { path: "", redirectTo: "signin", pathMatch: "full" },
  { path: "signin", component: SignInComponent },
  { path: "authenticate", component: StytchComponent },
  { path: "signup", component: SignupComponent },
  { path: "reset-password/:token", component: ResetPasswordComponent },
  { path: "staff-signin", component: SignInStaffComponent },
  {
    path: "home",
    component: HomeComponent,
    children: homeRoutes,
    // canActivate: [BlockchainGuard],
  },
  {
    path: "verify-certificate/:id",
    component: VerifyCertificateComponent,
    // canActivate: [BlockchainGuard],
  },
  {
    path: "demo-certificate",
    component: PrintPreviewComponent,
    canActivate: [BlockchainGuard],
  },
  {
    path: "demo-user-with-print-preview",
    component: DemoUserWithPrintPreviewComponent,
    canActivate: [BlockchainGuard],
  },
  {
    path: "demo-test",
    component: DemoComponent,
    canActivate: [BlockchainGuard],
  },

  {
    path: "demo-certificate-verification/:id",
    component: DemoCertificateVerifyComponent,
    canActivate: [BlockchainGuard],
  },
  {
    path: "nfc-certificate-verification/:token",
    component: VerifyCertificateByNFCComponent,
    canActivate: [BlockchainGuard],
  },
  // { path: "pdf", component: BlockchainCertificatePdfComponent },

  // {
  //   path: "verify-certificate",
  //   component: BlockchainVerifyCertificateComponent,
  // },
  {
    path: "mobile-verify-certificate",
    component: MobileVerifyCertificateComponent,
  },
  {
    path: "certificate-preview",
    component: CertificateMobileViewComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideBarComponent,
    SignInComponent,
    DashboardComponent,
    BaseComponent,
    HomeComponent,
    SignInStaffComponent,
    SettingsComponent,
    ChangePasswordComponent,
    ActivityLogComponent,
    CertificatesComponent,
    AddCertificatesFieldsComponent,
    CertificatesAccessRightsComponent,
    UserProfileComponent,
    UserSettingsComponent,
    UserTimelineComponent,
    AdminProfileComponent,
    PersonalSettingsComponent,
    InstituteSettingsComponent,
    SecuritySettingsComponent,
    AdminSettingsComponent,
    CertificationsComponent,
    ViewCertificationsComponent,
    NewCertificationComponent,
    NewCertificateComponent,
    CandidatesComponent,
    ViewCandidatesComponent,
    NewCandidateComponent,
    UsersComponent,
    NewUserComponent,
    ViewUsersComponent,
    UserCategoriesComponent,
    ViewUserCategoriesComponent,
    NewUserCategoryComponent,
    DemagedCertificateStockComponent,
    CertificateStockHistoryComponent,
    DemagedNfcTagsComponent,
    NfcTagsHistoryComponent,
    NfcTagsComponent,
    CertificatesStockComponent,
    DesignerComponent,
    ViewStockComponent,
    ViewNfcTagsComponent,
    GalleryComponent,
    PrintableCertificatesComponent,
    YubikeySetingsComponent,
    MediaGalleryComponent,
    ImageSelectorComponent,
    ViewDesignsComponent,
    NewDesignComponent,
    LayoutsComponent,
    PrintedCertificatesComponent,
    VoidedCertificatesComponent,
    WaitingForApprovalComponent,
    PendingApprovalsByOtherComponent,
    CertificationCandidatesComponent,
    VerificationModalComponent,
    NewNfcTagsComponent,
    NewCertificateStockComponent,
    VerifyCertificateComponent,
    InputModalComponent,
    PrintPreviewComponent,
    CertificationStaffComponent,
    SafeHtmlPipe,
    ResetPasswordComponent,
    DemoComponent,
    DemoCertificateVerifyComponent,
    AssignNfcComponent,
    ScanReportComponent,
    VerifyCertificateByNFCComponent,
    TWOFASettingsComponent,
    TwoFaStepComponent,
    DemoUserWithPrintPreviewComponent,
    BlockchainCertificatePdfComponent,
    AddNewDocumentComponent,
    ManageDocumentsComponent,
    BlockchainDashboardComponent,
    BlockchainComponent,
    // BlockchainVerifyCertificateComponent,
    AddMultipleDocumentComponent,
    BlockchainAddressComponent,
    CertificateMobileViewComponent,
    MobileVerifyCertificateComponent,
    StytchComponent,
    SignupComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ColorChromeModule,
    RouterModule.forRoot(routes),
    ChartsModule,
    NgxLoadingModule.forRoot({}),
    NgxPaginationModule,
    ModalModule.forRoot(),
    NgxStripeModule.forRoot("pk_test_HBrBHOkjJxdaIeCW82zIcVS900OFSOyubx"),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
  ],
  providers: [
    DatePipe,
    SharedHttpService,
    TransporterService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6Ld66Q0jAAAAANNLjLl7fm3nmUeOLD2gtFcKkvcT", //live key
        // siteKey: "6Lf9SfUpAAAAAC7gXhtzFWo9TCT0kWErPZaUinBR"
      } as RecaptchaSettings,
    },
    BsModalRef,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
