import web3 from "./web3";
import Verify from "../build/contracts/Verify.json";
// import { environment } from "../../../../environments/environment";
console.log(Verify.abi);
const verify = new web3.eth.Contract(
  Verify.abi,

  "0xE1c63cB3D7DAAe0B6237CF08FC18832BC9FE270D" //mainnet
  // "0x7ea41757c5a858C458d3C317A05040c558F1d7b4"
);

export default verify;
