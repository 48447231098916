import {Person} from './Person';
import {Certification} from './Certification';

export class StaffCertification {
    id: string;
    staff: Person;
    certification: Certification;

    constructor(private instituteId: string, private staffId: string, private certificationId: string) {


    }




}
