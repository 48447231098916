import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {ExcelService} from '../../services/excel.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {BluePrint} from '../../../Models/BluePrint';
import {TransporterService} from '../../services/transporter.service';

@Component({
  selector: 'app-view-designs',
  templateUrl: './view-designs.component.html',
  styles: []
})
export class ViewDesignsComponent extends BaseComponent implements OnInit {

  items: any;
  itemsOnServer: any;
  noData = false;
  isArchived = false;
  successAlert = false;
  errorAlert = false;
  alertMsg = '';


  constructor(private httpService: SharedHttpService, private excelService: ExcelService,
              private transporterService: TransporterService, private router: Router) {
    super(router);
  }

  async ngOnInit() {
    const input = document.querySelectorAll('input');
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
    }
    await this.getItems(this.isArchived);
  }

  async getItems(e: any) {
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.BluePrints,
      '', Attributes.None, {isArchived: e});
    if (res.success) {
      // console.log('data', res);
      this.items = this.itemsOnServer = res.data;
      if (this.items === null || this.items.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else {
      console.log('err', res);
    }
  }


  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(x =>
        x.title && x.title.toLowerCase().includes(e.toLowerCase()) ||
        x.size && x.size.toLowerCase().includes(e.toLowerCase()) ||
        x.orientation && x.orientation.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.items = this.itemsOnServer;
    }
  }

  async selectFilter(e: boolean) {
    // console.log('e', e);
    await this.getItems(e);
    this.isArchived = e;
  }

  async archiveUnarchiveDesign(id: any, isArchive) {
    const r = confirm('Are You Sure?');
    if (r == true) {
      const res = await this.httpService.Patch<BluePrint>(Tables.BluePrints, {isArchived: isArchive}, id);
      // console.log(res);
      if (res.success) {
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
        }, 3000);
        if (this.isArchived) {
          this.alertMsg = 'Design is Archived successfully.';
        } else {
          this.alertMsg = 'Design is Unarchived successfully.';
        }
        await this.getItems(!this.isArchived);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    }

  }

}
