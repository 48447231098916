import {Component, OnInit} from '@angular/core';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styles: []
})
export class GalleryComponent extends BaseComponent implements OnInit {
  noData= false;
  fileToUpload: FileList;
  items: any;
  itemsOnServer: any;
  apiBaseUrl;

  constructor(private httpService:SharedHttpService, private router: Router) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  ngOnInit() {
    this.getItems();
  }

  async fileHandler(files: FileList) {
    this.fileToUpload = files;
    // console.log('files', files);
    // console.log('files item', files.item(0));
      const res = await this.httpService.PostPicture(this.fileToUpload, 'candidates');
    // console.log('res', res);
    if(res){
      this.getItems();
    }
  }


  async getItems() {
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Candidates);
    if (res.success) {
      this.items = this.itemsOnServer = res.data;

      // console.log('candidates', this.items);

      if (this.items === null || this.items.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else {
      console.log('err', res);
    }
  }



  searchImage(val){
    setTimeout(() => {
      if (val === '') {
        this.items = this.itemsOnServer;
      } else {
        this.items = this.itemsOnServer.filter(x => x.rollNumber.includes(val));
      }
    }, 500);

  }

}
