import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';
import {FormService} from '../../services/formservice.service';
import {Institute} from '../../../Models/Institute';
import {Subscription} from 'rxjs/Subscription';
import {TransporterService} from '../../services/transporter.service';
import * as $ from 'jquery';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-institute-settings',
    templateUrl: './institute-settings.component.html',
    styles: []
})
export class InstituteSettingsComponent extends BaseComponent implements OnInit {

    public form: FormGroup;
    imageSelector = false;
    successAlert = false;
    errorAlert = false;
    alertMsg: any;
    imageFile: any;
    signatureImageFile: any;
    sealImageFile: any;
    apiBaseUrl: any;
    imageSelectorCaller;
    private subscription: Subscription;

    constructor(private fb: FormBuilder, private httpService: SharedHttpService, private router: Router,
                private transporterService: TransporterService, private formService: FormService) {
        super(router);
        this.apiBaseUrl = environment.apiBaseUrl;
    }

    ngOnInit() {
        this.form = this.formService.Get(this.user.institute);
        
        this.subscription = this.transporterService.receive().subscribe(parcel => {
            if (parcel.type === 'image-selector') {
                if (parcel.data === 'image-selected') {
                    if (parcel.receiver === 'instituteLogo') {
                        this.imageFile = parcel.file;
                        // console.log('selected image', this.imageFile);
                        $('.image-selector-ph').attr('src', this.apiBaseUrl + '/Attachments/' + this.imageFile.container + '/download/' + this.imageFile.name);
                        this.form.patchValue({
                            logo: this.imageFile.name
                        });
                    }
                    if(parcel.receiver === 'instituteSignature'){
                        console.log("instituteSignature")
                        this.signatureImageFile = parcel.file;
                        // console.log('selected image', this.imageFile);
                        $('.signature-image-selector-ph').attr('src', this.apiBaseUrl + '/Attachments/' + this.signatureImageFile.container + '/download/' + this.signatureImageFile.name);
                        this.form.patchValue({
                            signature: this.signatureImageFile.name
                        });
                    }
                    if(parcel.receiver === 'instituteSeal'){
                        console.log("instituteSeal")
                        this.sealImageFile = parcel.file;
                        // console.log('selected image', this.imageFile);
                        $('.seal-image-selector-ph').attr('src', this.apiBaseUrl + '/Attachments/' + this.sealImageFile.container + '/download/' + this.sealImageFile.name);
                        this.form.patchValue({
                            seal: this.sealImageFile.name
                        });
                    }

                    this.imageSelector = false;
                }
                if (parcel.data === 'close') {
                    this.imageSelector = false;
                }
            }
        });

        
        
    }

    async submit() {
        // console.log('val', this.form.value);
        const res = await this.httpService.Patch<Institute>
        (Tables.Institutes, this.form.value, this.user.institute.id);
        // console.log('res', res);
        if (res.success) {
            this.user.institute = res.data;
            localStorage.setItem('user', JSON.stringify(this.user));
            $('.uploaded-inst-logo').attr('src', this.apiBaseUrl + '/Attachments/' + this.imageFile.container + '/download/' + this.user.institute.logo);
            this.successAlert = true;
            setTimeout(() => {
                this.successAlert = false;
            }, 3000);
        } else {
            this.errorAlert = true;
            setTimeout(() => {
                this.errorAlert = false;
            }, 3000);
            this.alertMsg = res.data.error.error.message;
        }
    }


    imageSelectorTrigger(caller) {
        this.imageSelectorCaller=caller;
        this.imageSelector = true;
    }

    clearForm() {
        this.form.reset();
    }

}
