import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {SharedHttpService} from '../../services/shared-http.service';
import {FormBuilder} from '@angular/forms';
import {FormService} from '../../services/formservice.service';
import {environment} from '../../../environments/environment';
import {Tables} from '../../../Models/Tables';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    apiBaseUrl;

    constructor(private httpService: SharedHttpService, private route: ActivatedRoute, private router: Router) {
        super(router);
        this.apiBaseUrl = environment.apiBaseUrl;
    }


    ngOnInit() {
        $('#userProfile').on('click', function (e: any) {
            e.preventDefault();
            const $this = $(this);
            $this.parent('.nav-item').toggleClass('show');
            $this.parent('.nav-item').find('.user-dd').toggleClass('show');
        });

        $('body').click(function (evt) {
            if ($(evt.target).closest('#userProfile').length)
                return;
            $('#userProfile').parent('.nav-item').removeClass('show');
            $('#userProfile').parent('.nav-item').find('.user-dd').removeClass('show');
            //Do processing of click event here for every element except with id menu_content
        });

        // console.log('user', this.user);
    }


    togglesideBar() {
        $('body').toggleClass('lock-nav');
    }

    async logOut() {
        const res = await this.httpService.Patch(Tables.Staff, {verified2FA: false}, this.user.id);
        if (res.success) {
          localStorage.removeItem('user');
          this.router.navigate(['/signin']);
        }
    }

}
