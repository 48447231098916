import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificates-stock',
  templateUrl: './certificates-stock.component.html',
  styles: []
})
export class CertificatesStockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
