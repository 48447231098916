import {BluePrint} from './BluePrint';

export class Certification {

    validators = {details: null, startDate: null, endDate: null, printingDeadline: null, price: null};
    id: string;
    instituteId: string;
    level: number;
    title: string;
    startDate: Date;
    endDate: Date;
    printingDeadline: Date;
    details: string;
    price: number;
    bluePrintId: string;
    bluePrint: BluePrint;

    constructor(instituteId: string) {

        this.instituteId = instituteId;
        // this.bluePrintId = '';
        this.title = '';
        this.level = 1;
        this.startDate = new Date();
        this.endDate = new Date();
        this.printingDeadline = new Date();
        this.details = '';
        this.price = 0;


    }
}
