import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Tables} from '../../../../Models/Tables';
import {SharedHttpService} from '../../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormService} from '../../../services/formservice.service';
import {Person} from '../../../../Models/Person';
import {UserProfileComponent} from '../user-profile.component';

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styles: []
})
export class UserSettingsComponent extends UserProfileComponent implements OnInit {

    public form: FormGroup;
    UserCategories: any;

    constructor(httpService: SharedHttpService, router: Router, private fb: FormBuilder,
                private formService: FormService, route: ActivatedRoute) {
        super(route, httpService, router);
        // console.log('id', this.route.snapshot.params['id']);
        this.userId = this.route.snapshot.params['id'];
        this.clearForm();
    }

    async ngOnInit() {

        await this.getUsers();

        // console.log('settings profile', this.userProfile);
        this.form = this.formService.Get(this.userProfile);


        this.getUserCategories();
    }

    clearForm() {
        this.form = this.formService.Get(new Person(this.user.institute.id));
    }

    async getUserCategories() {
        const getStaffCategories = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.StaffCategories);
        // console.log(getStaffCategories);
        if (getStaffCategories.success) {
            this.UserCategories = getStaffCategories.data;
        } else {
        }
    }

    async submit() {
        console.log('val', this.form.value);
        const res = await this.httpService.PatchRelation(Tables.Staff, this.form.value, Tables.Institutes,
            this.user.institute.id, this.userProfile.id);
        if (res.success) {
            this.router.navigate(['/home/users/profile', this.userId, 'timeline']);
        }
    }
}

