import {AfterViewChecked, Component, OnDestroy, OnInit} from "@angular/core";
import {FormService} from '../../services/formservice.service';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Tables} from '../../../Models/Tables';
import {BluePrint} from '../../../Models/BluePrint';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import * as $ from 'jquery';
import {environment} from '../../../environments/environment';
import {TransporterService} from '../../services/transporter.service';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-new-design',
  templateUrl: './new-design.component.html',
  styles: []
})
export class NewDesignComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscription: Subscription;
  imageSelector = false;
  imageFile: any;
  requiredWidth: number;
  requiredHeight: number;
  apiBaseUrl: any;
  public form: FormGroup;
  public isA4 = true;
  public isLandscape = true;
  public selectedBG = '';
  editMode = false;
  id: any;
  expectingBG: any;

  constructor(private httpService: SharedHttpService, private route: ActivatedRoute, private router: Router,
              private fb: FormBuilder, private formService: FormService, private transporterService: TransporterService) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }


  toggleImagesModal() {
    this.imageSelector = !this.imageSelector;
  }

  ngAfterViewChecked() {

    const _this2 = this;

    $('.bg-selector button').on('click', function (e) {
      // console.log('sssss');
      e.stopImmediatePropagation();
      const $el = $(this), $p = $el.parent();
      _this2.requiredHeight = +$el.data('height');
      _this2.requiredWidth = +$el.data('width');
      $('.bg-selector .radio-block').removeClass('active');
      if ($el.hasClass('custom-selection')) {
        _this2.expectingBG = $el;
        _this2.imageSelector = true;
      } else if (!$el.hasClass('empty-style')) {
        _this2.selectedBG = $el.find('img').attr('src');
        $p.addClass('active');
      } else {
        $p.addClass('active');
        _this2.selectedBG = '';
      }
      console.log(_this2.selectedBG);
    });
  }

  async ngOnInit() {
   
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.editMode = true;
      const getRequest = await this.httpService.Get(BluePrint, {table: Tables.BluePrints, key: this.id});
      if (getRequest.success) {
        this.form = this.formService.Get(new BluePrint(), getRequest.data);
      }
    } else {
      this.form = this.formService.Get(new BluePrint(this.user.institute.id));
    }
    this.subscription = this.transporterService.receive().subscribe(parcel => {
      if (parcel.type === 'image-selector') {
        if (parcel.data === 'image-selected') {
          this.imageFile = this.apiBaseUrl + '/Attachments/' + parcel.file.container + '/download/' + parcel.file.name;
          this.checkImage();
        }
        if (parcel.data === 'close') {
          this.imageSelector = false;
        }
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async checkImage() {
    const img = await this.getMeta(this.imageFile);
    console.log($($('.bg-selector').length));
    if ($('.bg-selector').length && img['width'] === this.requiredWidth && img['height'] === this.requiredHeight) {
      this.expectingBG.find('img').attr('src', this.imageFile);
      this.expectingBG.parent().addClass('active');
      this.selectedBG = this.imageFile;
      this.imageSelector = false;
    } else {
      alert('Please choose image with correct dimension (i.e. ' + this.requiredWidth + 'x' + this.requiredHeight + ').');
    }
  }


  async getMeta(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  changeOrientation(val) {
    $('.bg-selector .radio-block').removeClass('active');
    this.isLandscape = val === 'landscape';
  }


  clearForm() {
    this.ngOnInit();
    this.isLandscape = true;
  }

  async submit() {


    if (this.form.valid) {
      if (this.id) {
        const res = await this.httpService.Patch<BluePrint>
        (Tables.BluePrints, this.form.value, this.id);
        // console.log(res);
        if (res.success) {
          this.router.navigate(['/home/layouts/view']);
        }
      } else {
        this.form.value.template = [];
        let bgImg = '';
        // if (this.selectedBG.length > 5) {
          bgImg = '<div class="pageBg" style="background-image:url('+this.selectedBG+');height:100%"></div>';
        // }

        this.form.value.template.push('<div id="the-certificate" class="the-certificate size-' + this.form.value.size + '-' + this.form.value.orientation + ' ppi_150"> ' + bgImg + '</div>');
        // console.log(this.form.value)
        
        const res = await this.httpService.PostRelation<BluePrint>
        (Tables.BluePrints, this.form.value, Tables.Institutes, this.user.institute.id);
        // console.log(res);
        if (res.success) {
          this.router.navigate(['/home/layouts/designer', res.data.id]);
        }
      }
    } else {
      console.log('invalid form', this.form.value);
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }
  }

}
