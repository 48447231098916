import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Paper} from '../../../Models/Paper';
import {ExcelService} from '../../services/excel.service';

@Component({
  selector: 'app-view-nfc-tags',
  templateUrl: './view-nfc-tags.component.html',
  styles: []
})
export class ViewNfcTagsComponent extends BaseComponent implements OnInit {
  stock;
  items: any;
  itemsOnServer: any;
  noData = false;

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router) {
    super(router);
  }

  async ngOnInit() {
/*    const input = document.querySelectorAll('input');
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
    }*/
    await this.getNFCTags();
  }

  async getNFCTags() {
    const where = { isDamaged: false};
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.NFCTags,'',[Attributes.certificate],where);
    if (res.success) {
      // console.log('data stock', res);
      this.items = this.itemsOnServer = res.data;
      // console.log('items', this.items);
    } else {
      console.log('err', res);
    }
  }

  async importExcelData(evt) {
    const page = this;
    const excelData = this.excelService.importExcelData(evt, async excelData => {
      // console.log('res2', excelData);
      if (!excelData) {
        // console.log('return');
        return;
      }
      const res = await page.httpService.PostRelation<Paper>
      (Tables.NFCTags, excelData, Tables.Institutes, this.user.institute.id);
      // console.log('res', res);
      if (res.success) {
        // console.log('excel import', res);
        await page.getNFCTags();
      }
    });
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(x => x.identifier && x.identifier.includes(e) || x.batchId && x.batchId.includes(e));
    } else {
      this.items = this.itemsOnServer;
    }
  }

  async markAsDamaged(id) {
    const r = confirm('Are You Sure?');
    if (r == true) {
      const data = {
        isDamaged: true
      };
      const res = await this.httpService.Patch(Tables.NFCTags, data, id);
      if (res.success) {
        await this.getNFCTags();
      }
    }
  }
}
