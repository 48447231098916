import { Injectable } from "@angular/core";
import { SharedHttpService } from "./shared-http.service";
import { Person } from "../../Models/Person";
import { Tables, Attributes } from "../../Models/Tables";

@Injectable({
  providedIn: "root",
})
export class GetUserDataService {
  constructor(private httpService: SharedHttpService) {}

  user: any;

  async getUserDataFromLogin(id) {
    localStorage.setItem("userId", JSON.stringify(id));
    this.user = await this.httpService.Get<Person>(
      Person,
      {
        table: Tables.Staff,
        key: id,
      },
      [Attributes.institute, Attributes.staffCategory]
    );
    return this.user;
  }

  async returnUserData() {
    if (!this.user) {
      const id = JSON.parse(localStorage.getItem("userId"));
      this.user = await this.httpService.Get<Person>(
        Person,
        {
          table: Tables.Staff,
          key: id,
        },
        [Attributes.institute, Attributes.staffCategory]
      );
    }
    return this.user.data;
  }
}
