import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/observable/throw";
import { environment } from "../../environments/environment";
import { UserAuthenticationModel } from "../../Models/UserAuthenticationModel";
import { AuthenticationResult } from "../../Models/AuthenticationResult";
import { Attributes, Tables } from "../../Models/Tables";
import { Certificate } from "../../Models/Certificate";
import { Person } from "../../Models/Person";
import { Candidate } from "../../Models/Candidate";
import { FormService } from "./formservice.service";
import { Email } from "../../Models/Email";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterUser } from "src/Models/RegisterUser";
import { InstituteCreate } from "src/Models/InstituteCreate";
// import { BaseComponent } from '../BaseComponent/BaseComponent';

@Injectable()
export class SharedHttpService {
  private apiBaseUrl;
  private webBaseUrl;
  private User;
  private blockchainBaseUrl;

  constructor(
    private httpClient: HttpClient,
    private formService: FormService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.apiBaseUrl = environment.apiBaseUrl;
    this.webBaseUrl = environment.webBaseUrl;
    this.blockchainBaseUrl = environment.blockchainBaseUrl;
    this.User = JSON.parse(localStorage.getItem("user"));
  }

  async GetPendingApprovalsByOther() {
    /*   try {

           let queryString = '';
           queryString += '/getwaitingformyapproval';
           queryString += '?access_token=' + this.getToken();
           queryString = this.apiBaseUrl + queryString;
           console.log(queryString);

           const data = await this.httpClient.get(queryString).toPromise();
           console.log(data);
           return {success: true, data: data};
       } catch (e) {
           return {success: false, data: e};
       }*/

    return await this.GetUrl("/pendingapprovalbyothers", "");
  }

  async GetCertificatesWaitingForMyApproval() {
    /*   try {

           let queryString = '';
           queryString += '/getwaitingformyapproval';
           queryString += '?access_token=' + this.getToken();
           queryString = this.apiBaseUrl + queryString;
           console.log(queryString);

           const data = await this.httpClient.get(queryString).toPromise();
           console.log(data);
           return {success: true, data: data};
       } catch (e) {
           return {success: false, data: e};
       }*/

    return await this.GetUrl("/getwaitingformyapproval", "");
  }

  async GetPendingCertificateApprovalsByOther(certificationId, certificateId) {
    return await this.GetUrl(
      "/pending-certificate/pendingapprovalbyothers/" +
        certificationId +
        "/" +
        certificateId,
      ""
    );
  }

  async GetCertificatesApprovedByMe() {
    /*        try {

                let queryString = '';
                queryString += '/getapprovedbyme';
                queryString += '?access_token=' + this.getToken();
                queryString = this.apiBaseUrl + queryString;
                console.log(queryString);

                const data = await this.httpClient.get(queryString).toPromise();
                console.log(data);
                return {success: true, data: data};
            } catch (e) {
                return {success: false, data: e};
            }*/

    return await this.GetUrl("/getapprovedbyme", "");
  }

  async verifyCertificate(identifier: string, pin: string) {
    try {
      const result = await this.httpClient
        .post(this.apiBaseUrl + "/certificates/verify", {
          identifier: identifier,
          pin: pin,
        })
        .toPromise();

      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async verifyDemoCertificate(identifier: string, pin: string) {
    try {
      const result = await this.httpClient
        .post(this.apiBaseUrl + "/DemoCertificates/verify", {
          identifier: identifier,
          pin: pin,
        })
        .toPromise();

      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async verifyCertificateByNFC(token: string) {
    try {
      const result = await this.httpClient
        .post(this.apiBaseUrl + "/NFCTagVerificationTokens/verify", {
          token: token,
        })
        .toPromise();

      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GetUrl(url, params) {
    try {
      let queryString = "";
      let paramsString = "";
      queryString += url;
      paramsString += params;
      if (params === "") {
        paramsString += "?access_token=" + this.getToken();
      } else {
        paramsString += "&access_token=" + this.getToken();
      }
      queryString = this.apiBaseUrl + queryString + paramsString;
      // console.log(queryString);

      const data = await this.httpClient.get(queryString).toPromise();
      // console.log(data);
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  Cast(rawObj, obj) {
    for (const i in rawObj) {
      obj[i] = rawObj[i];
    }
    return obj;
  }

  async AddCandidatesToCertification(
    candidates: Array<Candidate>,
    certification: string
  ) {
    try {
      // console.log(typeof certification);
      const datatopost = {
        certification: certification,
        staffId: this.User.id,
        instituteId: this.User.instituteId,
        candidates: candidates,
      };

      const result = await this.httpClient
        .post<Array<Candidate>>(
          this.apiBaseUrl + "/certifications/add-candidates",
          datatopost
        )
        .toPromise();

      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GetFromTable<T>(
    table: Tables,
    include: Array<Attributes> | Attributes = Attributes.None,
    where: any = null
  ) {
    try {
      let queryString = "";

      queryString += "/" + Tables[table];

      if (!(include instanceof Array)) {
        include = [include];
      }
      if (include[0] !== Attributes.None || where !== null) {
        queryString += "?filter=";
        if (include[0] !== Attributes.None) {
          queryString += '{"include":[';
          include.forEach((inc) => {
            queryString += '"' + Attributes[inc] + '"' + ",";
          });
          queryString = queryString.substring(0, queryString.length - 1);
          queryString += "]";
        }

        // apart from this, each querystring line must contain +=

        if (where !== null) {
          if (include[0] !== Attributes.None) {
            queryString += ',"where":';
          } else {
            queryString += '{"where":';
          }
          queryString += JSON.stringify(where);
        }
        queryString += "}"; // where ends here
      }
      queryString = this.apiBaseUrl + queryString;
      // console.log(queryString);

      const data = await this.httpClient.get<T>(queryString).toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async Get<T>(
    T,
    query:
      | Array<{ table: Tables; key: string }>
      | { table: Tables; key: string },
    include: Array<Attributes> | Attributes = Attributes.None,
    where: any = null
  ) {
    try {
      let queryString = "";
      if (!(query instanceof Array)) {
        query = [query];
      }
      query.forEach((queryParam) => {
        queryString += "/" + Tables[queryParam.table] + "/" + queryParam.key;
      });
      if (!(include instanceof Array)) {
        include = [include];
      }
      if (include[0] !== Attributes.None || where !== null) {
        queryString += '?filter={"include":[';
        include.forEach((inc) => {
          queryString += '"' + Attributes[inc] + '"' + ",";
        });
        // apart from this, each querystring line must contain +=
        queryString = queryString.substring(0, queryString.length - 1);
        queryString += "]";
        if (where !== null) {
          if (include[0] !== Attributes.None) {
            queryString += ',"where":';
          } else {
            queryString += '"where":';
          }
          queryString += JSON.stringify(where);
        }
        queryString += "}"; // where ends here
        queryString += "&access_token=" + this.getToken();
      } else {
        queryString += "?access_token=" + this.getToken();
      }
      queryString = this.apiBaseUrl + queryString;
      // console.log(queryString);

      const data = await this.httpClient.get<T>(queryString).toPromise();
      // let factory = new Factory();
      //
      // // let res = Object.assign(, data);
      // // angular.extend(factory.create(T), angular.fromJson(data));
      // let mType = factory.create(T);
      //
      // Object.keys(data).forEach(key => {
      //
      //
      //     mType[key.toString()] = data[key];
      //
      // });
      return { success: true, data: data };
    } catch (e) {
      console.log(e);
      return { success: false, data: e };
    }
  }

  async GetRelated<T>(
    baseTable: Tables,
    baseTableId: string,
    table: Tables,
    tableId: string = "",
    include: Array<Attributes> | Attributes = Attributes.None,
    where = null
  ) {
    try {
      let queryString = "";
      queryString +=
        "/" + Tables[baseTable] + "/" + baseTableId + "/" + Tables[table];

      if (tableId !== "") {
        queryString += "/" + tableId;
      }
      if (!(include instanceof Array)) {
        include = [include];
      }
      if (include[0] !== Attributes.None || where !== null) {
        queryString += "?filter={";
        if (include[0] !== Attributes.None) {
          queryString += '"include":[';
          include.forEach((inc) => {
            queryString += '"' + Attributes[inc] + '"' + ",";
          });
          // apart from this, each querystring line must contain +=
          queryString = queryString.substring(0, queryString.length - 1);
          queryString += "]";
        }
        // console.log(queryString);
        if (where !== null) {
          if (include[0] !== Attributes.None) {
            queryString += ',"where":';
          } else {
            queryString += '"where":';
          }
          queryString += JSON.stringify(where);
          queryString += "}"; // where ends here
        } else {
          queryString += "}";
        }
        queryString += "&access_token=" + this.getToken();
      } else {
        queryString += "?access_token=" + this.getToken();
      }
      queryString = this.apiBaseUrl + queryString;

      // console.log(queryString);
      const data = await this.httpClient.get(queryString).toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GetRelatedComplexQuery<T>(
    baseTable: Tables,
    baseTableId: string,
    table: Tables,
    tableId: string = "",
    include: Array<Attributes> | Attributes = Attributes.None,
    filter = null
  ) {
    try {
      let queryString = "";
      queryString +=
        "/" + Tables[baseTable] + "/" + baseTableId + "/" + Tables[table];

      if (tableId !== "") {
        queryString += "/" + tableId;
      }
      if (!(include instanceof Array)) {
        include = [include];
      }
      if (filter !== null) {
        queryString += "?filter=" + JSON.stringify(filter);
        queryString += "&access_token=" + this.getToken();
      } else {
        queryString += "?access_token=" + this.getToken();
      }
      queryString = this.apiBaseUrl + queryString;

      // console.log(queryString);
      const data = await this.httpClient.get(queryString).toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GetExternal(url) {
    try {
      const data = await this.httpClient.get(url).toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GetRelatedWithWhere<T>(
    baseTable: Tables,
    baseTableId: string,
    table: Tables,
    include: Array<Attributes> | Attributes = Attributes.None,
    where: null
  ) {
    try {
      const tableId = "";
      let queryString = "";
      queryString +=
        "/" + Tables[baseTable] + "/" + baseTableId + "/" + Tables[table];

      if (tableId !== "") {
        queryString += "/" + tableId;
      }
      if (!(include instanceof Array)) {
        include = [include];
      }
      if (include[0] !== Attributes.None || where !== null) {
        queryString = '?filter={"include":[';
        include.forEach((inc) => {
          queryString += '"' + inc + '"' + ",";
        });
        queryString = queryString.substring(queryString.length - 1);
        queryString += "]";
        if (where !== null) {
          queryString += ',"where:"';
          queryString += JSON.stringify(where);
          queryString += "}";
        }
        queryString += "}&access_token=" + this.getToken();
      } else {
        queryString += "?access_token=" + this.getToken();
      }
      queryString = this.apiBaseUrl + queryString;

      // console.log(queryString);
      const data = await this.httpClient.get(queryString).toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async Authenticate(credentials) {
    try {
      const data = await this.httpClient
        .post<AuthenticationResult>(
          this.apiBaseUrl + "/staff/login",
          credentials
        )
        .toPromise();

      data["token"] = data.id;
      localStorage.setItem("user", JSON.stringify(data));
      // console.log(data);
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async sendMagicLink(credentials: UserAuthenticationModel) {
    try {
      const data = await this.httpClient
        .post(this.apiBaseUrl + "/login-magic-link", credentials)
        .toPromise();
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async RecoverPassword(email: string) {
    try {
      const data = await this.httpClient
        .post(this.apiBaseUrl + "/staff/reset", {
          email: email,
          webBaseUrl: this.webBaseUrl,
        })
        .toPromise();
      // console.log(data);
      return { success: true, data: data };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async ResetPassword<T>(data: any) {
    try {
      const url =
        this.apiBaseUrl +
        "/Staff/reset-password" +
        +"?access_token=" +
        this.getToken();
      // console.log('url', url);
      const result = await this.httpClient.post<T>(url, data).toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async PostRelation<T>(
    table: Tables,
    data: any,
    relateWithTable: Tables,
    relateWithTableId: string
  ) {
    try {
      const url =
        this.apiBaseUrl +
        "/" +
        Tables[relateWithTable] +
        "/" +
        relateWithTableId +
        "/" +
        Tables[table] +
        "?access_token=" +
        this.getToken();
      console.log("url", url);
      const result = await this.httpClient.post<T>(url, data).toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async approveCertificate(certificate: Certificate, staffId) {
    try {
      const url =
        this.apiBaseUrl +
        "/certificates/approve" +
        "?access_token=" +
        this.getToken();
      // console.log('url', url);
      const result = await this.httpClient
        .post<Certificate>(url, {
          data: { certificate: certificate, staffId: staffId },
        })
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async checkRemainingCertificateApproval(payload: object) {
    try {
      const url =
        this.apiBaseUrl +
        "/checkRemainingCertificateApproval" +
        "?access_token=" +
        this.getToken();
      console.log("url", url);
      const result = await this.httpClient.patch(url, payload).toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async Patch<T>(table: Tables, data: any, rowId: string) {
    try {
      const result = await this.httpClient
        .patch<T>(
          this.apiBaseUrl +
            "/" +
            Tables[table] +
            "/" +
            rowId +
            "?access_token=" +
            this.getToken(),
          data
        )
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async DeleteRelated<T>(
    table: Tables,
    foreignKey: string,
    relateWithTable: Tables,
    relateWithTableId: string
  ) {
    try {
      const url =
        this.apiBaseUrl +
        "/" +
        Tables[relateWithTable] +
        "/" +
        relateWithTableId +
        "/" +
        Attributes[table] +
        "/rel/" +
        foreignKey +
        "?access_token=" +
        this.getToken();
      const result = await this.httpClient.delete<T>(url).toPromise();
      // console.log('Deleting staff ', url);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async PatchRelation<T>(
    table: Tables,
    data: any,
    relateWithTable: Tables,
    relateWithTableId: string,
    rowId: string
  ) {
    try {
      const result = await this.httpClient
        .patch<T>(
          this.apiBaseUrl +
            "/" +
            Tables[relateWithTable] +
            "/" +
            relateWithTableId +
            "/" +
            Tables[table] +
            "/" +
            rowId +
            "?access_token=" +
            this.getToken(),
          data
        )
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async Post<T>(table: Tables, data: any) {
    try {
      const result = await this.httpClient
        .post<T>(
          this.apiBaseUrl +
            "/" +
            Tables[table] +
            "?access_token=" +
            this.getToken(),
          data
        )
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async PostWithoutModel(url: any, data: any) {
    try {
      const result = await this.httpClient
        .post(this.apiBaseUrl + "/" + url, data)
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async ChangePassword<T>(data: any) {
    try {
      const result = await this.httpClient
        .post<T>(
          this.apiBaseUrl +
            "/Staff/change-password" +
            "?access_token=" +
            this.getToken(),
          data
        )
        .toPromise();
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async GenerateCertificate<T>(data: any) {
    try {
      const result = await this.httpClient
        .post<T>(
          this.apiBaseUrl +
            "/generatecertificate" +
            "?access_token=" +
            this.getToken(),
          data
        )
        .toPromise();

      // console.log(data);
      return { success: true, data: result };
    } catch (e) {
      // console.log(e);
      return { success: false, data: e };
    }
  }

  async GetPicture(route) {
    // console.log(this.User);
    let queryString = "";
    queryString +=
      "/Attachments/" +
      "institute-" +
      this.User.instituteId +
      "-" +
      route +
      "/files";
    queryString += "?access_token=" + this.getToken();
    queryString = this.apiBaseUrl + queryString;

    // console.log('queryString', queryString);

    try {
      const result = await this.httpClient.get(queryString).toPromise();
      // console.log('result', result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async PostPicture(filelist: FileList, route) {
    console.log(this.User.institute);
    let queryString = "";
    queryString +=
      "/Attachments/" +
      "institute-" +
      this.User.instituteId +
      "-" +
      route +
      "/upload";
    queryString += "?access_token=" + this.getToken();
    queryString = this.apiBaseUrl + queryString;

    // console.log('queryString', queryString);
    const formData: FormData = new FormData();
    for (let f = 0; f < filelist.length; f++) {
      formData.append("fileKey" + f, filelist[f], filelist[f].name);
    }
    try {
      const result = await this.httpClient
        .post(queryString, formData)
        .toPromise();
      // console.log('result', result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  getToken() {
    return JSON.parse(localStorage.getItem("user"))["token"];
  }

  async uploadBlob(blob, fileName) {
    const formData: FormData = new FormData();

    let queryString = "";

    queryString +=
      "/Attachments/" +
      "institute-" +
      this.User.instituteId +
      "-" +
      "certificates" +
      "/upload";
    queryString += "?access_token=" + this.getToken();
    queryString = this.apiBaseUrl + queryString;
    formData.append("fileKey" + "", blob, fileName);

    try {
      const result = await this.httpClient
        .post(queryString, formData)
        .toPromise();
      // console.log('pic result', result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async sendEmail<T>(data: any) {
    try {
      const url =
        this.apiBaseUrl + "/sendEmail" + "?access_token=" + this.getToken();
      const result = await this.httpClient.post<T>(url, data).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async sendTransactionHistoryToMail(data: any) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      const url =
        this.apiBaseUrl +
        "/sendTransactionHistoryToMail" +
        "?access_token=" +
        this.getToken();
      const result = await this.httpClient.post(url, data).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async sendCertificateToMail(data: any) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      const url =
        this.apiBaseUrl +
        "/sendCertificateToMail" +
        "?access_token=" +
        this.getToken();
      const result = await this.httpClient.post(url, data).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }
  async getDocumentInBlockchainCount(userId: any) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      const url = this.blockchainBaseUrl + "/get-dashboard-document-count";
      const result = await this.httpClient.post(url, userId).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async getBlockchainFileList(userId: any, page, limit) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      let queryString = `${this.blockchainBaseUrl}/get-blockchain-list/?userId=${userId}&page=${page}&limit=${limit}`;
      // const url = this.blockchainBaseUrl + "/get-blockchain-list/";
      const result = await this.httpClient.get(queryString).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async getBlockchainFileDetails(id: any) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      let queryString = `${this.blockchainBaseUrl}/get-blockchain-file-details/?id=${id}`;
      // const url = this.blockchainBaseUrl + "/get-blockchain-list/";
      const result = await this.httpClient.get(queryString).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }

  async stripePayment(payload: any) {
    try {
      // const headers = new HttpHeaders({'responseEncoding':'binary','responseType': 'arraybuffer', 'Content-Type': 'application/pdf'})
      let url = `${this.apiBaseUrl}/stripe-payment`;
      // const url = this.blockchainBaseUrl + "/get-blockchain-list/";
      const result = await this.httpClient.post(url, payload).toPromise();
      // console.log('email Service ' + result);
      return { success: true, data: result };
    } catch (e) {
      return { success: false, data: e };
    }
  }
}

class Factory {
  create<T>(type: new () => T): T {
    return new type();
  }
}
