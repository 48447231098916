import { Component, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import verify from "../../certificates/printed-certificates/binance/verify.js";
import Web3DefaultAccount from "../../certificates/printed-certificates/binance/returnDefaultAccount.js";
import { GetUserDataService } from "../../services/get-user-data.service";
import { Router } from "@angular/router";
import { BlockchainCertificatePdfComponent } from "../../certificates/printed-certificates/blockchain-certificate-pdf/blockchain-certificate-pdf.component";

@Component({
  selector: "app-add-multiple-document",
  templateUrl: "./add-multiple-document.component.html",
  styleUrls: ["./add-multiple-document.component.css"],
})
export class AddMultipleDocumentComponent implements OnInit {
  @ViewChild(BlockchainCertificatePdfComponent)
  child: BlockchainCertificatePdfComponent;
  blockchainFile: any;
  constructor(
    private http: HttpClient,
    private userDataService: GetUserDataService,
    private router: Router
  ) {}
  files: string[] = [];
  loading = false;
  userData: any;
  async ngOnInit() {
    this.userData = await this.userDataService.returnUserData();
  }

  onFileChange(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
    }
    console.log("files", this.files);
  }

  async onSubmit() {
    if (this.files.length == 0) return;
    const defaultAccount = await Web3DefaultAccount();
    if (!defaultAccount) return;
    this.loading = true;
    const formData = new FormData();
    for (let i = 0; i < this.files.length; i++) {
      formData.append("files", this.files[i]);
    }
    formData.append("id", "");
    let uploadIpfsRes: any;
    try {
      uploadIpfsRes = await this.http
        .post(`${environment.blockchainBaseUrl}/bulk-upload-ipfs`, formData)
        .toPromise();
      console.log(uploadIpfsRes);
    } catch (error) {
      this.loading = false;
      console.log(error);
      window.alert("Something went wrong!");
    }

    if (!this.userData.institute.blockchainAddress) {
      this.loading = false;
      window.alert("Something went wrong");
      return;
    }

    const amount_to_send_wei =
      this.userData.institute.blockchainFees *
      1000000000000000000 *
      uploadIpfsRes.data.length;
    const blockchainAddress = this.userData.institute.blockchainAddress;
    const id = [];
    const ipfsHash = [];
    console.log(uploadIpfsRes, "first");
    uploadIpfsRes.data.map((res) => {
      id.push(res.mainFileId);
      ipfsHash.push(res.ipfsLink);
    });
    console.log(
      this.userData.institute.blockchainFees,
      amount_to_send_wei,
      uploadIpfsRes.data.length,
      id,
      ipfsHash
    );
    let result = await verify.methods
      .bulkAddPdfLink(id, ipfsHash, blockchainAddress)
      .send({ from: defaultAccount, value: amount_to_send_wei })
      .then(
        async (res) => {
          console.log({ res });
          function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
          }
          const finalArray = [];
          uploadIpfsRes.data.map((ipfsRes) => {
            finalArray.push({
              fileName: ipfsRes.fileName,
              hash: ipfsRes.hash,
              mainFileId: ipfsRes.mainFileId,
              fileSize: ipfsRes.size,
              userId: this.userData.id,
              transaction: res,
              fileType: ipfsRes.fileType,
            });
          });
          // uploadIpfsRes.transactionHistory = res;
          // uploadIpfsRes.userId = this.userData.id;
          uploadIpfsRes = finalArray;
          const saveToDB: any = await this.http
            .post(`${environment.blockchainBaseUrl}/save-database`, {
              uploadIpfsRes,
            })
            .toPromise();

          if (saveToDB) {
            this.blockchainFile = saveToDB;
            await delay(1000);
            this.child.pdf();
            this.loading = false;
            this.router.navigate(["home/blockchain/dashboard"]);
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          console.log(err);
          if (err.code == 4001) {
            window.alert("User has declined payment!! Error!!");
            return;
          }
        }
      )
      .catch((err) => console.log({ err }));
  }
}
