import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import * as $ from "jquery";
import { SharedHttpService } from "../../services/shared-http.service";
import { GetUserDataService } from "../../services/get-user-data.service";
@Component({
  selector: "app-stytch",
  templateUrl: "./stytch.component.html",
  styleUrls: ["./stytch.component.css"],
})
export class StytchComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private httpService: SharedHttpService,
    private getUserData: GetUserDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(async (queryParams) => {
      try {
        $(".preloader").fadeOut();
        const res = await this.httpService.Authenticate({
          token: queryParams.token,
        });
        if (res.success) {
          const getUser: any = await this.getUserData.getUserDataFromLogin(
            res.data.userId
          );
          if (!getUser.success) {
            return;
          }
          getUser.data.token = res.data.id;
          localStorage.setItem("user", JSON.stringify(getUser.data));
          // console.log(getUser.data);
          if (!getUser.data.staffCategory.permissions.viewDashboardAnalytics) {
            this.router.navigate(["/home/certificates/waiting-for-approval"]);
          } else {
            console.log(getUser.data.require2FA, getUser.data.verified2FA);
            if (getUser.data.require2FA && !getUser.data.verified2FA) {
              this.router.navigate(["/home/2FA-step"]);
            } else {
              this.router.navigate(["/home/dashboard"]);
            }
          }
        } else {
        }
      } catch (authError) {
        console.log("error", authError);
        throw authError;
      }
    });
  }
}
