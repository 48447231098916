import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {ExcelService} from '../../services/excel.service';
import {Certification} from '../../../Models/Certification';


@Component({
    selector: 'app-certification-candidates',
    templateUrl: './certification-candidates.component.html',
    styles: []
})
export class CertificationCandidatesComponent extends BaseComponent implements OnInit {

    items: any;
    cetification: Certification;
    itemsOnServer: any;
    noData = false;
    errorAlert = false;
    alertMsg: any;
    id;

    constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router, private route: ActivatedRoute) {
        super(router);
    }

   async ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        const input = document.querySelectorAll('input');
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
        }
       await this.getItems();
       await this.getCertification();

    }

    async getCertification() {
        const res = await this.httpService.Get(Certification,{table:Tables.Certifications, key:this.id});
        if (res.success) {
            // console.log('certification', res);
            this.cetification = res.data;
        } else {
            console.log('err', res);
        }
    }

    async getItems() {
        const res = await this.httpService.GetRelated(Tables.Certifications, this.id, Tables.Candidates);
    if (res.success) {
    //   console.log('data candidates', res);
      this.items = this.itemsOnServer = res.data;
    } else {
      console.log('err', res);
    }
    }

  updateFilter(e: any) {
    if(e){
      this.items = this.itemsOnServer.filter(x =>
        x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.registrationNumber.toLowerCase().includes(e.toLowerCase()));
    }else{
      this.items = this.itemsOnServer;
    }
  }

    async importExcelData(evt) {
        let page = this;
        await this.excelService.importExcelData(evt, async excelData => {
            // console.log('excel data', excelData);
            if (!excelData) {
                console.log('return');
                return;
            }
            const res = await this.httpService.AddCandidatesToCertification(excelData, this.id);
            // console.log('Posted Candidates', res);
            if (res.success) {
                // console.log('excel import', res);
                await this.getItems();
            } else {
                this.errorAlert = true;
                setTimeout(() => {
                    this.errorAlert = false;
                }, 3000);
                this.alertMsg = 'Validation error';
            }
        });

    }

}
