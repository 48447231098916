export class InputField {
    validators = {name: null, type: null};
    name: string;
    type: string;

    constructor() {
        this.name = '';
        this.type = '';
    }
}
