import { Component, OnInit, Input } from "@angular/core";
import { SharedHttpService } from "../services/shared-http.service";
import { environment } from "../../environments/environment";
import { TransporterService } from "../services/transporter.service";

@Component({
  selector: "app-image-selector",
  templateUrl: "./image-selector.component.html",
  styles: [],
})
export class ImageSelectorComponent implements OnInit {
  @Input() imageContainer: string;
  @Input() caller: string;
  items: any;
  noData = false;
  apiBaseUrl;
  fileToUpload: FileList;

  constructor(
    private httpService: SharedHttpService,
    private transporterService: TransporterService
  ) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  async ngOnInit() {
    await this.getItems();
  }

  closeModal() {
    this.transporterService.transmit({
      type: "image-selector",
      data: "close",
    });
  }

  async getItems() {
    const res = await this.httpService.GetPicture(this.imageContainer);
    if (res.success) {
      this.items = res.data.reverse();

      // console.log(this.items)
      // console.log('images', this.items);
      if (this.items === null || this.items.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else {
      console.log("err", res);
    }
  }

  selectImage(item) {
    // console.log("-------------------------------------")
    // console.log(item)
    this.transporterService.transmit({
      type: "image-selector",
      data: "image-selected",
      receiver: this.caller,
      file: item,
    });
  }

  async fileHandler(files: FileList) {
    this.fileToUpload = files;
    console.log("files", files);
    console.log("files item", files.item(0));
    const res = await this.httpService.PostPicture(this.fileToUpload, "images");
    console.log("res", res);
    if (res) {
      this.getItems();
    }
  }
}
