import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor(private fb: FormBuilder) {
    }

    Synthesize(myObject, formGroup: FormGroup) {
        let props = {};
        // console.log('input', myObject);
        for (const key in myObject) {
            // console.log(key);
            // dont touch and id, just leave it as a property
            if (key.toString() !== 'id') {

                if (typeof myObject[key] === 'object' && !(myObject[key] instanceof Date) && !Array.isArray(myObject[key])) {
                    props[key] = this.Synthesize(myObject[key], formGroup);
                } else if (Array.isArray(myObject[key])) {
                    // console.log('we have array');

                    for (const innerkey in myObject[key]) {
                        this.Synthesize(myObject[key][innerkey], formGroup);
                    }


                } else {
                    // console.log(key, formGroup.controls[key].valid); // only add those props which are valid form controls
                    if (formGroup.controls[key].valid) {
                        props[key] = myObject[key];

                    }


                }
            }
        }
        return props;
    }

    Get(myObject, inputJSON = {}, arraySizes = {}): FormGroup {

        let props = {};
        // let myObject = new type;
        for (const key in myObject) {

            if ((myObject.hasOwnProperty(key))) {

                // console.log(key);
                // dont touch and id, just leave it as a property
                if (key.toString() !== 'id' && key.toString() !== 'createdAt' && key.toString() !== 'updatedAt' && key.toString() !== 'validators') {
                    if (typeof myObject[key] === 'object' && !(myObject[key] instanceof Date) && !Array.isArray(myObject[key])) {
                        props[key] = this.Get(myObject[key], inputJSON[key]);
                    } else if (Array.isArray(myObject[key])) {
                        // console.log('we have array');

                        const formArray = [];
                        let arraySize = 1;
                        if (arraySizes[key]) {
                            arraySize = arraySizes[key];
                        }
                        for (let i = 0; i < arraySize; i++) {
                            for (const innerkey in myObject[key]) {
                                if ((myObject[key].hasOwnProperty(innerkey))) {
                                    // console.log('in array ', myObject[key][innerkey]);
                                    formArray.push(this.Get(myObject[key][innerkey], inputJSON[key]));
                                }

                            }
                        }

                        props[key] = this.fb.array(formArray);
                        // console.log('the form array', props[key]);

                    } else {
                        // we have custom validators

                        // if the input json has a value for this key then assign that to myObject
                        if (inputJSON[key]) {
                            myObject[key] = inputJSON[key];
                        }
                        if (myObject.validators && (myObject.validators[key] || myObject.validators[key] === null)) {

                            // console.log('we have custom validator for ', key);

                            if (myObject.validators[key] === null) {

                                // console.log('    its null \n');

                                props[key] = [myObject[key]];

                                // console.log(typeof myObject[key]);

                            } else {
                                // console.log('    its not null \n');


                                let validators = [];


                                validators.push(Validators.required);
                                validators.push(myObject.validators[key]);
                                props[key] = [myObject[key], validators];
                                // console.log(props[key]);

                            }


                        } else if (myObject[key] !== undefined) {
                            // console.log('\t\t\t REQUIRED', myObject[key]);
                            // console.log('\n');
                            props[key] = [myObject[key], Validators.required];
                        } else {
                            // the attribute was null/ not initialized and hence considered not required

                            // console.log('\t\t\t not required ', myObject[key]);
                            // console.log('\n');

                            props[key] = [''];

                        }


                    }
                }
            }
        }


        const form = this.fb.group(props);

        // console.log('FORM ', form);

        return form;
    }
}
