import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Attributes,Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Paper} from '../../../Models/Paper';
import {ExcelService} from '../../services/excel.service';

@Component({
  selector: 'app-assign-nfc',
  templateUrl: './assign-nfc.component.html',
  styleUrls: ['./assign-nfc.component.css']
})
export class AssignNfcComponent extends BaseComponent implements OnInit {

  stock;
  items: any;
  itemsOnServer: any;
  noData = false;
  certificateId;

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router, private route: ActivatedRoute) {
    super(router);
  }

  async ngOnInit() {
    this.certificateId = this.route.snapshot.params['id']
    await this.getNFCTags();
  }

  async getNFCTags() {
    const where = { isDamaged: false};
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.NFCTags,'',Attributes.None,where);
    if (res.success) {
      console.log('data stock', res);
      this.items = this.itemsOnServer = res.data;
      console.log('items', this.items);
    } else {
      console.log('err', res);
    }
  }

  getNFCItem() {
    return this.items.filter((item) => !item.certificateId);
  }

  async assignToCertificate(id) {
    const r = confirm('Are You Sure you want to assign this NFC?');
    if (r == true) {
      const data = {
        certificateId: Object(this.certificateId)
      };
      const res = await this.httpService.Patch(Tables.NFCTags, data, id);
      if (res.success) {
        this.router.navigate(['/home/certificates/printed']);
        // await this.getNFCTags();
      }
    }
  }
}
