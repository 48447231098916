import {Certificate} from './Certificate';
import {nonZeroValidator} from "../Validators/nonZeroValidator";
import {Validators} from '@angular/forms';

export class Candidate {

  validators = {certificationId: Validators.required};
  firstName: string;
  lastName: string;
  rollNumber: string;
  registrationNumber: string;
  id: string;
  instituteId: string;
  dp: string;
  certificationId: string;
  certificates: Array<Certificate>;

  constructor(instituteId: string) {
    this.firstName = this.rollNumber = this.registrationNumber = this.lastName = '';
    this.instituteId = instituteId;
    this.certificationId = '';
  }
}
