import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedHttpService } from "../../services/shared-http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Attributes, Tables } from "../../../Models/Tables";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as $ from "jquery";
import { environment } from "../../../environments/environment";
import verify from "../printed-certificates/binance/verify.js";
// import web3 from "../printed-certificates/binance/web3.js";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { BlockchainFile } from "./blockchainTypes";
// import { BlockchainCertificatePdfComponent } from "../printed-certificates/blockchain-certificate-pdf/blockchain-certificate-pdf.component";
@Component({
  selector: "app-verify-certificate",
  templateUrl: "./verify-certificate.component.html",
  styleUrls: ["./verify-certificate.css"],
})
export class VerifyCertificateComponent implements OnInit {
  identifier: any;
  public form: FormGroup;
  notVerified = false;
  verified = false;
  certificate: any;
  certhtml = "";
  apiBaseUrl: any;
  currentHeight = 0;
  currentWidth = 0;
  showImageBtn = false;
  imageUrl;
  url: any;
  isBlockchain = false;
  fileName: string;
  blockchainFile: any;
  pdfLink: any;
  isShowPdfModal: boolean = false;
  isLoading = false;
  isRendered = false;
  thumbnail: any;
  blockChainFileData: any;
  isVerifyHashModal: boolean;
  HashFile: any;
  serverBlockchainFileHash: string;
  uploadedFileHash: string;
  hashMatch: boolean;
  hashCheckAgain: boolean;

  blockchainFileData: BlockchainFile;
  // @ViewChild(BlockchainCertificatePdfComponent)
  // child: BlockchainCertificatePdfComponent;
  constructor(
    private httpService: SharedHttpService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    this.identifier = this.route.snapshot.params["id"];
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  ngOnInit() {
    this.form = this.fb.group({
      pin: ["", Validators.required],
    });
  }

  async submit() {
    // console.log('form valid', this.form.valid);
    if (this.form.valid) {
      /*     const res = await this.httpService.GetFromTable(Tables.Certificates, [Attributes.candidate, Attributes.certification],
                   {identifier: this.identifier, pin: this.form.value.pin, isPrinted: true, isVoid: {or:[null,false]}});*/
      const res = await this.httpService.verifyCertificate(
        this.identifier,
        this.form.value.pin
      );
      // console.log(res);
      this.certificate = res.data;
      if (res["data"] == null || res.data.length < 1) {
        this.notVerified = true;
        this.verified = false;
      } else {
        this.notVerified = false;
        this.verified = true;
        this.certhtml = this.certificate.print;
        if (this.certificate.candidate.dp) {
          this.showImageBtn = true;
          this.imageUrl =
            this.apiBaseUrl +
            "/Attachments/institute-" +
            this.certificate.candidate.instituteId +
            "-candidates/download/" +
            this.certificate.candidate.dp;
        }
        if (this.certificate.googleFonts) {
          $(".dynamic-google-fonts").remove();
          const fontsCollection = this.certificate.googleFonts.split("|");
          fontsCollection.forEach((f) => {
            if (f.length > 1) {
              $("head").append(
                '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
                  f.replace(/\s+/g, "+") +
                  '" type="text/css" />'
              );
            }
          });
        }
      }
    } else {
      // console.log('form valid', this.form.valid);
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }

  closePreview() {
    $("body").removeClass("cert-modal-active");
    $(".cert-holder").html("No Certificate Record found");
  }

  async showPreview() {
    const id = this.certificate.id;
    try {
      const file: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, {
          id,
        })
        .toPromise();
      if (file.length <= 0) {
        const this_ = this;
        $(".cert-holder")
          .html($(".source-html").html())
          .promise()
          .done(function () {
            this_.updateZoomLevel(0.2);
            $("body").addClass("cert-modal-active");
          });
        return;
      }
      this.blockChainFileData = file[0];
      console.log(this.blockChainFileData);
      this.isShowPdfModal = true;
      if (this.pdfLink) return;
      this.isLoading = true;
      this.blockchainFile = file[0];
      this.isBlockchain = true;
      let ipfsFileHash = await verify.methods
        .getPdfLink(this.blockchainFile.mainFileId)
        .call();
      const url: any = await this.http
        .post(`${environment.blockchainBaseUrl}/download-file-blockchain`, {
          ipfsFileHash,
        })
        .toPromise();
      this.isLoading = false;
      const sanitizer = this.sanitizer.bypassSecurityTrustResourceUrl(
        `${environment.blockchainUrl}${url.image}`
      );

      console.log(url, url.image);

      this.pdfLink = sanitizer;
      this.isRendered = true;
    } catch (error) {
      console.log(error);
    }

    // -----------------------------------------downloading blockchain complete-----------------
  }

  async verifyHash() {
    this.isVerifyHashModal = true;
    const id = this.certificate.id;
    try {
      const file: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, {
          id,
        })
        .toPromise();
      this.serverBlockchainFileHash = file[0].hash;
      this.blockChainFileData = file[0];
    } catch (error) {
      window.alert(`Can't get data! Something went wrong!`);
    }
  }

  async onVerifyHashFileUploadChange(e) {
    this.HashFile = e.target.files[0];
    const formData = new FormData();
    formData.append("file", this.HashFile);
    try {
      const hash: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-sha-256Hash`, formData)
        .toPromise();
      this.uploadedFileHash = hash.hash;
      if (
        this.uploadedFileHash.toString() ===
        this.serverBlockchainFileHash.toString()
      )
        this.hashMatch = true;
      else this.hashMatch = false;
    } catch (error) {
      window.alert(`Something went wrong!`);
    }
  }

  verifyHashModalClose() {
    this.isVerifyHashModal = false;
    this.uploadedFileHash = null;
  }
  onCheckVerifyHashFileUploadCheckAgain() {
    this.HashFile = undefined;
    this.uploadedFileHash = null;
    this.hashMatch = false;
  }

  showImage() {
    let imageHtml =
      '<div id="the-certificate" class="the-certificate size-a4-landscape ppi_150" style="transform: scale(0.2);"><img src="' +
      this.imageUrl +
      '"></div>';
    const this_ = this;
    $(".cert-holder")
      .html(imageHtml)
      .promise()
      .done(function () {
        this_.updateZoomLevel(0.2);
        $("body").addClass("cert-modal-active");
      });
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(() => {
      // console.log(document.getElementsByClassName('cert-in-preview'));
      this.currentHeight = document
        .getElementById("the-certificate")
        .getBoundingClientRect().height;
      this.currentWidth = document
        .getElementById("the-certificate")
        .getBoundingClientRect().width;
      $(".cert-holder").css("width", this.currentWidth + "px");
      $(".cert-holder").css("height", this.currentHeight + "px");
    }, 500);
  }
  // async onBlockchainCertificate() {
  //   const id = this.certificate.id;
  //   try {
  //     const file: any = await this.http
  //       .post(`${environment.blockchainBaseUrl}/get-blockchain-file`, {
  //         id,
  //       })
  //       .toPromise();
  //     this.blockChainFileData = file[0];
  //     this.child.pdf();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
}
