import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Person} from '../../../Models/Person';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styles: []
})
export class UserProfileComponent extends BaseComponent implements OnInit {

    userProfile: Person;
    userId: string;
    activeTab: any;

    constructor(public route: ActivatedRoute, public httpService: SharedHttpService, public router: Router) {
        super(router);
        this.userId = route.snapshot.params['id'];
        this.activeTab = route.snapshot.params['tab'];
        // console.log('tab', this.activeTab);
    }

    async ngOnInit() {
        await this.getUsers();
    }

    async getUsers() {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Staff, this.userId);
        if (res.success) {
            // console.log('profile data', res.data);
            this.userProfile = res.data;
        } else {
            console.log('profile err', res);
        }
    }

    tabChange(tab: any) {
        this.activeTab = tab;
    }
}
