import { Component, OnInit, AfterViewChecked } from "@angular/core";
import * as $ from "jquery";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { Tables } from "../../../Models/Tables";
import { SharedHttpService } from "../../services/shared-http.service";
import { environment } from "../../../environments/environment";
import { GetUserDataService } from "src/app/services/get-user-data.service";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.css"],
})
export class SideBarComponent
  extends BaseComponent
  implements OnInit, AfterViewChecked
{
  apiBaseUrl;
  isAuthentificUser = false;

  constructor(
    private httpService: SharedHttpService,
    private route: ActivatedRoute,
    private router: Router,
    private getUserDataService: GetUserDataService
  ) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  ngOnInit() {
    this.getUserDataService.returnUserData().then((res) => {
      this.isAuthentificUser = res.institute.authentificUser;
    });
  }

  ngAfterViewChecked() {
    $(".has-arrow").on("click", function (e: any) {
      e.preventDefault();
      e.stopImmediatePropagation();
      const $this = $(this);
      $this.toggleClass("active");
      $this.parent("li").find(".collapse").toggleClass("in");
    });
  }

  async logOut() {
    const res = await this.httpService.Patch(
      Tables.Staff,
      { verified2FA: false },
      this.user.id
    );
    if (res.success) {
      localStorage.removeItem("user");
      this.router.navigate(["/signin"]);
    }
  }
}
