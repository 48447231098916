import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Candidate} from '../../../Models/Candidate';
import {ExcelService} from '../../services/excel.service';


@Component({
  selector: 'app-view-candidates',
  templateUrl: './view-candidates.component.html',
  styles: []
})
export class ViewCandidatesComponent extends BaseComponent implements OnInit {

  items: Array<Candidate> = new Array<Candidate>();
  itemsOnServer: any;
  errorAlert = false;
  alertMsg: any;

  constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router) {
    super(router);
  }

 async ngOnInit() {
   await this.getItems();
  }

  async getItems() {
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Candidates);
    if (res.success) {
      // console.log('data candidates', res);
      this.items = this.itemsOnServer = res.data;
    } else {
      console.log('err', res);
    }
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(x =>
        x.firstName.toLowerCase().includes(e.toLowerCase()) ||
        x.lastName.toLowerCase().includes(e.toLowerCase()) ||
        x.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
        x.registrationNumber.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.items = this.itemsOnServer;
    }
  }

  async importExcelData(evt) {
    let page = this;
    await this.excelService.importExcelData(evt, async excelData => {
      // console.log('excel data', excelData);
      if (!excelData) {
        // console.log('return');
        return;
      }
      const res = await this.httpService.PostRelation<Candidate>
      (Tables.Candidates, excelData, Tables.Institutes, this.user.institute.id);

      if (res.success) {
        // console.log('excel import', res);
        this.getItems();
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = 'Validation error';
      }
    });

  }

}
