import { Component, OnInit } from "@angular/core";
import { SharedHttpService } from "../../services/shared-http.service";
import { Router } from "@angular/router";
import { Attributes, Tables } from "../../../Models/Tables";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { Person } from "../../../Models/Person";
import { GetUserDataService } from "../../services/get-user-data.service";

@Component({
  selector: "app-view-users",
  templateUrl: "./view-users.component.html",
  styles: [],
})
export class ViewUsersComponent extends BaseComponent implements OnInit {
  items: Array<Person>;
  itemsOnServer: any;
  isAuthentificUser = false;
  constructor(
    private httpService: SharedHttpService,
    private router: Router,
    private getUserData: GetUserDataService
  ) {
    super(router);
  }

  async ngOnInit() {
    const user = await this.getUserData.returnUserData();
    this.isAuthentificUser = user.institute.authentificUser;
    const input = document.querySelectorAll("input");
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute(
        "size",
        input[i].getAttribute("placeholder").length + "px"
      );
    }
    await this.getUsers();
  }

  async getUsers() {
    const res = await this.httpService.GetRelated(
      Tables.Institutes,
      this.user.institute.id,
      Tables.Staff,
      "",
      Attributes.staffCategory
    );
    if (res.success) {
      // console.log('data user', res);
      this.items = this.itemsOnServer = res.data;
    } else {
      console.log("err", res);
    }
  }

  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(
        (x) =>
          x.firstName.toLowerCase().includes(e.toLowerCase()) ||
          x.lastName.toLowerCase().includes(e.toLowerCase()) ||
          x.email.toLowerCase().includes(e.toLowerCase())
      );
    } else {
      this.items = this.itemsOnServer;
    }
  }

  async blockUser(rowId) {
    const r = confirm("Are You Sure?");
    if (r == true) {
      let data = { isBlocked: true };
      const res = await this.httpService.Patch(Tables.Staff, data, rowId);
      if (res.success) {
        await this.getUsers();
      }
    }
  }
}
