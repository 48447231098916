import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedHttpService } from "../../services/shared-http.service";
import * as interact from "interactjs";
import * as $ from "jquery";
import { Tables } from "../../../Models/Tables";
import { TransporterService } from "../../services/transporter.service";
import { Subscription } from "rxjs/Subscription";
import { environment } from "../../../environments/environment";
import { BluePrint } from "../../../Models/BluePrint";
import { BaseComponent } from "../../BaseComponent/BaseComponent";

@Component({
  selector: "app-designer",
  templateUrl: "./designer.component.html",
  styles: [],
})
export class DesignerComponent
  extends BaseComponent
  implements OnInit, AfterViewChecked, AfterViewInit
{
  isSaved = false;
  imageSelector = false;
  imageFile: any;
  apiBaseUrl: any;
  selectingBG = false;
  template: string;
  currentPageIndex = 0;
  public design: any;
  public currentHeight;
  public currentWidth;
  public fontsCollection = [];
  public fontsCollectionString = "";
  public selectedElement = ".the-certificate";
  public ff_placeholder = "Select...";
  public fontToSelect;
  public googleFonts = [];
  public gridHelpers = false;
  public resizeTimer = null;
  public pageScroll;
  public googleFontsRoute =
    "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDQp4rBybhVN0dPRDZAAEG4Ezby8mFQo0g";
  private subscription: Subscription;
  public paperSize = {
    a4: {
      landscape: [1754, 1240],
      portrait: [1240, 1754],
    },
  };

  constructor(
    private router: Router,
    private httpService: SharedHttpService,
    private route: ActivatedRoute,
    private transporterService: TransporterService
  ) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  @HostListener("window:beforeunload", ["$event"])
  doSomething($event) {
    if (!this.isSaved) {
      $event.returnValue = true;
    }
  }

  ngAfterViewChecked() {
    const _this2 = this;

    $(".templateButtons button").on("click", function (e) {
      e.stopImmediatePropagation();
      /*save current page*/
      $(".grid-helpers").remove();
      $(".gridToggleLabel").html("Show grid");
      _this2.design.template[_this2.currentPageIndex] =
        $(".certificate-wrap").html();
      /*saved*/

      _this2.currentPageIndex = $(e.target).index();
      _this2.switchPage();
    });

    $(".vb-inner button").on("click", function (e) {
      e.stopImmediatePropagation();
      const text = $(e.target).data("variable");
      _this2.insertTextAtCursor(text);
    });

    $(".delable").on("click", function (e) {
      e.stopImmediatePropagation();
      $(this).parents(".dynamic-element").remove();
      _this2.resetEditables();
      $(".the-certificate").trigger("click");
    });

    $(".txt-wgt").click("click", function (e) {
      e.stopImmediatePropagation();
      $(".bg-selector").hide();
      $(".only-for-layers").show();
      $(".aop-tabs button").removeClass("active");
      $(".dyn-element-highlight").addClass("active");
      _this2.selectedElement =
        "#" + $(this).parents(".dynamic-element").attr("id");
      const $parent = $(this).parents(".dynamic-element");
      const $target = $(this);
      $(".dynamic-element").removeClass("selected-element edit-on");
      if (!$parent.hasClass("edit-on")) {
        $target.attr("contenteditable", "true");
        $parent.addClass("selected-element edit-on");
      } else {
        $parent.removeClass("selected-element edit-on");
        $target.attr("contenteditable", "false");
      }
      _this2.setAdvancePropertiesPanel();
    });

    interact(".draggable").draggable({
      inertia: true,
      restrict: {
        restriction: ".certificate-wrap",
        endOnly: true,
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
      },
      // enable autoScroll
      autoScroll: true,

      // call this function on every dragmove event
      onmove: function (event) {
        console.log("moving the element");
        _this2.selectedElement = event.target.parentNode.parentNode;
        const target = event.target,
          x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
          y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
        $(target).parents(".dynamic-element").addClass("selected-element");
        $(target)
          .parents(".dynamic-element")
          .css("top", y + "px");
        $(target)
          .parents(".dynamic-element")
          .css("left", x + "px");
        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);
        clearTimeout(_this2.resizeTimer);
        _this2.resizeTimer = setTimeout(function () {
          _this2.resetEditables();
          _this2.setAdvancePropertiesPanel();
        }, 50);
      },
      onend: function (event) {
        $(".dynamic-element").removeClass("selected-element");
      },
    });

    interact(".resizeable")
      .resizable({
        edges: { left: true, right: true, bottom: true, top: false },
      })

      .on("resizemove", function (event) {
        console.log("i am resizing");
        _this2.selectedElement = event.target;
        const target = event.target;
        // console.log(event)
        // console.log(target)
        let width = event["rect"]["width"] * 2;
        if ($(event.target).hasClass("line-element")) {
          $(event.target)
            .children(".the-real-liner")
            .css("height", event["rect"]["height"] + "px");
          $(event.target)
            .children(".the-real-liner")
            .css("width", width + "px");
        } else {
          $(target).css("width", width + "px");
          if (!$(event.target).hasClass("editable-content")) {
            $(target).css("height", event["rect"]["height"] + "px");
          }
        }
        clearTimeout(_this2.resizeTimer);
        _this2.resizeTimer = setTimeout(function () {
          _this2.resetEditables();
          _this2.setAdvancePropertiesPanel();
        }, 50);
      });
  }

  async ngOnInit() {
    $("body").addClass("cert-printing-page");
    this.getFontsList();
    this.getDesign(this.route.snapshot.params["id"]);

    this.subscription = this.transporterService
      .receive()
      .subscribe((parcel) => {
        if (parcel.type === "image-selector") {
          if (parcel.data === "image-selected") {
            this.imageFile = parcel.file;
            const selectedImage =
              this.apiBaseUrl +
              "/Attachments/" +
              this.imageFile.container +
              "/download/" +
              this.imageFile.name;
            if (this.selectingBG) {
              this.checkImage(selectedImage);
            } else {
              const totalElements =
                $(".the-certificate .dynamic-element").length + 1;
              $(".the-certificate").append(
                '<div id="content-box-' +
                  totalElements +
                  '" class="user-added-image dynamic-element">' +
                  '<div class="dynamic-controls">' +
                  '<button data-x="100" data-y="100" class="draggable btn waves-effect waves-light btn-primary"><i class=" fas fa-arrows-alt"></i></button>' +
                  '<button class="delable btn waves-effect waves-light btn-danger"><i class="fa fa-trash"></i></button>' +
                  "</div>" +
                  '<img id="content-box-' +
                  totalElements +
                  '" class="resizeable" src="' +
                  selectedImage +
                  '">' +
                  "</div>"
              );
            }
            this.imageSelector = false;
          }
          if (parcel.data === "close") {
            this.imageSelector = false;
          }
        }
      });
  }

  async checkImage(selectedImage: any) {
    console.log(selectedImage);

    const img = await this.getMeta(selectedImage);

    console.log(img);

    if (
      img["width"] === +$(".trigger-bg-set").data("width") &&
      img["height"] === +$(".trigger-bg-set").data("height")
    ) {
      if (!$(".pageBg").length) {
        $(".the-certificate").prepend(
          '<div class="pageBg"> <img draggable="false" class="pageBg" src="' +
            selectedImage +
            '"/> </div>'
        );
      } else {
        $(".pageBg img").attr("src", selectedImage);
      }
      $(".selected-bg-image img").attr("src", selectedImage);
      this.setAdvancePropertiesPanel();
    } else {
      alert(
        "Please choose image with correct dimension (i.e. " +
          +$(".trigger-bg-set").data("width") +
          "x" +
          +$(".trigger-bg-set").data("height") +
          ")."
      );
    }
  }

  async getMeta(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  }

  resetEditables() {
    $(".txt-wgt").attr("contenteditable", false);
    $(".editable-content").removeClass("edit-on");
    $(".editable-content").addClass("resizeable");
    $(".editable .fa").removeClass("fa-check").addClass("fa-pen");
  }

  toggleImagesModal() {
    console.log("toggling image modal");
    this.selectingBG = false;
    this.imageSelector = !this.imageSelector;
  }

  toggleVBox() {
    $(".variable-box").fadeToggle();
  }

  toggleQRCode() {
    if (!$("#qr-code-identification").length) {
      $(".qrCodeLabel").text("Remove QR Code");
      const y_axis = $(".the-certificate").height() * 0.7;
      const x_axis = $(".the-certificate").width() * 0.81;

      $(".the-certificate").append(
        "" +
          '<div id="qr-code-identification" style="left: ' +
          x_axis +
          "px; top:" +
          y_axis +
          'px;" class="editable-content dynamic-element ">' +
          '<div class="dynamic-controls">' +
          '<button data-x=" ' +
          x_axis +
          '" data-y="' +
          y_axis +
          '" class="draggable btn waves-effect waves-light btn-primary"><i class=" fas fa-arrows-alt"></i></button>' +
          "</div>" +
          '<img id="qr-code-identification" src="https://api.qrserver.com/v1/create-qr-code/?data=dummy_authentific&size=500x500" alt="loading qr code..." >' +
          "</div>"
      );
      $(".the-certificate").append(
        "" +
          '<div id="identification-pin" style="left:' +
          x_axis +
          "px; top:" +
          (y_axis + 140) +
          'px; width: 131.188px;"  class="editable-content dynamic-element resizeable selected-element edit-on txt-center">' +
          '<div class="dynamic-controls">' +
          '<button data-x=" ' +
          x_axis +
          '" data-y="' +
          (y_axis + 140) +
          '" class="draggable btn waves-effect waves-light btn-primary"><i class=" fas fa-arrows-alt"></i></button>' +
          "</div>" +
          '<div class="txt-wgt" style="font-size: 18px;">Pin: #pin</div>' +
          "</div>"
      );
      $(".certificate-canvas").animate(
        { scrollTop: y_axis, scrollLeft: x_axis },
        250
      );
    } else {
      $(".qrCodeLabel").text("Insert QR Code");
      $("#qr-code-identification,#identification-pin").remove();
    }
  }

  bgSetter() {
    this.selectingBG = true;
    this.imageSelector = !this.imageSelector;
  }

  insertTextAtCursor(text) {
    let sel, range;
    if (window.getSelection) {
      if (
        $(this.selectedElement).hasClass("editable-content") &&
        $(this.selectedElement).hasClass("edit-on")
      ) {
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          // console.log('last stand');
          range = sel.getRangeAt(0);
          range.deleteContents();
          range.insertNode(document.createTextNode(text));
          $(".variable-box").fadeOut();
        }
      } else {
        alert("Variables can only be inserted inside a text element.");
      }
    } else {
      alert("Please use either firefox or chrome to make certificates");
    }
  }

  cancelDesign() {
    if (!this.isSaved) {
      const r = confirm(
        "Are you sure you want to leave without saving your design?"
      );
      if (r == true) {
        this.router.navigate(["/home/layouts"]);
      }
    } else {
      this.router.navigate(["/home/layouts"]);
    }
  }

  async removePage() {
    // console.log('Current Page', this.currentPageIndex);
    // console.log('Total Pages', this.design.template.length);
    this.design.template = this.design.template.filter((x, i) => {
      if (i !== this.currentPageIndex) {
        return x;
      }
    });
    this.currentPageIndex -= 1;
    // console.log('Current Page', this.currentPageIndex);
    // console.log('Total Pages', this.design.template.length);
    this.populatePageTabs();
    this.switchPage();
  }

  async saveDesign() {
    $(".grid-helpers").remove();
    $(".gridToggleLabel").html("Show grid");
    this.design.template[this.currentPageIndex] = $(".certificate-wrap")
      .html()
      .replace("candidate_fatherName", "candidate_lastName")
      .replace("candidate_name", "candidate_firstName");
    // this.design.template[this.currentPageIndex] = $('.certificate-wrap').html().replace('candidate_name', 'candidate_firstName');
    const res = await this.httpService.Patch<BluePrint>(
      Tables.BluePrints,
      {
        template: this.design.template,
        googleFonts: this.fontsCollectionString || "",
      },
      this.design.id
    );
    if (res.success) {
      this.isSaved = true;
      $(".saveStatusLabel").html("✓ saved");
    }
  }

  async getDesign(id) {
    const getRequest = await this.httpService.Get(BluePrint, {
      table: Tables.BluePrints,
      key: id,
    });
    if (getRequest.success) {
      this.design = getRequest.data;
      this.fontsCollectionString =
        this.design.googleFonts && this.design.googleFonts.length
          ? this.design.googleFonts
          : "";
      this.callGoogleFonts();
      this.template = getRequest["data"]["template"][0];
      $(".trigger-bg-set").data(
        "width",
        this.paperSize[getRequest["data"]["size"]][
          getRequest["data"]["orientation"]
        ][0]
      );
      $(".trigger-bg-set").data(
        "height",
        this.paperSize[getRequest["data"]["size"]][
          getRequest["data"]["orientation"]
        ][1]
      );
      if (
        this.template &&
        this.template.length &&
        getRequest["data"]["template"][0].length
      ) {
        const _this2 = this;
        this.template = this.template
          .replace("candidate_lastName", "candidate_fatherName")
          .replace("candidate_firstName", "candidate_name");
        // this.template = this.template.replace('candidate_firstName', 'candidate_name');
        $(".certificate-wrap")
          .html(this.template)
          .promise()
          .done(function () {
            // _this2.toggleToDefaults();
            _this2.toggleToSelectedElement();
            if ($(".pageBg").length) {
              $(".selected-bg-image img").attr(
                "src",
                $(".pageBg img").attr("src")
              );
            }
            if ($("#qr-code-identification").length) {
              $(".qrCodeLabel").text("Remove QR Code");
            } else {
              $(".qrCodeLabel").text("Insert QR Code");
            }
            _this2.updateZoomLevel(0.5);
            _this2.setAdvancePropertiesPanel();
            _this2.populatePageTabs();
          });
      } else {
        alert("Invalid or corrupt certificate design");
      }
    } else {
      this.router.navigate(["/home/layouts"]);
    }
  }

  toggleToSelectedElement() {
    $(".bg-selector").show();
    $(".only-for-layers").show();
    $(".aop-tabs button").removeClass("active");
    $(".dyn-element-highlight").addClass("active");
    $(".dynamic-element").removeClass("selected-element edit-on");
    this.setAdvancePropertiesPanel();
  }

  toggleToDefaults() {
    // console.log('set defaults');
    $(".only-for-layers").hide();
    $(".bg-selector").show();
    $(".dyn-element-highlight").removeClass("active");
    $(".defaults-highlight").addClass("active");
    this.ff_placeholder = $(".the-certificate").css("font-family");
    this.selectedElement = ".the-certificate";
    this.setAdvancePropertiesPanel();
  }

  populatePageTabs() {
    let tabs = "",
      index = 1;
    for (let i = 0; i < this.design.template.length; i++) {
      index = i + 1;
      if (i === this.currentPageIndex) {
        tabs += '<button class="active">Page ' + index + "</button>";
      } else {
        tabs += "<button>Page " + index + "</button>";
      }
    }
    $(".templateButtons").html(tabs);
  }

  newPage() {
    /*save current page*/
    $(".grid-helpers").remove();
    $(".gridToggleLabel").html("Show grid");
    this.design.template[this.currentPageIndex] = $(".certificate-wrap").html();
    /*saved*/

    /*change to next index*/
    this.currentPageIndex = this.design.template.length;
    this.design.template.push(
      '<div style="background-color : #fff;" id="the-certificate-' +
        this.currentPageIndex +
        '" class="the-certificate size-' +
        this.design.size +
        "-" +
        this.design.orientation +
        ' ppi_150"></div>'
    );
    this.populatePageTabs();
    this.switchPage();
  }

  switchPage() {
    const this2 = this;
    this.template = this.design.template[this.currentPageIndex];
    if (this.currentPageIndex >= 1) {
      $(".removePageTrigger").show();
    } else {
      $(".removePageTrigger").hide();
    }
    $(".certificate-wrap")
      .html(this.template)
      .promise()
      .done(function () {
        if ($("#qr-code-identification").length) {
          $(".qrCodeLabel").text("Remove QR Code");
        } else {
          $(".qrCodeLabel").text("Insert QR Code");
        }
        this2.updateZoomLevel(1);
        this2.setAdvancePropertiesPanel();
      });
    $(".templateButtons button").removeClass("active");
    $(".templateButtons button").eq(this.currentPageIndex).addClass("active");
  }

  ngAfterViewInit() {}

  async getFontsList() {
    const res = await this.httpService.GetExternal(this.googleFontsRoute);
    if (res["success"]) {
      this.googleFonts = res["data"]["items"];
    } else {
      console.log("google fonts has betrayed us");
    }
  }

  makeBold() {
    document.execCommand("bold");
  }

  toggleGridVisibility() {
    this.gridHelpers = !this.gridHelpers;
    if (!$(".grid-helpers").length) {
      $(".the-certificate").prepend(
        '<div id="the-grid-v" class="grid-helpers"></div><div id="the-grid-h" class="grid-helpers"></div>'
      );
      for (let v = 0; v < 200; v++) {
        $("#the-grid-v").prepend("<span></span>");
      }
      for (let h = 0; h < 200; h++) {
        $("#the-grid-h").prepend("<span></span>");
      }
    }
    if (this.gridHelpers) {
      $(".grid-helpers").show();
      $(".gridToggleLabel").html("Hide grid");
    } else {
      $(".grid-helpers").hide();
      $(".gridToggleLabel").html("Show grid");
    }
  }

  makeItalic() {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    document.execCommand("italic");
  }

  makeUnderline() {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    document.execCommand("underline");
  }

  textAlignment(e, val) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    // console.log(this.selectedElement);
    // console.log(val);
    $(this.selectedElement).removeClass("txt-left");
    $(this.selectedElement).removeClass("txt-right");
    $(this.selectedElement).removeClass("txt-center");
    $(this.selectedElement).addClass("txt-" + val);
  }

  setAdvancePropertiesPanel() {
    $(".selectedEleID").text("#" + $(this.selectedElement).attr("id"));
    $("#selected-fs").val(parseFloat($(this.selectedElement).css("font-size")));
    if (
      $(this.selectedElement).attr("id") &&
      $(this.selectedElement).attr("id") !== "the-certificate"
    ) {
      $("#selected-x").val(parseFloat($(this.selectedElement).css("left")));
      $("#selected-y").val(parseFloat($(this.selectedElement).css("top")));
      this.fontToSelect = $(this.selectedElement).css("font-family");
    } else {
      this.fontToSelect = $(".the-certificate").css("font-family");
    }
    $("#selected-lh").val(
      parseFloat($(this.selectedElement).css("line-height"))
    );
  }

  updateFontSize(e) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const val = +parseFloat(e.target.value);
    $(this.selectedElement).css("font-size", val + "px");
  }

  fontChanged(e) {
    if (e) {
      this.isSaved = false;
      $(".saveStatusLabel").html("Save");
      if (this.fontsCollection.indexOf(e) === -1) {
        this.fontsCollection.push(e);
        this.fontsCollectionString = "";
        this.fontsCollection.forEach((f) => {
          this.fontsCollectionString = this.fontsCollectionString.length
            ? this.fontsCollectionString + "|" + f.replace(/\s+/g, "+")
            : f.replace(/\s+/g, "+");
        });
        this.callGoogleFonts();
      }
      $(this.selectedElement).css("font-family", e);
    }
  }

  callGoogleFonts() {
    $(".dynamic-google-fonts").remove();
    this.fontsCollection = [];
    this.fontsCollection = this.fontsCollectionString.split("|");
    this.fontsCollection.forEach((f) => {
      if (f.length > 1) {
        $("head").append(
          '<link class="dynamic-fonts" rel="stylesheet" href="https://fonts.googleapis.com/css?family=' +
            f.replace(/\s+/g, "+") +
            '" type="text/css" />'
        );
      }
    });
  }

  updateTextColor(e) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    $(this.selectedElement).css("color", e["color"]["hex"]);
  }

  updateY(e) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const val = +parseFloat(e.target.value);
    $(this.selectedElement).css("top", val + "px");
    $(this.selectedElement).data("data-y", val);
  }

  updateX(e) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const val = +parseFloat(e.target.value);
    $(this.selectedElement).css("left", val + "px");
    $(this.selectedElement).data("data-x", val);
  }

  updateLineHeight(e) {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const val = +parseFloat(e.target.value);
    $(this.selectedElement).css("line-height", val + "px");
  }

  updateZoomLevel(val) {
    $(".the-certificate").css("transform", "scale(" + val + ")");
    setTimeout(function () {
      const certId = $(".certificate-wrap").find(".the-certificate").attr("id");
      // console.log(certId);
      this.currentHeight = document
        .getElementById(certId)
        .getBoundingClientRect().height;
      this.currentWidth = document
        .getElementById(certId)
        .getBoundingClientRect().width;
      $(".certificate-wrap").css("width", this.currentWidth + "px");
      $(".certificate-wrap").css("height", this.currentHeight + "px");
    }, 200);
  }

  insertLine() {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const totalElements = $(".the-certificate .dynamic-element").length + 1;
    const y_axis = $(".the-certificate").height() * 0.6;
    const x_axis = $(".the-certificate").width() * 0.4;
    $(".the-certificate").append(
      "" +
        '<div style="left:' +
        x_axis +
        "px; top: " +
        y_axis +
        'px;" id="line-box-' +
        totalElements +
        '" class="line-element dynamic-element resizeable">' +
        '<div class="dynamic-controls">' +
        '<button data-x="' +
        x_axis +
        '" data-y="' +
        y_axis +
        '" class="draggable btn waves-effect waves-light btn-primary"><i class=" fas fa-arrows-alt"></i></button>' +
        '<button class="delable btn waves-effect waves-light btn-danger"><i class="fa fa-trash"></i></button>' +
        "</div>" +
        '<div style="background:#222; height: 2px; width: 300px" class="the-real-liner"></div>' +
        "</div>"
    );
    $(".certificate-canvas").animate(
      { scrollTop: y_axis - 200, scrollLeft: x_axis - 200 },
      250
    );
  }

  insertNewText() {
    this.isSaved = false;
    $(".saveStatusLabel").html("Save");
    const totalElements = $(".the-certificate .dynamic-element").length + 1;
    const y_axis = $(".the-certificate").height() * 0.6;
    const x_axis = $(".the-certificate").width() * 0.1;
    const width = $(".the-certificate").width() * 0.8;
    $(".the-certificate").append(
      "" +
        '<div style="left:' +
        x_axis +
        "px; top: " +
        y_axis +
        "px;width:" +
        width +
        'px;" id="content-box-' +
        totalElements +
        '" class="editable-content dynamic-element resizeable txt-center">' +
        '<div class="dynamic-controls">' +
        '<button data-x="' +
        x_axis +
        '" data-y="' +
        y_axis +
        '" class="draggable btn waves-effect waves-light btn-primary"><i class=" fas fa-arrows-alt"></i></button>' +
        '<button class="delable btn waves-effect waves-light btn-danger"><i class="fa fa-trash"></i></button>' +
        "</div>" +
        '<div class="txt-wgt">Click here to edit the text.</div>' +
        "</div>"
    );
    $(".certificate-canvas").animate(
      { scrollTop: y_axis - 200, scrollLeft: x_axis - 200 },
      250
    );
  }
}
