export class UserAuthenticationModel {
    email: string;
    password: string;
    recaptchaReactive: string;
    yubikey: string;

    constructor() {
        this.email = this.password = this.yubikey = '';
        this.recaptchaReactive = '';
    }
}
