import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Paper} from '../../../Models/Paper';
import {ExcelService} from '../../services/excel.service';

@Component({
    selector: 'app-view-stock',
    templateUrl: './view-stock.component.html',
    styles: []
})
export class ViewStockComponent extends BaseComponent implements OnInit {
    stock;
    items: any;
    itemsOnServer: any;

    constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router) {
        super(router);
    }

    async ngOnInit() {
        /*  const input = document.querySelectorAll('input');
          for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
          }*/
        await this.getStock();
    }

    async getStock() {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Paper);
        if (res.success) {
            // console.log('data stock', res);
            this.items = this.itemsOnServer = res.data;
        } else {
            console.log('err', res);
        }
    }

    async importExcelData(evt) {
        const page = this;
        this.excelService.importExcelData(evt, async excelData => {
            if (!excelData) {
                return;
            }
            const res = await page.httpService.PostRelation<Paper>
            (Tables.Paper, excelData, Tables.Institutes, page.user.institute.id);
            // console.log('res', res);
            if (res.success) {
                // console.log('excel import', res);
                await page.getStock();
            }
        });
    }

    updateFilter(e: any) {
        if (e) {
            this.items = this.itemsOnServer.filter(x => x.identifier && x.identifier.includes(e) || x.batchId && x.batchId.includes(e));
        } else {
            this.items = this.itemsOnServer;
        }
    }


    async markAsDamaged(id) {
        const r = confirm('Are You Sure?');
        if (r == true) {
            const data = {
                isDamaged: true
            };
            const res = await this.httpService.Patch(Tables.Paper, data, id);
            if (res.success) {
                await this.getStock();
            }
        }
    }

}
