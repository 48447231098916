import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-mobile-verify-certificate",
  templateUrl: "./mobile-verify-certificate.component.html",
  styleUrls: ["./mobile-verify-certificate.component.css"],
})
export class MobileVerifyCertificateComponent implements OnInit {
  data: any;
  constructor(private activateRoute: ActivatedRoute) {
    this.activateRoute.queryParams.subscribe((params) => {
      const _params = params;
      let _data: any = {};
      _data.hash = _params.hash;
      _data.fileInfo = JSON.parse(_params.fileInfo);
      _data.createdAt = _params.createdAt;
      this.data = _data;
    });
  }

  ngOnInit() {}
}
