import {Component, Input, OnInit} from '@angular/core';
import {TransporterService} from '../../services/transporter.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Attributes, Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';

@Component({
    selector: 'app-input-modal',
    templateUrl: './input-modal.component.html',
    styles: []
})
export class InputModalComponent extends BaseComponent implements OnInit {

    public form: FormGroup;
    @Input() rowId: string;
    @Input() caller: string;

    errorAlert = false;
    alertMsg: any;

    constructor(private transporterService: TransporterService, private fb: FormBuilder, private httpService: SharedHttpService, private router: Router) {
        super(router);
    }

    ngOnInit() {
        this.form = this.fb.group({
            identifier: ['', Validators.required]
        });
    }

    async verified() {
        if (this.form.valid) {

            const res = await this.httpService.GetFromTable(Tables.Paper, Attributes.None,
                {identifier: this.form.value.identifier, isDamaged: {neq: true}});

            // console.log('res', res);
            if (res.data.length) {
                this.transporterService.transmit({
                    type: 'input-modal',
                    receiver: this.caller,
                    data: 'true',
                    value: res.data[0].id,
                    rowId: this.rowId
                });
            } else {
                console.log('res', res);
                this.errorAlert = true;
                this.alertMsg = 'Invalid Identifier';
                setTimeout(() => {
                    this.errorAlert = false;
                }, 3000);
            }

        } else {
            // console.log('form valid', this.form.valid);
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control.markAsDirty({onlySelf: true});
            });
        }
    }

    closeModal() {
        this.transporterService.transmit({
            type: 'input-modal',
            receiver: this.caller,
            data: 'close'
        });
    }

}
