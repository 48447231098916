
import {Component} from '@angular/core';

@Component({
    selector: 'app-certificates',
    templateUrl: './certificates.component.html',
    styles: []
})
export class CertificatesComponent {

    constructor() {
    }

}

