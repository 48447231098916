
import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../../services/shared-http.service';
import {Attributes, Tables} from '../../../../Models/Tables';
import {BaseComponent} from '../../../BaseComponent/BaseComponent';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-timeline',
  templateUrl: './user-timeline.component.html',
  styles: []
})
export class UserTimelineComponent extends BaseComponent implements OnInit {

  rows = [];
  userId: any;

  constructor(private sharedHttpService: SharedHttpService, private router: Router, public route: ActivatedRoute) {
    super(router);
    this.userId = route.snapshot.params['id'];
  }

  async ngOnInit() {

    const res = await this.sharedHttpService.GetRelated(Tables.Institutes, this.user.instituteId,
      Tables.ActivityLogs, '', Attributes.staff, {staffId: this.userId} );

    if (res.success) {
      this.rows = res.data;

      // console.log(this.rows)
    }

  }

}
