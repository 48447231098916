export class StaffCategory {

    title: string;
    permissions = {
        validators: {
            viewDashboardAnalytics: null,
            userManagement: null,
            courseManagement: null,
            designManagement: null,
            certificatesManagement: null,
            printCertificates: null,
            voidCertificates: null,
            candidateManagement: null,
            stockPaperManagement: null,
            NFCTagsManagement: null,
            blockUser: null
        },
        viewDashboardAnalytics: false,
        userManagement: false,
        courseManagement: false,
        designManagement: false,
        printCertificates: false,
        certificatesManagement: false,
        voidCertificates: false,
        candidateManagement: false,
        stockPaperManagement: false,
        NFCTagsManagement: false
    };
    id: string;
    instituteId: string;
    level: number;
    public setPermissions = ((defaults) => {
        // console.log('in set permissions');
        this.permissions['viewDashboardAnalytics'] = defaults;
        this.permissions['userManagement'] = defaults;
        this.permissions['courseManagement'] = defaults;
        this.permissions['designManagement'] = defaults;
        this.permissions['certificatesManagement'] = defaults;
        this.permissions['printCertificates'] = defaults;
        this.permissions['voidCertificates'] = defaults;
        this.permissions['candidateManagement'] = defaults;
        this.permissions['stockPaperManagement'] = defaults;
        this.permissions['NFCTagsManagement'] = defaults;
    });

    constructor(instituteId: string = '', defaults = false) {
        this.title = '';
        this.level = 1;
        this.setPermissions(defaults);
        this.instituteId = instituteId;
    }
}
