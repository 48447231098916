import { Component, OnInit } from "@angular/core";
import { ChartOptions, ChartType, ChartDataSets } from "chart.js";
import { Label } from "ng2-charts";
import { SharedHttpService } from "../../services/shared-http.service";
import { Tables, Attributes } from "../../../Models/Tables";
import { Router } from "@angular/router";
import web3 from "../../certificates/printed-certificates/binance/web3.js";
@Component({
  selector: "app-blockchain-dashboard",
  templateUrl: "./blockchain-dashboard.component.html",
  styleUrls: ["./blockchain-dashboard.component.css"],
})
export class BlockchainDashboardComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: any = [
    { data: [], label: "Document in Blockchain" },
    { data: [], label: "Document Verified" },
  ];
  public documentCount = 0;
  public userCount = 0;
  blockchainFileList = [];
  page = 1;
  limit = 4;
  user;
  blockchainBalance;
  constructor(
    private sharedHttpService: SharedHttpService,
    public router: Router
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    const userList = await this.sharedHttpService.GetRelated(
      Tables.Institutes,
      user.institute.id,
      Tables.Staff,
      "",
      Attributes.staffCategory
    );
    this.userCount = userList.data.length;

    const blockchainFileList =
      await this.sharedHttpService.getBlockchainFileList(
        user.id,
        this.page,
        this.limit
      );
    this.blockchainFileList = blockchainFileList.data.data;

    const res = await this.sharedHttpService.getDocumentInBlockchainCount({
      userId: user.id,
    });
    this.documentCount = res.data.documentCount;
    if (res.data && res.data.data[0] && res.data.data[0].data) {
      for (const key in res.data.data[0].data) {
        this.barChartLabels.push(key);
        this.barChartData[0].data.push(res.data.data[0].data[key]);
      }
    } else {
      this.barChartLabels = ["0", "0", "0", "0", "0", "0"];
      var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      var today = new Date();
      var d;
      var month;

      for (var i = 6; i > 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        this.barChartData[0].data = month;
      }
    }

    if (res.data && res.data.verifyCount && res.data.verifyCount.data) {
      for (const key in res.data.verifyCount.data) {
        this.barChartData[1].data.push(res.data.verifyCount.data[key]);
      }
    }
    if (!user.blockchainAddress) return;
    web3.eth.getBalance(user.blockchainAddress, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        this.blockchainBalance = Number(web3.utils.fromWei(result, "ether"));
      }
    });
  }

  onBlockchainBalanceClick() {
    this.router.navigate(["home/settings/blockchain-address"]);
  }
}
