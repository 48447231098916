// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  // webBaseUrl: "http://localhost:4200",
  // apiBaseUrl: "http://localhost:3002/api",
  production: true,
  blockchainContractAddress: "mainnet",
  // blockchainContractAddress: "testnet",
  webBaseUrl:
    "http://app.authentific.com.au.s3-website-us-west-2.amazonaws.com/",
  apiBaseUrl: "https://api.authentific.com/api",
  // apiBaseUrl: "http://localhost:3002/api",
  // blockchainBaseUrl: "http://64.225.100.64/api",
  // blockchainUrl: "http://64.225.100.64/",
  blockchainBaseUrl: "https://verification-api.authentific.com/api",
  blockchainUrl: "https://verification-api.authentific.com/",
  // blockchainBaseUrl: "http://localhost:5000/api",
  // blockchainUrl: "http://localhost:5000/",
  verifyCertificateUrl: "https://verify.authentific.com/",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
