
import { Component, OnInit } from '@angular/core';
import { SharedHttpService } from '../../services/shared-http.service';
import { Router } from '@angular/router';
import { Attributes, Tables } from '../../../Models/Tables';
import { BaseComponent } from '../../BaseComponent/BaseComponent';
import * as $ from 'jquery';

@Component({
  selector: 'app-voided-certificates',
  templateUrl: './voided-certificates.component.html',
  styles: []
})
export class VoidedCertificatesComponent extends BaseComponent implements OnInit {

  items: any;
  itemsOnServer: any;

  constructor(private httpService: SharedHttpService, private router: Router) {
    super(router);

  }


  async ngOnInit() {

    await this.getItems({ isVoid: true, $or: [{ isExternalCreation: false }, { isExternalCreation: null }] });

    const input = document.querySelectorAll('input');
    for (let i = 0; i < input.length; i++) {
      input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
    }

  }

  async getItems(where: any | null) {
    const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Certificates, ''
      , [Attributes.certification, Attributes.candidate, Attributes.approvals], where);

    if (res.success) {
      // console.log('cert data', res);
      this.items = this.itemsOnServer = res.data;
    } else {
      console.log('err', res);
    }
  }


  updateFilter(e: any) {
    if (e) {
      this.items = this.itemsOnServer.filter(x =>
        x.candidate.firstName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.lastName.toLowerCase().includes(e.toLowerCase()) ||
        x.candidate.rollNumber.toLowerCase().includes(e.toLowerCase()) ||
        x.certification.title.toLowerCase().includes(e.toLowerCase()));
    } else {
      this.items = this.itemsOnServer;
    }
  }

  updateZoomLevel(val) {
    $('.the-certificate').css('transform', 'scale(' + val + ')');
    setTimeout(() => {
      const currentHeight = document.getElementById('the-certificate').getBoundingClientRect().height;
      const currentWidth = document.getElementById('the-certificate').getBoundingClientRect().width;
      $('.cert-holder').css('width', currentWidth + 'px');
      $('.cert-holder').css('height', currentHeight + 'px');
    }, 500);
  }
  showPreview(e) {
    const this_ = this;
    $('.cert-holder').html($(e.target).parents('tr').find('.source-html').html()).promise().done(function () {
      this_.updateZoomLevel(0.5);
      $('body').addClass('cert-modal-active');
    });
  }

  closePreview() {
    $('body').removeClass('cert-modal-active');
    $('.cert-holder').html('No Certificate Record found');
  }

}


