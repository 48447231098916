import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { SharedHttpService } from "../../services/shared-http.service";
import { Router } from "@angular/router";
import { FormService } from "../../services/formservice.service";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { Tables } from "../../../Models/Tables";

@Component({
  selector: "app-blockchain-address",
  templateUrl: "./blockchain-address.component.html",
  styleUrls: ["./blockchain-address.component.css"],
})
export class BlockchainAddressComponent
  extends BaseComponent
  implements OnInit
{
  public form: FormGroup;
  successAlert = false;
  errorAlert = false;
  alertMsg: any;

  constructor(
    private fb: FormBuilder,
    private httpService: SharedHttpService,
    private router: Router,
    private formService: FormService
  ) {
    super(router);
  }

  ngOnInit() {
    this.form = this.fb.group({
      blockchainAddress: ["", Validators.required],
    });

    this.form.setValue({
      blockchainAddress: this.user.blockchainAddress,
    });
  }

  async submit() {
    if (this.form.valid) {
      const res = await this.httpService.Patch(
        Tables.Staff,
        this.form.value,
        this.user.id
      );
      if (res.success) {
        // console.log('success', res);
        this.user.blockchainAddress = res.data.blockchainAddress;
        localStorage.setItem("user", JSON.stringify(this.user));
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
        }, 3000);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    } else {
      console.log("form invalid");
      Object.keys(this.form.controls).forEach((field) => {
        const control = this.form.get(field);
        control.markAsDirty({ onlySelf: true });
      });
    }
  }
}
