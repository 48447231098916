import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';


@Injectable()
export class TransporterService {

    constructor() {
    }

    private subject = new Subject<any>();

    /*
    *Always mention type packets while transmission so that only appropriate recipients receive them.
    * examples
    * ----------
    * { type : store, data : stores[]}
    * { type : warehouse, data : stores[]}
    * { type : outlet, data : stores[]}
    * { type : currentStore, data : stores}
    * { type : connection , data : boolean}
    * ---------
    * now recipients can check if packet is for them of not.
    * */

    transmit(parcel: any) {
        this.subject.next(parcel);
    }

    receive(): Observable<any> {
        return this.subject.asObservable();
    }

    clearTransmission() {
        this.subject.next();
    }

}
