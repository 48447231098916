import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {SharedHttpService} from '../../services/shared-http.service';
import {Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {ActivatedRoute, Router} from '@angular/router';
import {StaffCategory} from '../../../Models/StaffCategory';
import {FormService} from '../../services/formservice.service';

@Component({
  selector: 'app-new-user-category',
  templateUrl: './new-user-category.component.html',
  styles: []
})
export class NewUserCategoryComponent extends BaseComponent implements OnInit {

  form: FormGroup;
  id;
  editMode = false;
  errorAlert = false;
  alertMsg: any;
  items = [];
  userCategory: StaffCategory;

  constructor(private formService: FormService, private httpService: SharedHttpService, private router: Router,
              private route: ActivatedRoute) {
    super(router);
    this.form = formService.Get(new StaffCategory(this.user.institute.id));
  }

  async ngOnInit() {

    this.userCategory =  new StaffCategory(this.user.institute.id, false);

    for (let i = 1; i <= this.user.institute.levels; i++) {
      this.items.push({
        name: 'level ' + i,
        level: i
      });
    }

    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.editMode = true;
      const getRequest = await this.httpService.Get<StaffCategory>(StaffCategory,
        {table: Tables.StaffCategories, key: this.id});
      if (getRequest.success) {
        this.userCategory = getRequest.data;
        this.form = this.formService.Get(new StaffCategory(), this.userCategory);

      }
    } else {
      this.form = this.formService.Get(new StaffCategory(this.user.institute.id));
    }
  }

  selectAll(e: any) {

    let defaults = false;
    if (e.target.checked) {
      defaults = true;
    }
    this.setPermissions(this.userCategory, defaults);
    this.form = this.formService.Get(this.userCategory);

  }

  async submit() {
    console.log('New User Category', this.form);

    // console.log('Synthesize', this.formService.Synthesize(this.form.value, this.form));
    if (this.form.valid) {

      let res;
      if (this.id) {
        res = await this.httpService.Patch<StaffCategory>
        (Tables.StaffCategories, this.form.value, this.id);
      } else {
        res = await this.httpService.PostRelation<StaffCategory>
        (Tables.StaffCategories, this.form.value, Tables.Institutes, this.user.institute.id);
      }
      // console.log('res', res);
      if (res.success) {
        this.router.navigate(['/home/user-categories/view']);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }

    } else {
      // console.log('form valid', this.form.valid);
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }
  }

  setPermissions(userCategory, defaults) {

    // console.log('in set permissions', userCategory);
    userCategory.permissions['viewDashboardAnalytics'] = defaults;
    userCategory.permissions['userManagement'] = defaults;
    userCategory.permissions['courseManagement'] = defaults;
    userCategory.permissions['designManagement'] = defaults;
    userCategory.permissions['certificatesManagement'] = defaults;
    userCategory.permissions['printCertificates'] = defaults;
    userCategory.permissions['voidCertificates'] = defaults;
    userCategory.permissions['candidateManagement'] = defaults;
    userCategory.permissions['stockPaperManagement'] = defaults;
    userCategory.permissions['NFCTagsManagement'] = defaults;
  }


}
