import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Router} from '@angular/router';
import {FormService} from '../../services/formservice.service';
import {Person} from '../../../Models/Person';

@Component({
  selector: 'app-twofa-settings',
  templateUrl: './twofa-settings.component.html',
  styleUrls: ['./twofa-settings.component.css']
})
export class TWOFASettingsComponent extends BaseComponent implements OnInit {

  public form: FormGroup;
  successAlert = false;
  errorAlert = false;
  alertMsg: any;

  constructor(private fb: FormBuilder, private httpService: SharedHttpService, private router: Router, private formService: FormService) {
    super(router);
  }

  ngOnInit() {
    this.form = this.fb.group({
      require2FA: ['', Validators.required],
      numberToUseIn2FA: ['', Validators.required]
    });

    this.form.setValue({
      require2FA: this.user.require2FA,
      numberToUseIn2FA: this.user.numberToUseIn2FA,
    });
  }


  async submit() {
    console.log('val', this.form.value);
    if (this.form.valid) {
      const res = await this.httpService.Patch(Tables.Staff, this.form.value, this.user.id);
      if (res.success) {
        // console.log('success', res);
        this.user.require2FA = res.data.require2FA;
        this.user.numberToUseIn2FA = res.data.numberToUseIn2FA;
        localStorage.setItem('user', JSON.stringify(this.user));
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
        }, 3000);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    } else {
      console.log('form invalid');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }
  }

}
