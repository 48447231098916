import { environment } from "../../../environments/environment";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  NgModule,
} from "@angular/core";
import { SharedHttpService } from "../../services/shared-http.service";
import web3 from "../../certificates/printed-certificates/binance/web3.js";
import verify from "../../certificates/printed-certificates/binance/verify.js";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { GetUserDataService } from "../../services/get-user-data.service";
import { BlockchainCertificatePdfComponent } from "../../certificates/printed-certificates/blockchain-certificate-pdf/blockchain-certificate-pdf.component";

@Component({
  selector: "app-manage-documents",
  templateUrl: "./manage-documents.component.html",
  styleUrls: ["./manage-documents.component.css"],
})
export class ManageDocumentsComponent implements OnInit {
  @ViewChild("updateIssueDocument") updateIssueDocumentModal: ElementRef;
  @ViewChild(BlockchainCertificatePdfComponent)
  child: BlockchainCertificatePdfComponent;
  blockchainFile: any;
  userData: any;
  blockchainFileList = [];
  page = 1;
  limit = 5;
  user;
  total = 0;
  config: any;
  isShowDetailsModal = false;
  blockchainFileDetails = null;
  submitForm: FormGroup;
  public updateIssueDocumentModalRef: BsModalRef;
  public isSubmit: boolean = false;
  public loading: boolean = false;
  fileInfoArray = [];

  constructor(
    private sharedHttpService: SharedHttpService,
    private http: HttpClient,
    private httpService: SharedHttpService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private userDataService: GetUserDataService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.user = user;
    this.query();
    this.submitForm = this.formBuilder.group({
      // fileName: ["", Validators.required],
      // documentHolderName: ["", Validators.required],
      // documentHolderEmail: ["", [Validators.email, Validators.required]],
      // issueDate: ["", Validators.required],
      // expireDate: [""],
      // refNo: ["", Validators.required],
      // file: ["", Validators.required],
    });
    // console.log("blockchainFileDetails:", this.blockchainFileDetails);
  }

  get f() {
    return this.submitForm.controls;
  }

  setControls = (file: any) => {
    this.submitForm.controls = {};
    this.submitForm.addControl(
      "document_holder_email",
      new FormControl("", [Validators.email, Validators.required])
    );

    if (file.fileInfo && Array.isArray(file.fileInfo)) {
      file.fileInfo.forEach((element: any) => {
        this.submitForm.addControl(
          this.getFormControlName(element.inputLabel),
          new FormControl(element.inputValue, Validators.required)
        );
      });
    }
  };
  getFormControlName(inputLabel: string) {
    return inputLabel.replace(/\s+/g, "_").toLowerCase();
  }
  updateFormControls() {
    this.updateIssueDocumentModalRef.content.file.fileInfo.forEach((info) => {
      this.submitForm.get(info.inputLabel).patchValue(info.inputValue);
    });
  }

  async query() {
    const blockchainFileList =
      await this.sharedHttpService.getBlockchainFileList(
        this.user.id,
        this.page,
        this.limit
      );
    this.blockchainFileList = blockchainFileList.data.data;
    console.log(this.blockchainFileList);
    this.total = blockchainFileList.data.total;

    this.config = {
      itemsPerPage: this.limit,
      currentPage: this.page,
      totalItems: this.total,
    };
  }

  pageChanged(event) {
    this.page = event;
    this.query();
  }

  async onViewFileDetails(file) {
    try {
      let result = await this.sharedHttpService.getBlockchainFileDetails(
        file._id
      );
      if (result.data.expireDate)
        result.data.expireDate = result.data.expireDate.split("T")[0];
      if (result.data.issueDate)
        result.data.issueDate = result.data.issueDate.split("T")[0];
      this.blockchainFileDetails = result.data;
      this.isShowDetailsModal = true;
      this.cdr.detectChanges();
    } catch (error) {
      console.log(error);
      this.isShowDetailsModal = true;
      this.blockchainFileDetails = null;
    }
  }

  onSendPdfToEmail(file) {
    this.isSubmit = false;
    this.loading = true;

    if (file.transaction == null) {
      window.alert("Transaction not found");
      this.loading = false;
      return;
    }

    console.log("passed file:", file);

    web3.eth
      .getTransactionReceipt(file.transaction.transactionHash)
      .then((receipt) => {
        console.log("receipt", receipt);
        if (receipt) {
          if (receipt.status) {
            console.log("Transaction succeeded:", receipt);
            this.loading = false;

            if (
              file.transaction &&
              (file.transaction.status === "false" || !file.transaction.status)
            ) {
              this.http
                .patch(`${environment.blockchainBaseUrl}/save-database`, {
                  updateIpfsRes: {
                    _id: file._id,
                    transactionHistory: receipt,
                  },
                })
                .toPromise()
                .then(() => {
                  console.log("IPFS data updated successfully");
                  return file;
                })
                .catch((error) => {
                  console.error("Error updating IPFS data:", error);
                  return file;
                });
            }
            return file;
          } else {
            throw new Error("Transaction has failed");
          }
        } else {
          throw new Error("Transaction is not yet completed");
        }
      })
      .then((file) => {
        if (file.documentHolderEmail) {
          return this.downloadFromBlockchain(
            file._id,
            file.documentHolderEmail
          );
        } else {
          this.isSubmit = false;
          this.updateIssueDocumentModalRef = this.modalService.show(
            this.updateIssueDocumentModal
          );
          this.updateIssueDocumentModalRef.content = { file: file };
          this.setControls(file);
        }
      })
      .catch((error) => {
        console.error("Error in onSendPdfToEmail:", error);
        this.loading = false;
        window.alert(error.message || "Transaction Error");
      });
  }

  async downloadFromBlockchain(id, email) {
    try {
      let accounts = await web3.eth.getAccounts();
      let defaultAccount = accounts[0];
      if (!defaultAccount) {
        const ethEnabled = async () => {
          if (window.ethereum) {
            await window.ethereum.request({ method: "eth_requestAccounts" });
            try {
              window.web3(window.ethereum);
            } catch (error) {
              return true;
            }
            return true;
          }
          return false;
        };
        await ethEnabled();
        accounts = await web3.eth.getAccounts();
        defaultAccount = accounts[0];
      }
      const file: any = await this.http
        .post(`${environment.blockchainBaseUrl}/get-file-database`, { id })
        .toPromise();
      let ipfsFileHash = await verify.methods
        .getPdfLink(file.mainFileId)
        .call();
      const url: any = await this.http
        .post(`${environment.blockchainBaseUrl}/download-file-blockchain`, {
          ipfsFileHash,
        })
        .toPromise();
      console.log("Sending email to : ", email);
      if (url.fileType === "image") {
        this.httpService.sendCertificateToMail({
          pdf: url.bufferToString,
          email: email,
          // documentName: file.fileName + ".jpg",
          documentName: "Blockchain-document.jpg",
          type: "jpg",
        });
      } else {
        this.httpService.sendCertificateToMail({
          pdf: url.bufferToString,
          email: email,
          // documentName: file.fileName,
          documentName: "Blockchain-document.pdf",
          type: "pdf",
        });
      }

      const issueDocument: any = await this.http
        .get(`${environment.blockchainBaseUrl}/issue-document?id=${id}`)
        .toPromise();
      this.query();
      this.loading = false;
    } catch (error) {
      this.query();
      this.loading = false;
    }
  }

  async onSubmit(file: any) {
    let updateFile = file;
    let id = file._id;

    if (this.submitForm.get("document_holder_email").invalid) {
      alert("Email is required");
      return;
    }

    const documentHolderEmail = this.submitForm.get(
      "document_holder_email"
    ).value;

    if (!updateFile.fileInfo || updateFile.fileInfo.length === 0) {
      updateFile.fileInfo = [
        {
          inputLabel: "Document Holder Email",
          inputValue: documentHolderEmail,
        },
      ];
    } else {
      updateFile.fileInfo.push({
        inputLabel: "Document Holder Email",
        inputValue: documentHolderEmail,
      });
    }

    this.userData = await this.userDataService.returnUserData();
    this.isSubmit = true;
    if (!this.submitForm.valid) {
      return;
    }
    this.loading = true;
    const updateIpfsRes: any = {};
    // updateIpfsRes._id = id;
    // updateIpfsRes.fileName = this.submitForm.value.fileName;
    // updateIpfsRes.issueDate = this.submitForm.value.issueDate;
    // updateIpfsRes.documentHolderName = this.submitForm.value.documentHolderName;
    // updateIpfsRes.documentHolderEmail =
    //   this.submitForm.value.documentHolderEmail;
    // updateIpfsRes.refNo = this.submitForm.value.refNo;
    // updateIpfsRes.expireDate = this.submitForm.value.expireDate;
    try {
      let saveToDB: any = await this.http
        .patch(`${environment.blockchainBaseUrl}/save-database`, {
          updateIpfsRes: updateFile,
        })
        .toPromise();
      this.blockchainFile = [saveToDB];
      await this.delay(1000);
      this.child.pdf();
      this.downloadFromBlockchain(id, documentHolderEmail);
      this.closeModal();
    } catch (error) {
      console.log(error);
      window.alert(error);
      this.loading = false;
      this.closeModal();
    }
  }

  closeModal() {
    this.updateIssueDocumentModalRef.hide();
    this.submitForm.reset();
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
