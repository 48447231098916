import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import * as $ from "jquery";
import { SharedHttpService } from "../services/shared-http.service";
import { Attributes, Tables } from "../../Models/Tables";
import { Router } from "@angular/router";
import { Person } from "../../Models/Person";
import { UserAuthenticationModel } from "../../Models/UserAuthenticationModel";
import { FormService } from "../services/formservice.service";
import { GetUserDataService } from "../services/get-user-data.service";

@Component({
  selector: "app-signin",
  templateUrl: "./sign-in.component.html",
  styles: [],
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  recoverEmailForm: FormGroup;
  forgotPassForm = false;
  yubikeyRequired = false;
  captchaChecked = false;
  errorAlert = false;
  successAlert = false;
  alertMsg: string = "";
  disabledButton = false;

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private httpService: SharedHttpService,
    private router: Router,
    private getUserData: GetUserDataService
  ) {}

  async ngOnInit() {
    this.form = this.formService.Get(new UserAuthenticationModel());

    $(".preloader").fadeOut();

    this.recoverEmailForm = this.fb.group({
      email: ["", Validators.email],
    });
  }

  yubikeyTrigger() {
    this.yubikeyRequired = true;
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
    this.captchaChecked = true;
    this.errorAlert = false;
  }

  async submit() {
    console.log(this.recoverEmailForm);
    if (!this.recoverEmailForm.valid) return;
    if (!this.captchaChecked) return;
    this.disabledButton = true;
    try {
      this.alertMsg = "";
      this.errorAlert = false;
      this.successAlert = false;
      const res = await this.httpService.sendMagicLink(this.form.value);
      if (!res.success) {
        this.disabledButton = false;
        this.errorAlert = true;
        this.alertMsg = res.data.error;
        return;
      } else {
        this.disabledButton = true;
        this.alertMsg = "Please check your email for a magic link to sign in.";
        this.successAlert = true;
      }
    } catch (error) {
      this.disabledButton = false;
      this.alertMsg = "Something went wrong! Please try again later.";
      this.successAlert = false;
      this.errorAlert = true;
      console.log(error);
    }
    // try {
    //   if (this.captchaChecked == false) {
    //     this.errorAlert = true;
    //     this.alertMsg = "Recaptcha required ";
    //     return false;
    //   }
    //   $(".preloader").fadeOut();
    //   const res = await this.httpService.Authenticate(this.form.value);
    //   if (res.success) {
    //     const getUser: any = await this.getUserData.getUserDataFromLogin(
    //       res.data.userId
    //     );
    //     if (!getUser.success) {
    //       return;
    //     }
    //     this.successAlert = true;
    //     this.errorAlert = false;
    //     this.alertMsg = "Logged In Successfully";
    //     getUser.data.token = res.data.id;
    //     localStorage.setItem("user", JSON.stringify(getUser.data));
    //     // console.log(getUser.data);
    //     if (!getUser.data.staffCategory.permissions.viewDashboardAnalytics) {
    //       this.router.navigate(["/home/certificates/waiting-for-approval"]);
    //     } else {
    //       console.log(getUser.data.require2FA, getUser.data.verified2FA);
    //       if (getUser.data.require2FA && !getUser.data.verified2FA) {
    //         this.router.navigate(["/home/2FA-step"]);
    //       } else {
    //         this.router.navigate(["/home/dashboard"]);
    //       }
    //     }
    //   } else {
    //     this.errorAlert = true;
    //     this.successAlert = false;
    //     this.alertMsg = "LogIn Failed";
    //   }
    // } catch (authError) {
    //   this.errorAlert = true;

    //   console.log("error", authError);
    //   throw authError;
    // }
  }

  forgotPassTrigger() {
    this.forgotPassForm = !this.forgotPassForm;
  }

  signupTrigger() {
    this.router.navigate(["/signup"]);
  }

  async submitEmail() {
    try {
      const res = await this.httpService.RecoverPassword(this.form.value);
      if (res.success) {
      } else {
        throw res.data;
      }
    } catch (authError) {
      this.errorAlert = true;
      this.alertMsg = authError.error.error.message;
      console.log("error", authError);
    }
  }
}
