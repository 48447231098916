import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {FormService} from '../../services/formservice.service';
import {Person} from '../../../Models/Person';
import {Email} from '../../../Models/Email';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styles: []
})
export class NewUserComponent extends BaseComponent implements OnInit {

  public form: FormGroup;
  UserCategories: any;
  errorAlert = false;
  alertMsg: any;
  email: any;
  successAlert = false;

  constructor(private httpService: SharedHttpService, private router: Router, private fb: FormBuilder, private formService: FormService) {
    super(router);
  }

  ngOnInit() {

    this.form = this.formService.Get(new Person(this.user.institute.id));

    this.getUserCategories();
  }

  clearForm() {
    this.form = this.formService.Get(new Person(this.user.institute.id));
  }

  async getUserCategories() {
    const getStaffCategories = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.StaffCategories);
    // console.log(getStaffCategories);
    if (getStaffCategories.success) {
      this.UserCategories = getStaffCategories.data;
    } else {
    }
  }

  async submit() {

    // console.log('form value', this.form.value);
    if (this.form.valid) {
      this.form.value['requireYubikey'] = false;
      // console.log('val', this.form.value);
      const res = await this.httpService.PostRelation<Person>
      (Tables.Staff, this.form.value, Tables.Institutes, this.user.institute.id);
      // console.log('res', res);
      if (res.success) {
        this.email = {
          to: this.form.value.email,
          password: this.form.value.password,
          firstName: this.form.value.firstName
        }
        this.httpService.sendEmail<Email>(this.email);
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
          this.router.navigate(['/home/users']);
        }, 600);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    } else {
      console.log('form not valid');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }
  }
}
