
import {Component, OnInit} from '@angular/core';
import {Tables} from '../../Models/Tables';
import {SharedHttpService} from '../services/shared-http.service';
import {BaseComponent} from '../BaseComponent/BaseComponent';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styles: []
})
export class MediaGalleryComponent extends BaseComponent implements OnInit {
  noData= false;
  fileToUpload: FileList;
  items = [];
  itemsOnServer = [];
  apiBaseUrl;
  page = 0;

  constructor(private httpService:SharedHttpService, private router: Router) {
    super(router);
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  ngOnInit() {
    this.getItems();
  }

  async fileHandler(files: FileList) {
    this.fileToUpload = files;
    // console.log('files', files);
    // console.log('files item', files.item(0));
    const res = await this.httpService.PostPicture(this.fileToUpload, 'images');
    // console.log('res', res);
    if(res){
      this.getItems();
    }
  }


  async getItems() {
    const res = await this.httpService.GetPicture('images',);
    if (res.success) {
      this.items = this.itemsOnServer = res.data;

      this.items = this.itemsOnServer.slice(this.page*10, 10);

      // console.log('images', this.items);
      if (this.items === null || this.items.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    } else {
      console.log('err', res);
    }
  }

  previousPage(){
    this.page -= 1;
    this.items = this.itemsOnServer.slice(this.page*10, (this.page+1)*10);
  }
  nextPage(){
    this.page += 1;
    // console.log('page', this.page);
    // console.log('page1', this.itemsOnServer);
    this.items = this.itemsOnServer.slice(this.page*10, (this.page+1)*10);
    // console.log('images', this.items);
  }

  searchImage(e: any) {
    if(e){
      // console.log('e',e);
      this.items = this.itemsOnServer.filter(x => x.name.toLowerCase().includes(e.toLowerCase()));
    }else{
      console.log('e',e);
      this.items = this.itemsOnServer;
    }
  }

}
