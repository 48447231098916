import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-sign-in-staff',
    templateUrl: './sign-in-staff.component.html',
    styles: []
})
export class SignInStaffComponent implements OnInit {

    forgotPassForm = false;

    constructor() {
    }

    ngOnInit() {
        $('.preloader').fadeOut();
    }

    forgotPassTrigger() {
        this.forgotPassForm = !this.forgotPassForm;
    }
}
