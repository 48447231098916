
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormService} from '../services/formservice.service';
import {Certification} from '../../Models/Certification';
import {BaseComponent} from '../BaseComponent/BaseComponent';
import {Router} from '@angular/router';


@Component({
    selector: 'app-add-certificates-fields',
    templateUrl: './add-certificates-fields.component.html',
    styles: []
})
export class AddCertificatesFieldsComponent  extends BaseComponent implements OnInit {

    count = 1;
    public form: FormGroup;

    constructor(private fb: FormBuilder, private formService: FormService, private router: Router) {
        super(router);
    }

    ngOnInit() {

        // this.form = this.formService.Get(new Certification(this.user.institute.id));

        this.form = this.formService.Get(new Certification(this.user.institute.id));

    }



    submit() {

    }

}
