
import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Attributes, Tables} from '../../../Models/Tables';
import {Router} from '@angular/router';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {StaffCategory} from '../../../Models/StaffCategory';

@Component({
    selector: 'app-view-user-categories',
    templateUrl: './view-user-categories.component.html',
    styles: []
})
export class ViewUserCategoriesComponent extends BaseComponent implements OnInit {

    items: any;
    itemsOnServer: any;
    noData = false;

    constructor(private httpService: SharedHttpService, private router: Router) {
        super(router);
    }

    ngOnInit() {
        const input = document.querySelectorAll('input');
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
        }
        this.getUserCategories();
    }

    async getUserCategories() {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.StaffCategories);
        if (res.success) {
            // console.log('data', res);
            this.items = this.itemsOnServer = res.data;
            if (this.items === null || this.items.length === 0) {
                this.noData = true;
            } else {
                this.noData = false;
            }
        } else {
            console.log('err', res);
        }
    }

    updateFilter(e: any) {
      if(e){
        // console.log('e',e);
        this.items = this.itemsOnServer.filter(x => x.title.toLowerCase().includes(e.toLowerCase()));
      }else{
        console.log('e',e);
        this.items = this.itemsOnServer;
      }
    }

}
