import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {ExcelService} from '../../services/excel.service';
import {Certification} from '../../../Models/Certification';
import {StaffCertification} from '../../../Models/StaffCertification';
import {Person} from '../../../Models/Person';


@Component({
    selector: 'app-certification-staff',
    templateUrl: './certification-staff.component.html',
    styles: []
})
export class CertificationStaffComponent extends BaseComponent implements OnInit {

    allowedStaff: Array<Person> = new Array<Person>();
    otherStaff: Array<Person> = new Array<Person>();
    itemsOnServer: any;
    noData = false;
    errorAlert = false;
    alertMsg: any;
    id;
    certification: Certification;

    constructor(private httpService: SharedHttpService, private excelService: ExcelService, private router: Router, private route: ActivatedRoute) {
        super(router);
    }

    async ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        const input = document.querySelectorAll('input');
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
        }
        await this.getCertification(); // :todo do not change the placement of this call

        await this.getItems();
        await this.getUsers();

    }

    async getCertification() {
        const res = await this.httpService.Get<Certification>(Certification,{table: Tables.Certifications, key: this.id});
        if (res.success) {
            this.certification = res.data;
        }
    }

    async getItems() {
        const res = await this.httpService.GetRelated(Tables.Certifications, this.id, Tables.Staff, '');
        if (res.success) {
            // console.log('data', res);
            this.allowedStaff = this.itemsOnServer = res.data;
            if (this.allowedStaff === null || this.allowedStaff.length === 0) {
                this.noData = true;
            } else {
                this.noData = false;
            }
        } else {
            console.log('err', res);
        }
    }

    updateFilter(e: any) {
        if (e) {
            this.allowedStaff = this.itemsOnServer.filter(x =>
                x.staff.firstName.toLowerCase().includes(e.toLowerCase()) ||
                x.staff.lastName.toLowerCase().includes(e.toLowerCase()) ||
                x.staff.email.toLowerCase().includes(e.toLowerCase()));
        } else {
            this.allowedStaff = this.itemsOnServer;
        }
    }

    async importExcelData(evt) {
        let page = this;
        await this.excelService.importExcelData(evt, async excelData => {
            // console.log('excel data', excelData);
            if (!excelData) {
                // console.log('return');
                return;
            }
            const res = await this.httpService.AddCandidatesToCertification(excelData, this.id);
            // console.log('Posted Candidates', res);
            if (res.success) {
                // console.log('excel import', res);
                await this.getItems();
            } else {
                this.errorAlert = true;
                setTimeout(() => {
                    this.errorAlert = false;
                }, 3000);
                this.alertMsg = 'Validation error';
            }
        });

    }

    async getUsers() {
        const res = await this.httpService.GetRelated<Person>(Tables.Institutes, this.user.institute.id,
            Tables.Staff, '', [Attributes.certifications, Attributes.staffCategory]);
        // console.log('res',res);
        if (res.success) {
            this.otherStaff = [];
            res.data.forEach(x => {
                if (!this.allowedStaff.find(y => y.id === x.id)) {
                    if (x.staffCategory.level === this.certification.level) {
                        this.otherStaff.push(x);
                    }
                }
            });
            // console.log('otherStaff', this.otherStaff);

        } else {
            console.log('err', res);
        }
    }

    async userAllowAccess(id: any) {
        const res = await this.httpService.PostRelation<Certification>
        (Tables.StaffCertifications, new StaffCertification(this.user.institute.id, id, this.id), Tables.Institutes,
            this.user.institute.id);
        console.log('res',res);
        if (res.success) {
           await this.ngOnInit();
        }
    }

    async userUnAllowAccess(staff: Person) {
        const res = await this.httpService.DeleteRelated<StaffCertification>
        (Tables.Staff, staff.id, Tables.Certifications, this.id);
      console.log('res',res);
      if (res.success) {
        await this.ngOnInit();
      }
    }


}
