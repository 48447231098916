import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Tables } from "../../Models/Tables";
import { SharedHttpService } from "../services/shared-http.service";
import { BaseComponent } from "../BaseComponent/BaseComponent";
import { GetUserDataService } from "../services/get-user-data.service";

@Component({
  selector: "app-admin-profile",
  templateUrl: "./admin-profile.component.html",
  styles: [],
})
export class AdminProfileComponent extends BaseComponent implements OnInit {
  admin: any;
  activeTab: any;
  isAuthentificUser = false;
  constructor(
    public route: ActivatedRoute,
    public httpService: SharedHttpService,
    public router: Router
  ) {
    super(router);
    this.activeTab = route.snapshot.params["tab"];
  }

  async ngOnInit() {
    const user = JSON.parse(localStorage.getItem("user"));
    this.isAuthentificUser = user.institute.authentificUser;
  }

  tabChange(tab: any) {
    this.activeTab = tab;
  }
}
