import Web3 from "web3";

const Web3DefaultAccount = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    window.alert("No ethereum broswer detected! You can check out MetaMask!");
    return null;
  }
  const web3 = window.web3;
  const account = await web3.eth.getAccounts();
  return account[0];
};

export default Web3DefaultAccount;
