import {Component, Input, OnInit} from '@angular/core';
import {TransporterService} from '../../services/transporter.service';

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styles: []
})
export class VerificationModalComponent implements OnInit {

  @Input() rowId : string;
  @Input() caller : string;
  constructor(private transporterService: TransporterService) { }

  ngOnInit() {
  }

  verified(){
    this.transporterService.transmit({
      type: 'verification-modal',
      receiver: this.caller,
      data: 'verified',
      rowId: this.rowId
    });
  }

  closeModal(){
    this.transporterService.transmit({
      type: 'verification-modal',
      receiver: this.caller,
      data: 'close'
    });
  }

}
