import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Attributes, Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {Candidate} from '../../../Models/Candidate';
import {FormService} from '../../services/formservice.service';
import {Certificate} from '../../../Models/Certificate';
import {Certification} from '../../../Models/Certification';
import {environment} from '../../../environments/environment';
import {BluePrint} from '../../../Models/BluePrint';

@Component({
    selector: 'app-new-certificate',
    templateUrl: './new-certificate.component.html',
    styles: []
})
export class NewCertificateComponent extends BaseComponent implements OnInit {

    public form: FormGroup;
    candidateNotSelected = true;
    certificateNotSelected = true;
    certificationDesignNotSelected = true;
    formSubmitting = false;
    id: any;
    candidates: any;
    apiBaseUrl: any;
    candidate: Candidate;
    warningAlert = false;
    successAlert = false;
    limitAlert=false;
    errorAlert = false;
    alertMsg: any;
    selectedCertification;
    Certifications: Array<Certification>;
    bluePrints: Array<BluePrint>;
    btnDisable = false;
    candidateImage;
    candidateId;
    certificationId;
    blueprintId;
    generatedCertificate=0;
    remainingCertificateLimit=0;

    constructor(private httpService: SharedHttpService, private route: ActivatedRoute, private router: Router,
                private fb: FormBuilder, private formService: FormService) {
        super(router);
        this.apiBaseUrl = environment.apiBaseUrl;
    }

    async ngOnInit() {
        console.log(this.user)
        this.certificationId = this.route.snapshot.params['id'];
        this.candidateId = this.route.snapshot.params['candidateId'];
        await this.getBluePrints();
        await this.getAllCeritifications();
        await this.getGeneratedCertificateCount(null);
        
        
        if (this.certificationId && this.candidateId) {
            await this.getAllCandidates({certificateGenerate:false,certificationId:this.certificationId});

            this.form = this.formService.Get(new Certificate(this.candidateId, this.user.institute.id,this.certificationId));
            this.candidateChange(this.candidateId)
        } else {
            this.form = this.formService.Get(new Certificate(0, this.user.institute.id,0));
        }

        // await this.getItems();
    }

    goBack() {
        window.history.back();
    }



    clearForm() {
        this.form = this.formService.Get(Candidate, {instituteId: this.user.instituteId});
    }


    async getBluePrints() {
        const res = await this.httpService.GetRelated<BluePrint>(Tables.Institutes, this.user.institute.id,
            Tables.BluePrints, '', [Attributes.None], {isArchived: false});
        if (res.success) {
            this.bluePrints = res.data;
        } else {
            console.log('err', res);
        }
    }


   async updateCandidateCertificationStatus(){
       let dataToPost = {certificateGenerate:true}
        const res = await this.httpService.Patch(Tables.Candidates, dataToPost, this.form.value.candidateId);
        if (res.success) {
            // console.log(res.data)
        }
    }

    async getAllCeritifications(){
        const getCertification = await this.httpService.GetRelated(Tables.Institutes, this.user.instituteId, Tables.Certifications,
            '', Attributes.None);

        this.Certifications = getCertification.data;
    }

    async getGeneratedCertificateCount(where: any | null) {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Certificates, ''
            , [Attributes.None], where);

        if (res.success) {
            this.generatedCertificate = res.data.length;
            console.log(this.generatedCertificate)
            this.remainingCertificateLimit = this.user.institute.certificateLimit - this.generatedCertificate;
            // console.log('certifications: ', this.items);
        } else {
            console.log('err', res);
        }
    }


    

    async getAllCandidates(where: any | null) {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Candidates, '', [Attributes.certificates],where);
        if (res.success) {
            this.candidates = res.data;
            this.form = this.formService.Get(new Certificate(0, this.user.institute.id,this.certificationId));
        } else {
            console.log('err', res);
        }     
    }

    async candidateChange(e) {
        this.candidateId = e;
            this.form.patchValue({
                candidateId:e
            });
       

        const found =this.candidates.find(x => x.id === e)
        if(found && found.dp){
            this.candidateImage = found.dp
        }else{
            this.candidateImage = '';
        }
        
        

    }

    async certificationDesignChange(e) {
        if (e) {
            this.blueprintId = e;
            this.form.patchValue({
                bluePrintId:e
            })
        } 
        // console.log(this.form.value)

    }

    async courseChange(e) {
        this.certificationId = e
        this.form.patchValue({
            certificationId:e
        })
        this.getAllCandidates({certificateGenerate:false,certificationId:this.certificationId});
        // console.log(this.form.value)
    }


    async submit() {
        let page = this;
        await this.getGeneratedCertificateCount(null);
        if(this.remainingCertificateLimit<1){
            this.limitAlert = true;
            return false;
            
        }
        if(this.form.value.candidateId!==0 && this.form.value.certificationId !== 0 && this.form.value.bluePrintId!==0 && this.remainingCertificateLimit>0){
            const res = await this.httpService.Post<Certificate>
                (Tables.Certificates, this.form.value);
                if (res.success) {
                    page.updateCandidateCertificationStatus();

                    let certificationId = res.data.certificationId;
                    let certificateId = res.data.id;
                    
                    const pendingReport = await this.httpService.GetPendingCertificateApprovalsByOther(certificationId,certificateId);
                    if(pendingReport.data.length>0){
                        let dataToPost = {isApproved:true};
                        await this.httpService.Patch<Certificate>
                        (Tables.Certificates, dataToPost,certificateId);
                        this.router.navigate(['/home/certificates/printable']);
                    }else{
                        this.router.navigate(['/home/certificates/pending-approval-by-other']);
                    }   
                }else{
                    console.log("error on submitting form")
                }
        }else{
            console.log('form invalid');
        }
    }

}
