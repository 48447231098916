import { environment } from "src/environments/environment";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
} from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import verify from "../../certificates/printed-certificates/binance/verify.js";
import web3 from "../../certificates/printed-certificates/binance/web3";
import Web3DefaultAccount from "../../certificates/printed-certificates/binance/returnDefaultAccount.js";
import { GetUserDataService } from "../../services/get-user-data.service";
import { BlockchainCertificatePdfComponent } from "../../certificates/printed-certificates/blockchain-certificate-pdf/blockchain-certificate-pdf.component";
import { Router } from "@angular/router";
import { AbstractControl, FormArray } from "@angular/forms";
@Component({
  selector: "app-add-new-document",
  templateUrl: "./add-new-document.component.html",
  styleUrls: ["./add-new-document.component.css"],
})
export class AddNewDocumentComponent implements OnInit {
  submitForm: FormGroup;
  constructor(
    private http: HttpClient,
    private userDataService: GetUserDataService,
    private router: Router,
    private fb: FormBuilder
  ) {}
  file: any;
  blockChainFileData: any;
  userData: any;
  loading = false;
  @ViewChild(BlockchainCertificatePdfComponent)
  child: BlockchainCertificatePdfComponent;
  isSubmit = false;
  ngOnInit() {
    this.submitForm = this.fb.group({
      issueDate: [""],
      otherInfo: this.fb.group({
        info: this.fb.array([]),
      }),
    });

    this.infoArray.push(
      this.fb.group({
        inputLabel: ["Document Name"],
        inputValue: [""],
      })
    );

    this.infoArray.push(
      this.fb.group({
        inputLabel: ["Document Holder Name"],
        inputValue: [""],
      })
    );

    this.infoArray.push(
      this.fb.group({
        inputLabel: ["Document Number"],
        inputValue: [""],
      })
    );
  }
  get f() {
    return this.submitForm.controls;
  }
  initialField() {
    return this.fb.group({
      inputLabel: [""],
      inputValue: [""],
    });
  }
  field() {
    return this.fb.group({
      inputLabel: [""],
      inputValue: [""],
    });
  }
  onRemoveButton(i) {
    this.infoArray.removeAt(i);
  }

  addMorePhase() {
    this.infoArray.push(this.field());
  }
  get infoArray() {
    const control = <FormArray>(
      (<FormGroup>this.submitForm.get("otherInfo")).get("info")
    );
    return control;
  }
  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.file = e.target.files[0];
    }
  }

  async onSubmit() {
    if (!this.file) {
      return window.alert("Please select your file!");
    }
    if (!this.submitForm.controls.issueDate.valid) return;
    const infoValue = this.submitForm.value.otherInfo.info;
    const filteredInfoValue = infoValue.filter(
      (info) => info.inputLabel !== "" && info.inputValue !== ""
    );
    filteredInfoValue.push({
      inputLabel: "issueDate",
      inputValue: this.submitForm.value.issueDate || null,
    });

    this.userData = await this.userDataService.returnUserData();
    this.isSubmit = true;

    const defaultAccount = await Web3DefaultAccount();
    if (!defaultAccount) return;
    this.loading = true;
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("id", "");
    let uploadIpfsRes: any;
    try {
      uploadIpfsRes = await this.http
        .post(`${environment.blockchainBaseUrl}/upload-ipfs`, formData)
        .toPromise();
    } catch (error) {
      this.loading = false;
      console.log(error);
      window.alert("Something went wrong!");
      return;
    }

    if (!this.userData.institute.blockchainAddress) {
      this.loading = false;
      window.alert("Something went wrong");
      return;
    }

    const amount_to_send_wei =
      this.userData.institute.blockchainFees * 1000000000000000000;
    const blockchainAddress = this.userData.institute.blockchainAddress;
    console.log("UploadIPFS::", uploadIpfsRes);

    verify.methods
      .addPdfLink(
        uploadIpfsRes.mainFileId,
        uploadIpfsRes.ipfsLink,
        blockchainAddress
      )
      .send({
        from: defaultAccount,
        value: amount_to_send_wei,
        gasLimit: 4000000,
      })
      .on("transactionHash", async (hash) => {
        console.log("Transaction sent, hash:", hash);
        uploadIpfsRes.userId = this.userData.id;
        uploadIpfsRes.fileInfo = filteredInfoValue;
        uploadIpfsRes.transactionHistory = { transactionHash: hash };

        const saveToDB: any = await this.http
          .post(`${environment.blockchainBaseUrl}/save-database`, {
            uploadIpfsRes,
          })
          .toPromise();

        if (saveToDB) {
          this.blockChainFileData = [saveToDB];
          await delay(1000);
          this.child.pdf();
          this.loading = false;
          this.router.navigate(["home/blockchain/dashboard"]);
        }
      })
      .on("receipt", async (receipt) => {
        console.log("Transaction mined, receipt:", receipt);
        uploadIpfsRes.transactionHistory = receipt;
        console.log("receipt:", receipt);

        console.log("uploadIpfsRes:", uploadIpfsRes);

        const updateIpfsRes = {
          _id: uploadIpfsRes._id,
          transactionHistory: receipt,
        };

        console.log("updateIpfsRes:", updateIpfsRes);
        try {
          const updatedData = await this.http
            .patch(`${environment.blockchainBaseUrl}/save-database`, {
              updateIpfsRes,
            })
            .toPromise();

          console.log("Database updated with receipt:", updatedData);
        } catch (error) {
          console.error("Error updating database with receipt:", error);
        }
      })
      .on("confirmation", async (txHash, receipt) => {
        console.log(
          "Transaction confirmed, confirmation number:",
          txHash,
          "receipt:",
          receipt
        );
      })
      .on("error", (error) => {
        this.loading = false;

        if (error.code === 4001) {
          window.alert("User has declined payment!! Error!!");
          return;
        } else if (
          error.message != null &&
          error.message.includes("Transaction was not mined within 50 blocks")
        ) {
        } else {
          window.alert("An error occurred while processing the transaction.");
          console.error("Error during transaction:", error);
        }
      });
  }

  // async onSubmit() {
  //   if (!this.file) {
  //     return window.alert("Please select your file!");
  //   }
  //   if (!this.submitForm.controls.issueDate.valid) return;
  //   const infoValue = this.submitForm.value.otherInfo.info;
  //   const filteredInfoValue = infoValue.filter(
  //     (info: any) => info.inputLabel !== "" && info.inputValue !== ""
  //   );
  //   filteredInfoValue.push({
  //     inputLabel: "issueDate",
  //     inputValue: this.submitForm.value.issueDate || null,
  //   });

  //   this.userData = await this.userDataService.returnUserData();
  //   this.isSubmit = true;

  //   const formData = new FormData();
  //   formData.append("file", this.file);
  //   formData.append("id", "");
  //   let uploadIpfsRes: any;
  //   try {
  //     uploadIpfsRes = await this.http
  //       .post(`${environment.blockchainBaseUrl}/upload-ipfs`, formData)
  //       .toPromise();
  //   } catch (error) {
  //     this.loading = false;
  //     console.log(error);
  //     window.alert("Something went wrong!");
  //     return;
  //   }

  //   uploadIpfsRes.userId = this.userData.id;
  //   uploadIpfsRes.fileInfo = filteredInfoValue;

  //   const saveToDB: any = await this.http
  //     .post(`${environment.blockchainBaseUrl}/save-database`, {
  //       uploadIpfsRes,
  //     })
  //     .toPromise();

  //   if (saveToDB) {
  //     this.blockChainFileData = [saveToDB];
  //     await delay(1000);
  //     this.child.pdf();
  //     this.loading = false;
  //     this.router.navigate(["home/blockchain/dashboard"]);
  //   }
  // }

  async onSubmit2() {
    if (!this.file) {
      return window.alert("Please select your file!");
    }
    if (!this.submitForm.controls.issueDate.valid) return;
    const infoValue = this.submitForm.value.otherInfo.info;
    const filteredInfoValue = infoValue.filter(
      (info) => info.inputLabel !== "" && info.inputValue !== ""
    );
    filteredInfoValue.push({
      inputLabel: "issueDate",
      inputValue: this.submitForm.value.issueDate || null,
    });

    this.userData = await this.userDataService.returnUserData();
    this.isSubmit = true;

    const defaultAccount = await Web3DefaultAccount();
    if (!defaultAccount) return;
    this.loading = true;
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("id", "");
    let uploadIpfsRes: any;
    try {
      uploadIpfsRes = await this.http
        .post(`${environment.blockchainBaseUrl}/upload-ipfs`, formData)
        .toPromise();
    } catch (error) {
      this.loading = false;
      console.log(error);
      window.alert("Something went wrong!");
    }

    if (!this.userData.institute.blockchainAddress) {
      this.loading = false;
      window.alert("Something went wrong");
      return;
    }

    const amount_to_send_wei =
      this.userData.institute.blockchainFees * 1000000000000000000;
    const blockchainAddress = this.userData.institute.blockchainAddress;
    await verify.methods
      .addPdfLink(
        uploadIpfsRes.mainFileId,
        uploadIpfsRes.ipfsLink,
        blockchainAddress
      )
      .send({
        from: defaultAccount,
        value: amount_to_send_wei,
        gasLimit: 4000000,
      })
      .then(
        async (res) => {
          console.log({ res });
          function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
          }
          uploadIpfsRes.transactionHistory = res;
          uploadIpfsRes.userId = this.userData.id;
          // uploadIpfsRes.fileName = this.submitForm.value.fileName;
          // uploadIpfsRes.issueDate = this.submitForm.value.issueDate;
          // uploadIpfsRes.documentHolderName =
          //   this.submitForm.value.documentHolderName;
          // uploadIpfsRes.documentHolderEmail =
          //   this.submitForm.value.documentHolderEmail;
          // uploadIpfsRes.refNo = this.submitForm.value.refNo;
          // uploadIpfsRes.expireDate = this.submitForm.value.expireDate;
          uploadIpfsRes.fileInfo = filteredInfoValue;
          const saveToDB: any = await this.http
            .post(`${environment.blockchainBaseUrl}/save-database`, {
              uploadIpfsRes,
            })
            .toPromise();

          if (saveToDB) {
            this.blockChainFileData = [saveToDB];
            await delay(1000);
            this.child.pdf();
            this.loading = false;
            this.router.navigate(["home/blockchain/dashboard"]);
            // let currentUrl = this.router.url;
            // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            // this.router.onSameUrlNavigation = "reload";
            // this.router.navigate([currentUrl]);
          }
        },
        (err) => {
          this.loading = false;
          console.log(err);
          if (err.code == 4001) {
            window.alert("User has declined payment!! Error!!");
            return;
          }
        }
      )
      .error((err) => {
        this.loading = false;
        console.log({ err });
      });
  }
}
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
