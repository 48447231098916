
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import * as $ from 'jquery';
import {SharedHttpService} from '../services/shared-http.service';
import {Attributes, Tables} from '../../Models/Tables';
import {ActivatedRoute, Router} from '@angular/router';
import {Person} from '../../Models/Person';
import {FormService} from '../services/formservice.service';
import {BaseComponent} from '../BaseComponent/BaseComponent';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styles: []
})
export class ResetPasswordComponent extends BaseComponent implements OnInit {

  form: FormGroup;
  errorAlert = false;
  alertMsg = '';
  token: string;

  constructor(private fb: FormBuilder, private formService: FormService, private httpService: SharedHttpService,
              private router: Router, private route: ActivatedRoute) {
    super(router);
  }

  async ngOnInit() {

    $('.preloader').fadeOut();
    this.token = this.route.snapshot.params['token'];
    this.user.token = this.token;
    localStorage.setItem('user', JSON.stringify(this.user));

    this.form = this.fb.group({
      newPassword: ['', Validators.required]
    });

  }

  async submit() {
    try {
      const res = await this.httpService.ResetPassword(this.form.value);
      if (res.success) {
        this.router.navigate(['/signin']);
      } else {
        throw res.data;
      }
    } catch (authError) {
      this.errorAlert = true;
      this.alertMsg = authError.error.error.message;
      console.log('error', authError);
    }
  }

}
