export enum Tables {
  ActivityLogs,
  Approvals,
  Institute,
  Certifications,
  AccessRightDefinitions,
  Certificates,
  Institutes,
  Paper,
  Staff,
  StaffMembers,
  BluePrints,
  StaffCategoryAccessRightDefinitions,
  StaffCategories,
  StaffCertifications,
  Candidates,
  NFCTags,
  NFCTagScans,
}

export enum Attributes {
  approvals,
  certification,
  Certifications,
  institute,
  certifications,
  accessRightDefinitions,
  certificates,
  NFCTag,
  NFCTagScan,
  paper,
  staff,
  staffCategories,
  staffCategory,
  bluePrint,
  candidates,
  candidate,
  None,
  accessTokens,
  certificate,
  staffCertifications,
  blockchainFees,
}
