

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Tables} from '../../Models/Tables';
import {SharedHttpService} from '../services/shared-http.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../BaseComponent/BaseComponent';
import {Candidate} from '../../Models/Candidate';
import {FormService} from '../services/formservice.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styles: []
})
export class SettingsComponent extends BaseComponent implements OnInit {

    public form: FormGroup;
    UserCategories: any;

    constructor(private httpService: SharedHttpService, private router: Router, private fb: FormBuilder, private formService: FormService) {
        super(router);
    }

    ngOnInit() {

        this.form = this.formService.Get(new Candidate(this.user.institute.id));

    }

    clearForm() {
        this.form = this.formService.Get(Candidate, {instituteId: this.user.instituteId});
    }

    async submit() {
        // console.log('val', this.form.value);
        const res = await this.httpService.PostRelation<Candidate>
        (Tables.Candidates, this.form.value, Tables.Institutes, this.user.institute.id);
        // console.log('res', res);
        if (res.success) {
            this.router.navigate(['/candidates']);
        }
    }
}
