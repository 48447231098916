import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Tables} from '../../../Models/Tables';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {BaseComponent} from '../../BaseComponent/BaseComponent';
import {FormService} from '../../services/formservice.service';
import {Person} from '../../../Models/Person';
import {YubikeyModel} from '../../../Models/YubikeyModel';


@Component({
  selector: 'app-yubikey-setings',
  templateUrl: './yubikey-setings.component.html',
  styles: []
})
export class YubikeySetingsComponent extends BaseComponent implements OnInit {

  public form: FormGroup;
  successAlert = false;
  errorAlert = false;
  alertMsg: any;

  constructor(private httpService: SharedHttpService, private router: Router, private fb: FormBuilder, private formService: FormService) {
    super(router);
  }

  ngOnInit() {
    if(this.user.requireYubikey === null){
      this.user.requireYubikey = false;
    }
    this.form = this.formService.Get(new YubikeyModel());
  }

  clearForm() {
    this.form = this.formService.Get(new YubikeyModel());
    this.form.reset();
  }

  async submit() {

    // console.log('form value', this.form.value);
    if(this.form.valid){
      const res = await this.httpService.Patch<YubikeyModel>
      (Tables.Staff,  this.form.value, this.user.id);
      // console.log('res', res);
      if (res.success) {
        // console.log('res', res);
        this.successAlert = true;
        setTimeout(() => {
          this.successAlert = false;
        }, 3000);
      } else {
        this.errorAlert = true;
        setTimeout(() => {
          this.errorAlert = false;
        }, 3000);
        this.alertMsg = res.data.error.error.message;
      }
    }else{
      // console.log('form invalid');
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsDirty({onlySelf: true});
      });
    }

  }
}
