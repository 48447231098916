import {Component, OnInit} from '@angular/core';
import {SharedHttpService} from '../../services/shared-http.service';
import {Router} from '@angular/router';
import {Attributes, Tables} from '../../../Models/Tables';
import {BaseComponent} from '../../BaseComponent/BaseComponent';

@Component({
    selector: 'app-demaged-certificate-stock',
    templateUrl: './demaged-certificate-stock.component.html',
    styles: []
})
export class DemagedCertificateStockComponent extends BaseComponent implements OnInit {
    items: any;
    itemsOnServer: any;
    noData = false;

    constructor(private httpService: SharedHttpService, private router: Router) {
        super(router);
    }

    ngOnInit() {
        this.getItems();
        const input = document.querySelectorAll('input');
        for (let i = 0; i < input.length; i++) {
            input[i].setAttribute('size', input[i].getAttribute('placeholder').length + 'px');
        }
    }

    async getItems() {
        const res = await this.httpService.GetRelated(Tables.Institutes, this.user.institute.id, Tables.Paper);
        if (res.success) {
            // console.log('damaged nfc tags', res);
            this.items = this.itemsOnServer = res.data;
            if (this.items === null || this.items.length === 0) {
                this.noData = true;
            } else {
                this.noData = false;
            }
        } else {
            console.log('err', res);
        }
    }

  updateFilter(e: any) {
    if(e){
      this.items = this.itemsOnServer.filter(x => x.identifier && x.identifier.includes(e) ||  x.batchId && x.batchId.includes(e));
    }else{
      this.items = this.itemsOnServer;
    }
  }

}

