import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Attributes, Tables } from '../../../Models/Tables';
import { SharedHttpService } from '../../services/shared-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../BaseComponent/BaseComponent';
import { Candidate } from '../../../Models/Candidate';
import { FormService } from '../../services/formservice.service';
import { Certification } from '../../../Models/Certification';
import {environment} from '../../../environments/environment';
@Component({
    selector: 'app-new-candidate',
    templateUrl: './new-candidate.component.html',
    styles: []
})
export class NewCandidateComponent extends BaseComponent implements OnInit {

    public form: FormGroup;
    UserCategories: any;
    certifications: Array<Certification>;
    certificationId: any;
    editMode = false;
    successAlert = false;
    warningAlert = false;
    errorAlert = false;
    alertMsg: any;
    courseNotSelected = false;
    id: string;
    courseId;
    fileToUpload: FileList;
    imageUrl: String;
    imageFile: String;
    apiBaseUrl;
    submitBtnDisable=false;
    imageUploading=false;
    constructor(private httpService: SharedHttpService, private route: ActivatedRoute, private router: Router,
        private fb: FormBuilder, private formService: FormService) {
        super(router);
        this.apiBaseUrl = environment.apiBaseUrl;
    }


    async ngOnInit() {
        console.log(this.user)
        this.courseId = this.route.snapshot.params['courseId'];
        console.log(this.courseId)
        this.id = this.route.snapshot.params['id'];
        await this.getCertifications();
        if (this.id) {
            this.editMode = true;
            const getRequest = await this.httpService.Get(Candidate, { table: Tables.Candidates, key: this.id });
            if (getRequest.success) {
                // console.log('res', getRequest.data);
                if(getRequest.data.dp){
                    this.imageFile = getRequest.data.dp;
                }
                
                this.form = this.formService.Get(getRequest.data);
            }
        } else {
            this.form = this.formService.Get(new Candidate(this.user.institute.id));
        }
        this.form.patchValue({
            certificationId:this.courseId
        });
        if(!this.id){
            this.form.controls['certificationId'].disable();
        }
        
    }

    clearForm() {
        this.form = this.formService.Get(new Candidate(this.user.institute.id));
        this.form.patchValue({
            certificationId:this.courseId
        });
        this.certificationId = this.form.get('certificationId').value;
    }

    async submit() {
        
        let dataToPost = this.form.value;
        dataToPost.certificationId = this.courseId;
        if(this.imageFile){
            dataToPost.dp = this.imageFile;
        }
        
        let page = this;
        if (this.form.valid) {
            
            // this.certificationId = this.form.get('certificationId').value;
            this.certificationId = this.courseId;
            if (!this.id && (this.certificationId !== 0)) {
                // console.log('add');
                const res = await this.httpService.Post(Tables.Candidates, dataToPost);
                // console.log('res', res);
                if (res.success) {
                    if (this.id) {
                        this.router.navigate(['/home/courses/'+this.courseId+'/candidates']);
                    } else {
                        console.log('success');
                        this.successAlert = true;
                        this.clearForm();
                        // this.certificationId = 0;
                        setTimeout(() => {
                            this.router.navigate(['/home/courses/'+this.courseId+'/candidates']);
                            this.successAlert = false;
                        }, 3000);
                    }
                }
            }
            //  else if (!this.id && (this.certificationId === 0)) {
            //     // console.log('courseNotSelected');
            //     // this.courseNotSelected = true;
            // }
             else {
                console.log('edit');
               
                const res = await this.httpService.Patch(Tables.Candidates, this.form.value, this.id);
                if (res.success) {
                    if (this.id) {
                        this.router.navigate(['/home/courses/'+this.courseId+'/candidates']);
                    } else {
                        // console.log('success');
                        this.successAlert = true;
                        console.log(page.courseId)
                        // this.form.reset();
                        // this.certificationId = 0;
                        setTimeout(() => {
                            this.successAlert = false;
                            this.router.navigate(['/home/courses/'+this.courseId+'/candidates']);
                        }, 3000);
                    }
                }
            }


        } else {
            if (!this.id && (this.certificationId === 0)) {
                this.courseNotSelected = true;
            }
            // console.log('form not valid', this.form.valid);
            Object.keys(this.form.controls).forEach(field => {
                const control = this.form.get(field);
                control.markAsDirty({ onlySelf: true });
            });
        }

    }

    async getCertifications() {
        const res = await this.httpService.GetRelated(Tables.Staff, this.user.id, Tables.Certifications,
            '', Attributes.None);
        if (res.success) {
            // console.log('data', res);
            this.certifications = res.data;
        } else {
            console.log('err', res);
        }
    }


    async fileHandler(files: FileList) {
        this.imageFile = '';
        this.submitBtnDisable =true;
        this.imageUploading =true;
        this.fileToUpload = files;
        // console.log('files', files);
        // console.log('files item', files.item(0));
        const res = await this.httpService.PostPicture(this.fileToUpload, 'candidates');
        // console.log('res', res.data.result.files.fileKey0);
        this.imageFile = res.data.result.files.fileKey0[0].name
        this.submitBtnDisable =false;
        this.imageUploading =false;
        
      }
}
